import React, { useState,useEffect } from "react";

import ReactDatatable from "@mkikets/react-datatable";

import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import moment from "moment";
import { transactionDetail } from "../services/transactionDetail";
import configs from "../Constant/Config";
import "react-confirm-alert/src/react-confirm-alert.css";

export const Paymentmanagement = () => {
  const [record,setRecord] = useState([]);
  const [newRecord, setNewRecord] = useState([]);
  const [filterValue, setfilterValue] = useState("all")
  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "userId",
      text: "User ID",
      className: "user id",
      align: "left",
      sortable: true,
     
    },

    {
      key: "transaction_id",
      text: "Transaction ID",
      className: "Transaction Id",
      align: "left",
      sortable: true,
    },
    // {
    //   key: "transaction_mode",
    //   text: "Mode Of Transaction",
    //   className: "Mode Of Transaction",
    //   align: "left",
    //   sortable: true,
    // },
    {
      key: "type",
      text: "Type",
      className: "Type",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Amount",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record.amount}
            <img
              style={{ width: "8px" }}
              src={record.src}
              alt=""
              class="img-fluid"
            />
          </>
        );
      },
    },
    {
      key: "createdAt",
      text: "Date & Time",
      className: "Date & Time",
      align: "left",
      sortable: true,
      cell: (record) => {
        return moment(record.createdAt).format("DD/MM/YYYY, hh:mm:ss A");
      },

    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  
  const getTransaction = async () => {
    let data = await transactionDetail(configs);
    console.log("datat",data)
    if(data.status){
    setRecord(data.data);
    setNewRecord(data.data)
    }
    
  };
  useEffect(() => {
    getTransaction();
  }, []);

 const handleChange=(e)=>{
  let value=e.target.value
  setfilterValue(e.target.value)
  itemFilter(e.target.value)
  // if(e.target.value=='all'){
  //   allAmt()
  // }
  // if(e.target.value=='deposit' || e.target.value=='withdrawal'){

  //   todayAmount(e.target.value)
  //   itemFilter(deposit)
  // }

  // if(e.target.value=='Weekly-Deposit' ){
  //   weeklyAmount("deposit")
  // }
  // if(e.target.value=='Weekly-Withdraw'){
  //   weeklyAmount('withdrawal')
  // }
  // if(e.target.value=='Monthly-Withdraw'){
  //   monthlyAmount('withdrawal')
  // }
  // if(e.target.value=='Monthly-Deposit'){
  //   monthlyAmount('deposit')
  // }
 }
  const formatDate = (date) => moment(date).format("YYYY-MM-DD");
  const allAmt = () => {
    setNewRecord(record);
  };
  const itemFilter = (type) => {
    if (type === "all") {
      setNewRecord(record);
    } else {
      let data = record.filter((filtered) => filtered.type == type);

      setNewRecord(data);
    }
  }

  const todayAmount = (type) => {
    setNewRecord([]);
    const dateFilter = [];
    const todayDate = formatDate(new Date());
  
    record
      .filter(
        (item) =>
          item.createdAt.includes(todayDate) && item.type.includes(type)
      )
      .forEach((item) => {
        console.log("item---",item)
        dateFilter.push(item);
      });

    setNewRecord(dateFilter);
  };

  const weeklyAmount = (type) => {
    setNewRecord([]);
    const today = new Date();
    const dates = [];
    const weeklyFilter = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      dates.push(date.toISOString().split("T")[0]);
    }
    dates.map((item) => {
      const weeklydata = moment(item).format("YYYY-MM-DD");
      return record
        .filter(
          (filterDate) =>
            filterDate.createdAt.match(weeklydata) &&
            filterDate.type.includes(type)
        )
        .map((item) => {
          weeklyFilter.push(item);
          setNewRecord(weeklyFilter);
        });
    });
  };
  // ==========================monthly filter ======================//

  const monthlyAmount = (type) => {
    setNewRecord([]);
    const today = new Date();
    const dates = [];
    const monthlyDepositeFilter = [];

    for (let i = 0; i < 30; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      dates.push(date.toISOString().split("T")[0]);
    }

    const formattedDates = dates.map((item) =>
      moment(item).format("YYYY-MM-DD")
    );

    record
      .filter(
        (filterDate) =>
          formattedDates.includes(filterDate.createdAt.split("T")[0]) &&
          filterDate.type.includes(type)
      )
      .forEach((item) => {
        monthlyDepositeFilter.push(item);
      });

    setNewRecord(monthlyDepositeFilter);
  };

  const pageChange = (pageData) => {};

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="page-heading d-flex flex-wrap justify-content-between align-items-center">
              <div class="section-heading">
                <h2 class="text-black">
                  <b> Transaction Details</b>
                </h2>
              </div>
              <div className="filter-record">
                <select onChange={(e)=>{handleChange(e)}} value={filterValue}>
                  <option value="all">All</option>
                  <option value="deposit">Deposit</option>
                  <option value="withdrawal">Withdraw</option>
                  <option value="contest">Contest</option>
                  <option value="other">Other</option>
                  {/* <option value="deposit">Today's Deposit</option>
                  <option value="Weekly-Deposit">Weekly Deposit</option>
                  <option value="withdrawal">Today  Withdraw</option>
                  <option value="Weekly-Withdraw">Weekly Withdraw</option>
                  <option value="Monthly-Deposit">Monthly Deposit</option>
                   <option value="Monthly-Withdraw">Monthly Withdraw</option> */}
                </select>
              </div>
              {/* <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Filter Button
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <button onClick={() => ""} class="dropdown-item">
                      All Amount
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => ""}
                      class="dropdown-item"
                    >
                      Today Total Deposite
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => ""}
                      class="dropdown-item"
                    >
                      Weekly Deposite
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>""}
                      class="dropdown-item"
                    >
                      Monthly Deposite
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => ''}
                      class="dropdown-item"
                    >
                      Today Total Withdraw
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => ''}
                      class="dropdown-item"
                    >
                      Weekly Withdraw
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => ''}
                      class="dropdown-item"
                    >
                      Monthly Withdraw
                    </button>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer">
                  <div className="add-product-btn text-center"></div>
                  <ReactDatatable
                    config={config}
                    records={newRecord}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
