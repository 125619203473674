import React, { useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import "react-confirm-alert/src/react-confirm-alert.css";

export const Pushnotification = () => {
  const [record] = useState([]);

  const columns = [
    {
      key: "User Id",
      text: "User Id ",
      className: "User Id ",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "User Name ",
      text: "User Name ",
      className: "User Name ",
      align: "left",
      sortable: true,
    },
    {
      key: "Email Id",
      text: "Email Id",
      className: "Email Id",
      align: "left",
      sortable: true,
    },
    {
      key: "Phone No ",
      text: "Phone No ",
      className: "Phone No ",
      align: "left",
      sortable: true,
    },
    {
      key: "Sign Up Date ",
      text: "Sign Up Date ",
      className: "Sign Up Date ",
      align: "left",
      sortable: true,
    },
    {
      key: "DOC",
      text: "DOC",
      className: "DOC",
      align: "left",
      sortable: true,
    },
    {
      key: "Send Notifcation ",
      text: "Send Notifcation",
      className: "Send Notifcation",
      align: "left",
      sortable: true,
    },
    {
      key: "view",
      text: "Action",
      className: "view",
      align: "left",
      sortable: true,
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Push Notifcation</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer">
                  <div className="add-product-btn text-center"></div>
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
