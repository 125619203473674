import * as opsService from "./Ops";
// import { baseUrl } from "../Pages/BaseUrl";
import { getFixturePlayerById } from "../Constant/Api";

const getFixturePlayer = async (data, token) => {
    let result = await opsService.postdata(
      getFixturePlayerById,
      data,
      token
    );
    return result;
  };

  export {
    getFixturePlayer
  };
  