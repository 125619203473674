import React, { useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import "react-confirm-alert/src/react-confirm-alert.css";
function Withdrawalrequest() {
  const [record,] = useState([]);

  const columns = [
    {
      key: "Sr No.",
      text: "User Id",
      className: "User",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "User",
      text: "User",
      className: "User",
      align: "left",
      sortable: true,
    },
    {
      key: "Phone Description",
      text: "Phone Description",
      className: "Phone Description",
      align: "left",
      sortable: true,
    },
    {
      key: "Type",
      text: "Type",
      className: "Type",
      align: "left",
      sortable: true,
    },
    {
      key: "Match Info",
      text: "Match Info",
      className: "Match Info",
      align: "left",
      sortable: true,
    },

    {
      key: "Status",
      text: "Status",
      className: "Status",
      align: "left",
      sortable: true,
    },
    {
      key: "Amount",
      text: "Amount",
      className: "Amount",
      align: "left",
      sortable: true,
    },
    {
      key: "Created At",
      text: "Created At",
      className: "Created At",
      align: "left",
      sortable: true,
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {};

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Payments</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Withdrawalrequest;
