import * as opsService from "./Ops";
// import { baseUrl } from "../Pages/BaseUrl";
import { getTransaction,getWithdrawRequest,updateWithdrawRequest,todayDeposite,todayWithdraw,totalWithDrawRequest } from "../Constant/Api";
const transactionDetail = async(token) => {
    let result = await opsService.getData(getTransaction,token);
    return result;
  };
  const withdrawrequest = async(token) => {
    let result = await opsService.getData(getWithdrawRequest,token);
    return result;
  };
  const updateRequest = async(data,token) => {
    let result = await opsService.postdata(updateWithdrawRequest,data,token);
    return result;
  };
  const todayDeposit = async(token) => {
    let result = await opsService.getData(todayDeposite,token);
    return result;
  };
  const todayWithdrawal = async(token) => {
    let result = await opsService.getData(todayWithdraw,token);
    return result;
  };
  const totalWithdrawalRequest = async(token) => {
    let result = await opsService.getData(totalWithDrawRequest,token);
    return result;
  };

  export {
    transactionDetail,withdrawrequest,updateRequest,todayDeposit,todayWithdrawal,totalWithdrawalRequest
  };