import React, { useState, useEffect } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";

import { getSettingdata, updateSettingdata } from "../services/support";
import Accordion from "react-bootstrap/Accordion";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { toast } from "react-toastify";
import configs from "../Constant/Config";

export const Settings = () => {
  const [record, setRecord] = useState([]);

  useEffect(() => {
    getSettingdatas();
  }, []);

  const handleSubmit = async (type) => {
    let err = "This field is required";
    if (!minWithdraw) {
      setWithdrawMinAmounterr(err);
      return false;
    }
    if (!maxWithdraw) {
      setWithdrawMaxAmounterr(err);
      return false;
    }
    if (minWithdraw > maxWithdraw) {
      setWithdrawMinAmounterr("Please enter a valid amount");
      return false;
    }
    if (!tdsDeduction) {
      setTdsDeductionerr(err);
      return false;
    }
    if (!reffaralBonus) {
      setReffaralBonuserr(err);
      return false;
    }
    if (!signupBonus) {
      setSignupBonuserr(err);
      return false;
    }
    if (!usableBonus) {
      setUsableBonuserr(err);
      return false;
    }

    let data = {
      id: record[0].id,
      minWithdraw,
      maxWithdraw,
      tdsDeduction,
      reffaralBonus,
      signupBonus,
      usableBonus
    };
    let result = await updateSettingdata(data, configs);
    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
    }
  };

  const getSettingdatas = async () => {
    let data = await getSettingdata(configs);
    const {
      minWithdraw,
      maxWithdraw,
      tdsDeduction,
      reffaralBonus,
      signupBonus,
      usableBonus
    } = data.data[0];

    setRecord(data.data);
    setMinWithdraw(minWithdraw);
    setMaxWithdraw(maxWithdraw);
    setTdsDeduction(tdsDeduction);
    setReffaralBonus(reffaralBonus);
    setSignupBonus(signupBonus);
    setUsableBonus(usableBonus)
  };

  const [minWithdraw, setMinWithdraw] = useState("");
  const [tdsDeduction, setTdsDeduction] = useState("");
  const [reffaralBonus, setReffaralBonus] = useState("");
  const [signupBonus, setSignupBonus] = useState("");
  const [maxWithdraw, setMaxWithdraw] = useState("");
  const [usableBonus, setUsableBonus] = useState("")

  const [withdrawMaxAmounterr, setWithdrawMaxAmounterr] = useState("");
  const [withdrawMinAmounterr, setWithdrawMinAmounterr] = useState("");
  const [tdsDeductionerr, setTdsDeductionerr] = useState("");
  const [reffaralBonuserr, setReffaralBonuserr] = useState("");
  const [signupBonuserr, setSignupBonuserr] = useState("");
  const [usableBonuserr, setUsableBonuserr] = useState("")

  const handleChangeHandler = (e) => {
    let { name, value } = e.target;
    const validateErr = () => {
      if (value === "" || value === undefined) {
        return ["This field is required", false];
      } else {
        return ["", true];
      }
    };

    if (name === "withdrawMinAmount") {
      const [withdrawMinAmounterr] = validateErr(name, value);
      setMinWithdraw(value);
      setWithdrawMinAmounterr(withdrawMinAmounterr);
    }

    if (name === "withdrawMaxAmount") {
      const [withdrawMaxAmountmeErr] = validateErr(name, value);
      setMaxWithdraw(value);
      setWithdrawMaxAmounterr(withdrawMaxAmountmeErr);
    }
    if (parseFloat(minWithdraw > maxWithdraw)) {
      setWithdrawMinAmounterr("Please enter a valid amount");
      return false;
    } else if (parseFloat(minWithdraw < maxWithdraw)) {
      setWithdrawMinAmounterr("");
    }

    if (name === "tdsDeduction") {
      const [tdsDeductionErr] = validateErr(name, value);
      setTdsDeduction(value);
      setTdsDeductionerr(tdsDeductionErr);
    }
    if (name === "reffaralBonus") {
      const [reffaralBonusErr] = validateErr(name, value);
      setReffaralBonus(value);
      setReffaralBonuserr(reffaralBonusErr);
    }
    if (name === "signupBonus") {
      const [signupBonusErr] = validateErr(name, value);
      setSignupBonus(value);
      setSignupBonuserr(signupBonusErr);
    }
    if (name === "usableBonus") {
      const [usableBonusErr] = validateErr(name, value);
      setUsableBonus(value);
      setUsableBonuserr(usableBonusErr);
    }
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="section-heading">
              <h2>
                <b>Settings</b>
              </h2>
            </div>
            <div className="setting-accordion-outer">
              <div className="row">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Withdraw </Accordion.Header>
                    <AccordionBody>
                      <div className="row">
                        <div className="col-md-5 col-12">
                          <input
                            className="form-control"
                            type="number"
                            name="withdrawMinAmount"
                            value={minWithdraw}
                            placeholder="Enter Minimum Amount"
                            onChange={handleChangeHandler}
                          />

                          {withdrawMinAmounterr && (
                            <span style={{ color: "red" }}>
                              {withdrawMinAmounterr}
                            </span>
                          )}
                        </div>
                        <div className="col-md-5 col-12">
                          <input
                            className="form-control"
                            type="number"
                            name="withdrawMaxAmount"
                            value={maxWithdraw}
                            placeholder="Enter Maximum Amount"
                            onChange={handleChangeHandler}
                          />

                          {withdrawMaxAmounterr && (
                            <span style={{ color: "red" }}>
                              {withdrawMaxAmounterr}
                            </span>
                          )}
                        </div>
                        <div className="col-md-2 col-12 text-end">
                          <button
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={() => handleSubmit("Withdraw")}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </AccordionBody>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="1">
                    <Accordion.Header>TDS Deduction%</Accordion.Header>
                    <AccordionBody>
                      <div className="row">
                        <h5>Payment In</h5>
                        <div className="col-md-10 col-12">
                          <input
                            className="form-control"
                            type="number"
                            name="tdsDeduction"
                            value={tdsDeduction}
                            onChange={handleChangeHandler}
                            placeholder="Tds Deduction % *"
                          />
                          <span style={{ color: "red" }}>
                            {tdsDeductionerr}
                          </span>
                        </div>
                        <div className="col-md-2 col-12">
                          <button
                            onClick={() => handleSubmit("tdsDeduction")}
                            type="button"
                            className="btn btn-primary w-100"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </AccordionBody>
                  </Accordion.Item> */}
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Referral Bonus</Accordion.Header>
                    <AccordionBody>
                      <div className="row">
                        <h5>Reffaral Bonus</h5>
                        <div className="col-md-10 col-12">
                          <input
                            className="form-control"
                            type="number"
                            name="reffaralBonus"
                            value={reffaralBonus}
                            onChange={handleChangeHandler}
                            placeholder="Enter Referral Bonus*"
                          />
                          <span style={{ color: "red" }}>
                            {reffaralBonuserr}
                          </span>
                        </div>
                        <div className="col-md-2 col-12">
                          <button
                            onClick={() => handleSubmit("reffaralBonus")}
                            type="button"
                            className="btn btn-primary w-100"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </AccordionBody>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Sign Up Bonus</Accordion.Header>
                    <AccordionBody>
                      <div className="row">
                        <h5>Sign Up Price</h5>
                        <div className="col-md-10 col-12">
                          <input
                            className="form-control"
                            type="number"
                            name="signupBonus"
                            onChange={handleChangeHandler}
                            value={signupBonus}
                            placeholder="Enter Sign Up Bonus*"
                          />
                          <span style={{ color: "red" }}>{signupBonuserr}</span>
                        </div>
                        <div className="col-md-2 col-12">
                          <button
                            onClick={() => handleSubmit("signupBonus")}
                            type="button"
                            className="btn btn-primary w-100"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </AccordionBody>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Usable Bonus</Accordion.Header>
                    <AccordionBody>
                      <div className="row">
                        <h5>Usable Bonus</h5>
                        <div className="col-md-10 col-12">
                          <input
                            className="form-control"
                            type="number"
                            name="usableBonus"
                            onChange={handleChangeHandler}
                            value={usableBonus}
                            placeholder="Enter Usable Bonus*"
                          />
                          <span style={{ color: "red" }}>{usableBonuserr}</span>
                        </div>
                        <div className="col-md-2 col-12">
                          <button
                            onClick={() => handleSubmit("usableBonus")}
                            type="button"
                            className="btn btn-primary w-100"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </AccordionBody>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
