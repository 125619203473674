import React, { useState, useEffect } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import configs from "../Constant/Config";
// import { baseUrls } from "./BaseUrl";
import { baseUrls } from "../Constant/BaseUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    getplayerImage,UpdateplayerImage
} from "../services/player";

function PlayerImage() {
    const [playerId, setPlayerId] = useState("")
    const [record,setRecord ] = useState([]);
    const getdata = async () => {
      let res = await getplayerImage(configs);
      if(res.status){
        console.log("res----",res)
        setRecord(res.data)
        
      }
    };
    useEffect(() => {
      getdata();
    }, []);
    const columns = [
        {
          key: "srno.",
          text: "Sr.No.",
          className: "sr_no.",
          align: "left",
          sortable: true,
          cell: (row, index) => index + 1,
        },
        {
          key: "player_name",
          text: "Player Name",
          className: "name",
          align: "left",
          sortable: true,
         
        },
        {
          key: "Player_photo",
          text: "Image",
          className: "type",
          align: "left",
          sortable: true,
          cell:((response,index)=>{
             console.log("index",index)
            return(
            <div className="change-player-image position-relative">
            <img
              src={
                response.player_image == null
                  ? "/assets/images/person-icon.png"
                  : baseUrls + `/static/playerPic/${response.player_image}`
              }
              alt="hfghfgh"
              className="img-fluid"
            />
           
          </div>
            )
          })
        },
        {
          key: "Action",
          text: "Action",
          className: "action view",
          align: "left",
          sortable: true,
          cell:(response)=>{
            return (
              <div className="edit-profile">
              <input
                type="file"
                // ref={myref}
                id="img1"
                accept="image/png, image/jpeg, image/jpg"
                style={{ display: "none" }}
                onChange={(e)=>{
               
                  saveFile(e)              }}
                  name="playerphoto"
              />
              <label htmlFor="img1" className="editlabel" id={response.id} onClick={()=>setPlayerId(response.id)}>
                <i className="fa fa-edit" ></i>
              </label>
            
            </div>
            )
          }
         
        },
      ];
      const config = {
        page_size: 20,
        length_menu: [ 20, 50],
        filename: "Fund Request List",
        no_data_text: "No record found!",
        button: {
          // print: true,
          // csv: true,
        },
        language: {
          filter: "Filter in records...",
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
      };
      const saveFile = async (e) => {
        e.preventDefault();
        console.log("id--",playerId)
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
          toast.error("Select valid image format");
          return false;
        }
        const formData = new FormData();
        formData.append("playerphoto", e.target.files[0]);
        formData.append("playerid", playerId);
        try {
          let result = await UpdateplayerImage(formData, configs);
          console.log("res=====",result)
          if(result.status){
            toast.success(result.message);
            getdata()
          }
         
        } catch (ex) {
          console.log(ex);
        }
      };
  return (
    <div id="layout-wrapper">
    <Header />
    <Navbar />
    <div className="main-content ">
      <div className="page-content">
        <div className="container-fluid">
          <div className="section-heading">
            <h2>
              <b>Player</b>
            </h2>
          </div>
          <div className="row">
            <div className="col-md-12">
            
              
              <div className="buttonDirection">
                
              </div>
			  <div className="product-list-outer">
              <ReactDatatable
                config={config}
                records={record}
                columns={columns}
              />
            </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  )
}

export default PlayerImage
