import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
const baseUrl = myConstList.baseUrl;

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eye, seteye] = useState("fa-eye-slash");
  const [showCurrentPassword, setShowCurrentPassword] = useState("password");
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
    } else {
      window.location.href = "/dashboard";
    }
    if (localStorage.checkbox && localStorage.email !== "") {
      setEmail(localStorage.email);
      setPassword(localStorage.password);
    }
  }, []);
  const showcurrentPassword = () => {
   
    if (showCurrentPassword === "password") {
   
      setShowCurrentPassword("text");
      seteye("fa-eye");
    } else {
      setShowCurrentPassword("password");
      seteye("fa-eye-slash");
    }
  };

  const loginInputTypes = async (type, event) => {
    if (type === "email") {
      setEmail(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  const handleOnBlurEmail = async (event) => {
    var eventValue = await event.target.value;
    var Email1Reg = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      email
    );
    if (!eventValue) {
      toast.dismiss();
      toast.error("Enter Email Address");
      return false;
    }
    if (!Email1Reg) {
      toast.dismiss();
      toast.error("Enter valid Email Address");
      return false;
    }
  };
  const handleOnBlurPassword = async (event) => {
    var password = await event.target.value;
    if (!password) {
      toast.dismiss();
      toast.error("Password is required");
      return false;
    }
  };
  const onLogin = async (event) => {
    event.preventDefault();
    let LoginData = {
      email,
      password,
    };

    axios.post(baseUrl + "/login-admin", LoginData).then((resp) => {
      var respo = resp.data;
      if (respo.status === false) {
        toast.dismiss();
        toast.error(respo.message);
        return;
      }
      if (respo.status === true) {
        let token = respo.token;
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userType", respo.data[0].userType);
        sessionStorage.setItem("jwtToken", token);
        toast.success("Login Successfully");
        setTimeout(
          function () {
            window.location.href = "/dashboard";
          }.bind(this),
          2000
        );
        return false;
      }
    });
  };
  return (
    <div className="login d-flex nm-aic nm-vh-md-100">
      <div className="nm-tm-wr">
        <div className="container p-0">
          <div className="container p-0">
            <img
              src="assets/images/logo.png"
              alt=""
              className="img-fluid login_logo"
            />
          </div>
          <form>
            <div className="input-group nm-gp">
              <input
                type="text"
                className="form-control"
                id="inputUsername"
                tabIndex={1}
                placeholder="Email"
                required=""
                onChange={(e) => loginInputTypes("email", e)}
                onBlur={handleOnBlurEmail}
              />
            </div>
            <div className="input-group nm-gp password-group">
              <input
                 type={showCurrentPassword}
                className="form-control password"
                id="inputPassword"
                tabIndex={2}
                placeholder="Password"
                required=""
                onChange={(e) => loginInputTypes("password", e)}
                onBlur={handleOnBlurPassword}
              />
              <span
                role="button"
                onClick={showcurrentPassword}
                className="eye-icon"
              >
                <i className={`fa ${eye}`}></i>
              </span>
            </div>
            {/* <div className="input-group nm-gp">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="rememberMe"
                />
                <label
                  className="form-check-label nm-check"
                  htmlFor="rememberMe"
                >
                  Keep me logged in
                </label>
              </div>
            </div> */}
            <div className=" nm-aic nm-mb-1">
              <div className="login-btn">
                <button
                  type="submit"
                  className="btn btn-primary nm-hvr nm-btn-2 w100"
                  onClick={onLogin}
                >
                  Log In
                </button>
              </div>
              {/* <div className="col-sm-6 nm-sm-tr">
                <Link className="nm-fs-1 nm-fw-bd" to="">
                  Forgot Password?
                </Link>
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Login;
