import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-bootstrap/Modal";
import configs from "../Constant/Config";
import { inputFieldValidation } from "../Validations/validation";
import {
  getfantasydata,
  addpoint,
  fantasyPointById,
  fantasyPointDelete,
  fantasyupdates,
} from "../services/savefantasy";
import { Link } from "react-router-dom";
import ReactDatatable from "@mkikets/react-datatable";

export const Fantasypoints = () => {
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [Tab, setTab] = useState("T20");
  const [fantastydata, setfantastydata] = useState([]);
  const [id, setid] = useState("");
  const [name, setName] = useState("");
  const [category, setcategory] = useState("");
  const [point, setPoint] = useState("");

  // ============ errors========================//
  const [nameerr, setNameerr] = useState(false);
  const [categoryerr, setcategoryerr] = useState(false);
  const [pointserr, setpointserr] = useState(false);
  useEffect(() => {
    getfantasydatas(Tab);
  }, []);
  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "name",
      text: "Name",
      className: "fa-name",
      align: "left",
      sortable: true,
    },
    {
      key: "category",
      text: "Category",
      className: "fa-category",
      align: "left",
      sortable: true,
    },
    {
      key: "point",
      text: "Points",
      className: "fa-points",
      align: "left",
      sortable: true,
    },

    {
      key: "action",
      text: "Action",
      className: "Action d-flex align-items-center view ",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link
              to=""
              onClick={() => {
                setid(record.id);
                showUpdateModal(record.id);
              }}
              style={{ cursor: "pointer" }}
            >
               <span className="mdi mdi-square-edit-outline"></span>
            </Link>
            <Link to="" onClick={() => Conn(record.id, "T20")}>
              <span className="mdi mdi-trash-can-outline"></span>
            </Link>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Filter in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  // ================= show for t20================
  const showAddModal = () => {
    setAddModal(true);
  };
  const handleclosebutton = () => {
    setName("");
    setcategory("");
    setPoint("");
    setNameerr("");
    setcategoryerr("");
    setpointserr("");
    setAddModal(false);
    setUpdateModal(false);
  };

  const showUpdateModal = (id) => {
    setUpdateModal(true);
    FantsyDataByid(id);
  };

  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      setName(value);
      const err = inputFieldValidation(name, value);
      setNameerr(err);
    }
    if (name === "categorytype") {
      setcategory(value);
      const err = inputFieldValidation(name, value);
      setcategoryerr(err);
    }

    if (name === "point") {
      setPoint(value);
      const err = inputFieldValidation(name, value);
      setpointserr(err);
    }
  };
  // ============= API INTEGRATION START ================//
  const onsubmit = async () => {
    let err = "This field is required";
    if (!name) {
      setNameerr(err);
      return false;
    }
    if (!category) {
      setcategoryerr(err);
      return false;
    }
    if (!point) {
      setpointserr(err);
      return false;
    }

    let data = {
      name,
      category,
      point: point,
      type: Tab,
    };
    let result = await addpoint(data, configs);
    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
    }
    getfantasydatas(Tab);
    // getdata(Tab)
    handleclosebutton();
  };

  const getfantasydatas = async (Tab) => {
    const data = {
      type: Tab,
    };
    let result = await getfantasydata(data, configs);
    // const { data, t10data, ODIdata, testdata } = result;
    // if (result.status === true) {
    //   setT20Data(data);
    //   setT10data(t10data);
    //   setODIdata(ODIdata);
    //   setTestdata(testdata);
    // }
  
    setfantastydata(result.data);
  };
  const pointupdate = async () => {
    let err = "This field is required";
    if (!name) {
      setNameerr(err);
      return false;
    }
    if (!category) {
      setcategoryerr(err);
      return false;
    }
    if (!point) {
      setpointserr(err);
      return false;
    }
    let data = {
      id,
      name,
      category,
      point,
    };
    let result = await fantasyupdates(data, configs);
    if (result.status === true) {
      toast.dismiss();
      toast.success(Tab + result.message);
    }
    handleclosebutton();
    getfantasydatas(Tab);
  };

  const FantsyDataByid = async (id) => {
    let datas = {
      id,
    };
    let result = await fantasyPointById(datas, configs);
    const { name, category, point } = result.data[0];
    setName(name);
    setcategory(category);
    setPoint(point);
  };

  const deletebyid = async (id) => {
    let deleteid = {
      id,
    };
    let result = await fantasyPointDelete(deleteid, configs);
    if (result.status === false) {
      toast.dismiss();
      toast.error(result.message);
      return false;
    }

    if (result.status === true) {
      toast.dismiss();
      toast.success(Tab + result.message);
    }
    getfantasydatas(Tab);
  };

  const Conn = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deletebyid(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content ">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>Fantasy Points</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-auto mb-3 btn_4">
                  <button
                    onClick={() => {
                      setTab("T20");

                      getfantasydatas("T20");
                    }}
                    style={{background:Tab=='T20'?"green":"white"}}
                  >
                    T20
                  </button>
                  <button
                    onClick={() => {
                      setTab("T10");

                      getfantasydatas("T10");
                    }}
                    style={{background:Tab=='T10'?"green":"white"}}
                  >
                    T10
                  </button>
                  <button
                    onClick={() => {
                      setTab("oneday");

                      getfantasydatas("oneday");
                    }}
                    style={{background:Tab=='oneday'?"green":"white"}}
                  >
                    ODI
                  </button>
                  <button
                    onClick={() => {
                      setTab("Test");

                      getfantasydatas("Test");
                    }}
                    style={{background:Tab=='Test'?"green":"white"}}
                  >
                    Test
                  </button>
                  </div>
				   <div className="col-auto ml-auto">
                  <div className="buttonDirection">
                    <button onClick={showAddModal} className="btn btn-primary">
                      Add {Tab} Point
                    </button>
                  </div>
				  
				   </div>
              </div>
                  <div className="product-list-outer">
				 <ReactDatatable
                    config={config}
                    records={fantastydata}
                    columns={columns}
                  />
               </div>
              <div className="row">
                <div className="col-xxl-5 text-center">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center">
                      {/* ============================================t20modal========================================== */}
                      <Modal show={addModal} onHide={handleclosebutton}>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Title style={{ color: "black" }}>
                          {`Add ${Tab} Point Details`}
                        </Modal.Title>
                        <Modal.Body>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Name </Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                value={name}
                                placeholder="Enter Name"
                                autoFocus
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{nameerr}</span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Category Type </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="categorytype"
                                onChange={handlechange}
                              >
                                <option selected disabled>
                                  Please select
                                </option>
                                <option value="Bowling">Bowling</option>
                                <option value="Batting">Batting</option>
                                <option value="Fielding">Fielding</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {categoryerr}
                              </span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Point</Form.Label>
                              <Form.Control
                                type="number"
                                name="point"
                                placeholder="Enter Point"
                                value={point}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{pointserr}</span>
                            </Form.Group>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleclosebutton}
                          >
                            Close
                          </Button>
                          <Button variant="secondary" onClick={onsubmit}>
                            Save
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      {/*======================================================= update modal============================ */}
                      <Modal show={updateModal} onHide={handleclosebutton}>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Title style={{ color: "black" }}>
                          {`Update ${Tab} Point Details`}
                        </Modal.Title>
                        <Modal.Body>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Name </Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                value={name}
                                placeholder="Enter Name"
                                onChange={handlechange}
                                disabled
                              />
                              <span style={{ color: "red" }}>{nameerr}</span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Category Type </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="categorytype"
                                onChange={handlechange}
                                value={category}
                                disabled
                              >
                                <option value="Bowling">Bowling</option>
                                <option value="Batting">Batting</option>
                                <option value="Fielding">Fielding</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {categoryerr}
                              </span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Point</Form.Label>
                              <Form.Control
                                type="number"
                                name="point"
                                placeholder="Enter Point"
                                value={point}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{pointserr}</span>
                            </Form.Group>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleclosebutton}
                          >
                            Close
                          </Button>
                          <Button variant="secondary" onClick={pointupdate}>
                            Save
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
