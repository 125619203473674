// import React from "react";
import * as opsService from "./Ops";
// import { baseUrl } from "../Pages/BaseUrl";
import { addFantasyPoint,getFantasyPoint,deleteFantasyPoint,updateFantasyPoint,getFantasyPointById } from "../Constant/Api";
// import config from "../Constant/Config";

const getfantasydata = async (data,token) => {
  let result = await opsService.postdata(
    getFantasyPoint,
    data,token
  );
  return result;
};

const addpoint = async (data,token) => {
  let result = await opsService.postdata(
     addFantasyPoint,
    data,token
  );

  return result;
};
const fantasyPointById = async (data,token) => {
  let result = await opsService.postdata(
    getFantasyPointById,
    data,token
  );
  return result;
};
const fantasyPointDelete = async (data,token) => {
  let result = await opsService.postdata(
    deleteFantasyPoint,
    data,token
  );
  return result;
};
const fantasyupdates = async (data,token) => {
    
    
  let result = await opsService.postdata(
    updateFantasyPoint,
    data,token
  );
  return result;
};

export {
  getfantasydata,
  addpoint,
  fantasyPointById,
  fantasyPointDelete,
  fantasyupdates,



};
