import React, { useState, useEffect, Fragment } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  addbanner,
  getbanner,
  bannerDelete,
  bannerById,
  bannerUpdate,
} from "../services/banner";
import ReactDatatable from "@mkikets/react-datatable";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// import { baseUrls } from "./BaseUrl";
import { baseUrls } from "../Constant/BaseUrl";
import configs from "../Constant/Config";
import { confirmAlert } from "react-confirm-alert";
import { inputFieldValidation } from "../Validations/validation";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

export const Banner = () => {
  const [record, setRecord] = useState([]);
  const [userid, setUserid] = useState([]);

 
  const [bannertype, setBannerType] = useState("");
  const [s_time, setS_time] = useState("");
  const [e_time, setE_time] = useState("");
  const [s_date, setS_date] = useState("");
  const [e_date, setE_date] = useState("");
  const [bannertypeerr, setBannerTypeErr] = useState("");
  const [image, setImage] = useState("");
  const [disable, setdisable] = useState(false)

  const [imageerr, setImageErr] = useState(false);
  const [s_timeErr, setS_timeErr] = useState(false);
  const [e_timeErr, setE_timeErr] = useState(false);
  const [s_dateErr, setS_dateErr] = useState(false);
  const [e_dateErr, setE_dateErr] = useState(false);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const handleClose = () => {
    setBannerType("");
    setImage("");
    setBannerTypeErr("");
    setImageErr("");
    setShow(false);
    setEditShow(false);
  };
  const handleShow = (id) => {
    setShow(true);
  };
  const handleeditShow = (id) => {
    getbannerbyid(id);
    setEditShow(true);
    setUserid(id);
  };

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "bannerImage",
      text: "Banner",
      className: "Banner",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <img
              style={{ height: "50px" }}
              src={baseUrls + "/static/banner/" + record.bannerImage}
              alt=""
            />
          </Fragment>
        );
      },
    },

    {
      key: "banner_type",
      text: "Banner Type",
      className: "Banner Type",
      align: "left",
      sortable: true,
    },
    {
      key: "start_time",
      text: "Start Time",
      className: "startTime",
      align: "left",
      sortable: true,
    },
    {
      key: "end_time",
      text: "End Time",
      className: "endDendTimeate",
      align: "left",
      sortable: true,
    },
    {
      key: "start_date",
      text: "Start Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.start_date;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "end_date",
      text: "End Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.end_date;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action d-flex align-items-center ",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <Link
              to=""
              onClick={() => {
                handleeditShow(record.id);
              }}
              style={{ cursor: "pointer" }}
            >
              <span className="mdi mdi-square-edit-outline"></span>
            </Link>
            <Link
              to=""
              onClick={() => deleteBanner(record.id)}
              style={{ marginLeft: "5px" }}
            >
              <span className="mdi mdi-trash-can-outline"></span>
            </Link>
          </Fragment>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  const onsubmit = async (e) => {
    setdisable(true)
    setTimeout(() => {
      setdisable(false)
    }, 2000);
    if (!bannertype) {
      setBannerTypeErr("This field is required");
      return false;
    }
    if (!image || image === undefined) {
      setImageErr("This field is required");
      return false;
    }
    if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setImageErr("Select valid image format");
      return false;
    }
    if (!s_time) {
      setS_timeErr("This field is required");
      return false;
    }
    if (!s_date) {
      setS_dateErr("This field is required");
      return false;
    }
    if (!e_time) {
      setE_timeErr("This field is required");
      return false;
    }
    if (!e_date) {
      setE_dateErr("This field is required");
      return false;
    }
    const formdata = new FormData();
    formdata.append("bannertype", bannertype);
    formdata.append("bannerimage", image);
    formdata.append("start_time", s_time);
    formdata.append("end_time", e_time);
    formdata.append("start_date", s_date);
    formdata.append("end_date", e_date);
    let result = await addbanner(formdata, configs);
    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
      getbannerdata();
      handleClose();
    }
  };
  const getbannerdata = async () => {
    let results = await getbanner(configs);
    setRecord(results.data);
  };
  const getbannerbyid = async (id) => {
    let datas = {
      id,
    };
    let results = await bannerById(datas, configs);
    let {
      banner_type,
      start_time,
      end_time,
      start_date,
      end_date,
      bannerImage,
    } = results.data[0];
    console.log(bannerImage, "bannerImage");

    let start_dates = moment(start_date).format("YYYY-MM-DD");
    let end_dates = moment(end_date).format("YYYY-MM-DD");
    // console.log(dates, "dates");

    setBannerType(banner_type);
    setS_time(start_time);
    setE_time(end_time);
    setS_date(start_dates);
    setE_date(end_dates);
    setImage(baseUrls + "/static/banner/" + bannerImage);
  };
  const onUpdateBanner = async () => {
    setdisable(true)
    setTimeout(() => {
      setdisable(false)
    }, 2000);
    if (!s_time) {
      setS_timeErr("This field is required");
      return false;
    }
    if (!s_date) {
      setS_dateErr("This field is required");

      return false;
    }
    if (!e_time) {
      setE_timeErr("This field is required");

      return false;
    }
    if (!e_date) {
      setE_dateErr("This field is required");

      return false;
    }

    const formdata = new FormData();

    formdata.append("bannertype", bannertype);
    formdata.append("bannerImage", image);
    formdata.append("start_time", s_time);
    formdata.append("start_date", s_date);
    formdata.append("end_time", e_time);
    formdata.append("end_date", e_date);
    formdata.append("id", userid);

    let result = await bannerUpdate(formdata, configs);
    if (result.status === true) {
      toast.dismiss();
      toast.success(result.meassge);
    }
    getbannerdata();
    handleClose();
  };

  const bannerDeleteApi = async (id) => {
    let data = {
      id,
    };

    let result = await bannerDelete(data, configs);
    if (result.status === false) {
      toast.dismiss();
      toast.error(result.message);
      return false;
    }

    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
      getbannerdata();
    }

    console.log(result, "result");
  };
  const deleteBanner = (Deleteid) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => bannerDeleteApi(Deleteid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  useEffect(() => {
    getbannerdata();
  }, []);
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "bannertype") {
      setBannerType(value);
      const err = inputFieldValidation(name, value);
      setBannerTypeErr(err);
    }
    if (name === "s_time") {
      setS_time(value);
      const err = inputFieldValidation(name, value);
      setS_timeErr(err);
    }
    if (name === "s_date") {
      setS_date(value);
      const err = inputFieldValidation(name, value);
      setS_dateErr(err);
    }
    if (name === "e_time") {
      setE_time(value);
      const err = inputFieldValidation(name, value);
      setE_timeErr(err);
    }
    if (name === "e_date") {
      setE_date(value);
      const err = inputFieldValidation(name, value);
      setE_dateErr(err);
    }
  };
  const handlechangeimage = (e) => {
    let { name, value } = e.target;

    const image = e.target.files[0];
    if (name === "bannerimage") {
      if (value !== "" || value !== undefined) {
        setImage(e.target.files[0]);
        setImageErr("");
      } else {
        setImage(e.target.files[0]);
        setImageErr("This field is required");
      }
      if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        setImageErr("Select valid image format");
        return false;
      }
    }
  };
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>Slider Banner</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-5 ">
                  <div className="product-list-outer">
                    <div className="add-product-btn ">
                      <button
                        onClick={() => handleShow()}
                        className="btn btn-primary"
                      >
                        Add Banner
                      </button>

                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          {" "}
                          <Modal.Title style={{ color: "black" }}>
                            Add Banner
                          </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Banner Type </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="bannertype"
                                onChange={handlechange}
                              >
                                <option selected disabled>
                                  Please select
                                </option>
                                <option value="match-type">Match Type</option>
                                <option value="invite-type">Invite type</option>
                                <option value="offer-type">Offer Type</option>
                                <option value="leaderboard">LEADERBOARD</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {bannertypeerr}
                              </span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Banner Image</Form.Label>
                              <img
                                style={{ width: "100px" }}
                                src={image}
                                className="img-fluid"
                                alt=""
                              />
                              <Form.Control
                                type="file"
                                name="bannerimage"
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={handlechangeimage}
                              />
                              <span style={{ color: "red" }}>{imageerr}</span>
                            </Form.Group>
                            <Row>
                              <Col xs={12} md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Start Time</Form.Label>
                                  <Form.Control
                                    type="time"
                                    name="s_time"
                                    onChange={handlechange}
                                  />{" "}
                                  {s_timeErr && (
                                    <span style={{ color: "red" }}>
                                      {s_timeErr}
                                    </span>
                                  )}
                                </Form.Group>{" "}
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Start Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="s_date"
                                    onChange={handlechange}
                                    min={new Date().toISOString().substr(0, 10)}
                                  />{" "}
                                  {s_dateErr && (
                                    <span style={{ color: "red" }}>
                                      {s_dateErr}
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>End Time</Form.Label>
                                  <Form.Control
                                    type="time"
                                    name="e_time"
                                    onChange={handlechange}
                                  />
                                  {e_timeErr && (
                                    <span style={{ color: "red" }}>
                                      {e_timeErr}
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>

                              <Col xs={12} md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>End Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="e_date"
                                    onChange={handlechange}
                                    min={s_date}
                                  />{" "}
                                  {e_dateErr && (
                                    <span style={{ color: "red" }}>
                                      {e_dateErr}
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={onsubmit} disabled={disable}>
                            Save
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <Modal show={editShow} onHide={handleClose}>
                        <Modal.Header closeButton><Modal.Title style={{ color: "black" }}>
                          Update Banner
                        </Modal.Title></Modal.Header>
                        
                        <Modal.Body>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Banner Type </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="bannertype"
                                onChange={handlechange}
                                value={bannertype}
                              >
                                <option value="match-type">Match Type</option>
                                <option value="invite-type">Invite type</option>
                                <option value="offer-type">Offer Type</option>
                                <option value="leaderboard">LEADERBOARD</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {bannertypeerr}
                              </span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Banner Image</Form.Label>
                              <img
                                style={{ width: "100px" }}
                                src={image}
                                className="img-fluid"
                                alt=""
                              />
                              <Form.Control
                                type="file"
                                name="bannerimage"
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={handlechangeimage}
                              />
                              <span style={{ color: "red" }}>{imageerr}</span>
                            </Form.Group>
                            <Row>
                              <Col xs={12} md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Start Time</Form.Label>
                                  <Form.Control
                                    type="time"
                                    name="startTime"
                                    value={s_time}
                                    onChange={handlechange}
                                  />{" "}
                                  {s_timeErr && (
                                    <span style={{ color: "red" }}>
                                      {s_timeErr}
                                    </span>
                                  )}
                                </Form.Group>{" "}
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label> Start Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="s_date"
                                    value={s_date}
                                    onChange={handlechange}
                                  />{" "}
                                  {s_dateErr && (
                                    <span style={{ color: "red" }}>
                                      {s_dateErr}
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>End Time</Form.Label>
                                  <Form.Control
                                    type="time"
                                    name="e_time"
                                    value={e_time}
                                    onChange={handlechange}
                                  />
                                  {e_timeErr && (
                                    <span style={{ color: "red" }}>
                                      {e_timeErr}
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label> End Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="e_date"
                                    value={e_date}
                                    onChange={handlechange}
                                  />{" "}
                                  {e_dateErr && (
                                    <span style={{ color: "red" }}>
                                      {e_dateErr}
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={onUpdateBanner} disabled={disable}>
                            Save
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <ReactDatatable
                      config={config}
                      records={record}
                      columns={columns}
                      onPageChange={pageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
