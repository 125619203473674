import React, { useState, useEffect, Fragment } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
    addpromotionbanner,
    getpromotionbanner,
    promotionBannerDelete,
    promotionBannerupdate
} from "../services/banner";
import ReactDatatable from "@mkikets/react-datatable";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// import { baseUrls } from "./BaseUrl";
import { baseUrls } from "../Constant/BaseUrl";
import configs from "../Constant/Config";
import { confirmAlert } from "react-confirm-alert";
import { inputFieldValidation } from "../Validations/validation";
// import Col from "react-bootstrap/esm/Col";
// import Row from "react-bootstrap/esm/Row";

export const PromotionBanner = () => {
  const [record, setRecord] = useState([]);

  const handleClose = () => {
    setTitle("");
    setImage("");
    setTitleErr("");
    setImageErr("");
    setShow(false);
   
  };
  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");
  const [image, setImage] = useState("");

  const [imageErr, setImageErr] = useState(false);
  const [show, setShow] = useState(false);



  const handleShow = (id) => {
    setShow(true);
  };


  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "promotion_banner",
      text: "Promotion Banner",
      className: "Banner",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <img
              style={{ height: "50px" }}
              src={baseUrls + "/static/banner/" + record.promotion_banner}
              alt=""
            />
          </Fragment>
        );
      },
    },

    {
      key: "title",
      text: "Title",
      className: "title",
      align: "left",
      sortable: true,
    },
   
   
    {
      key: "create_at",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.create_at;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
        key: "status",
        text: "Status",
        className: "Action ",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <button style={{border:"1px solid #fff", borderRadius:"3px", background:record.status==='1'?"green":"#d10202"}}  onClick={() => updateAlert(record.id)}>
               <i className="fa fa-lock" style={{color:"#fff"}}></i>
              </button>
            </>
          );
        },
      },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <Link to="" onClick={() => deleteBanner(record.id)}>
               <span className="mdi mdi-trash-can-outline"></span>
            </Link>
          </Fragment>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };


  const onsubmit = async (e) => {
    if (!title) {
      setTitleErr("This field is required");
      return false;
    }
    if (!image || image === undefined) {
      setImageErr("This field is required");
      return false;
    }
    if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setImageErr("Select valid image format");
      return false;
    }

    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("bannerimage", image);
    let result = await addpromotionbanner(formdata, configs);
    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
      getbannerdata();
      handleClose();
    }
  };
  const getbannerdata = async () => {
    let results = await getpromotionbanner(configs);
    if(results.status){

        setRecord(results.data);
    }
  };
 
 
  const bannerDeleteApi = async (id) => {
    let data = {
      id,
    };
    
    let result = await promotionBannerDelete(data, configs);
    if (result.status === false) {
      toast.dismiss();
      toast.error(result.message);
      return false;
    }

    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
      getbannerdata();
    }

  };
  const bannerUpdateStatus = async (id) => {
    let data = {
      id,
    };
    
    let result = await promotionBannerupdate(data, configs);
    if (result.status === false) {
      toast.dismiss();
      toast.error(result.message);
      return false;
    }

    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
      getbannerdata();
    }

  };
  const deleteBanner = (Deleteid) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => bannerDeleteApi(Deleteid),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const updateAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => bannerUpdateStatus(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  useEffect(() => {
    getbannerdata();
  }, []);
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "title") {
      setTitle(value);
      const err = inputFieldValidation(name, value);
      setTitleErr(err);
    }
  };
  const handlechangeimage = (e) => {
    let { name, value } = e.target;

    const image = e.target.files[0];
    if (name === "bannerimage") {
      if (value !== "" || value !== undefined) {
        setImage(e.target.files[0]);
        setImageErr("");
      } else {
        setImage(e.target.files[0]);
        setImageErr("This field is required");
      }
      if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        setImageErr("Select valid image format");
        return false;
      }
    }
  };
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>Banners</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-5 ">
                  <div className="product-list-outer">
                    <div className="add-product-btn ">
                      <button
                        onClick={() => handleShow()}
                        className="btn btn-primary"
                      >
                        Add Banner
                      </button>

                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton> <Modal.Title style={{ color: "black" }}>
                          Add Promotion Banner
                        </Modal.Title></Modal.Header>
                       
                        <Modal.Body>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Title </Form.Label>
                              <Form.Control
                                name="title"
                                onChange={handlechange}
                                type="text"
                         
                              >
                              </Form.Control>
                              <span style={{ color: "red" }}>
                                {titleErr}
                              </span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Banner Image</Form.Label>
                              <img
                                style={{ width: "100px" }}
                                src={image}
                                className="img-fluid"
                                alt=""
                              />
                              <Form.Control
                                type="file"
                                name="bannerimage"
                                onChange={handlechangeimage}
                              />
                              <span style={{ color: "red" }}>{imageErr}</span>
                            </Form.Group>
                          
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={onsubmit}>
                            Save
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    
                    </div>
                    <ReactDatatable
                      config={config}
                      records={record}
                      columns={columns}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
