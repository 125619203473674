import React, { useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { bankDetail } from "../services/user";
import configs from "../Constant/Config";
export const UserBankDetail = (props) => {
 
  const [holderName, setHolderName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [ifscCode, setIfscCode] = useState("")
 
  useEffect(() => {
    if (props.id) {
      getBankDetail();
    }
  }, [props.id]);
  const getBankDetail = async () => {
    let data = {
      id: props.id,
    };
    const result = await bankDetail(data, configs);
    
    if (result.status) {
        console.log("results==",result.data)
        let res=result.data[0]
        setHolderName(res.holder_name)
        setAccountNo(res.account_number)
        setIfscCode(res.ifsc_code)
    //   setBankDetail(result.data);
     
    }
  };

 

  return (
    <>
      {" "}
      <Modal
        {...props}
        dialogClassName="modal-1000w"
        size="md"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {/* closeButton */}
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Bank Detail
          </Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body>
          <Container>
             <p><strong>Holder Name : </strong> {holderName}</p>
             <p><strong>Account No. : </strong> {accountNo}</p>
             <p><strong>IFSC Code : </strong> {ifscCode}</p>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
