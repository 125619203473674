import React, { useState, useEffect } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-bootstrap/Modal";
import configs from "../Constant/Config";
import moment from "moment";
import { inputFieldValidation } from "../Validations/validation";

import {
  getcategory,
  addcategory,
  getcategorydatabyid,
  categorydelete,
  categoryupdates,
} from "../services/contest";
import { Link } from "react-router-dom";

const Contestcategories = () => {
  const [record, setRecord] = useState([]);
  const [userid, setUserid] = useState([]);
  const [nameerror, setnameerror] = useState(false);
  const [taglineerror, settaglineerror] = useState(false);
  const handleClose = () => {
    setName("");
    setTagline("");
    setnameerror("");
    settaglineerror("");
    setShow(false);
    seteditShow(false);
  };

  const [name, setName] = useState("");
  const [tagline, setTagline] = useState("");
  const [show, setShow] = useState(false);
  const [editshow, seteditShow] = useState(false);
  const handleShow = (id) => {
    setShow(true);
  };
  const handleeditShow = (id) => {
    iddata(id);
    seteditShow(true);
    setUserid(id);
  };
  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "Name",
      align: "left",
      sortable: true,
    },

    {
      key: "tagline",
      text: "Tagline",
      className: "Tagline",
      align: "left",
      sortable: true,
    },
    {
      key: "createdAt",
      text: "Date & time",
      className: "date",
      align: "left",
      sortable: true,
      cell:(res)=>{
        return (
          <>{moment(res.createdAt).format("DD-MM-YYYY, hh:mm:ss A")}</>
        )
      }
    },

    {
      key: "view",
      text: "Action",
      className: "view",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link
              to=""
              onClick={() => {
                handleeditShow(record?.id);
              }}
              style={{ float: "left", cursor: "pointer" }}
            >
             <span className="mdi mdi-square-edit-outline"></span>
            </Link>
            <Link
              to=""
              onClick={() => Conn(record?.id)}
              style={{ marginLeft: "5px" }}
            >
             <span className="mdi mdi-trash-can-outline"></span>
            </Link>
          </>
        );
      },
    },

  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Filter in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  // ========== api integration==============//

  const getcategorydata = async () => {
    let result = await getcategory(configs);
    if (result.status === true) {
      setRecord(result.data.categorydata);
    }
  };

  const onsubmit = async (e) => {
    let err = "This field is required";
    if (!name) {
      setnameerror(err);

      return false;
    }
    if (name.match(/^\s/)) {
      setnameerror("Please enter a valid name");

      return false;
    }
    if (!tagline) {
      settaglineerror(err);

      return false;
    }
    if (tagline.match(/^\s/)) {
      settaglineerror("Please enter a valid tagline");

      return false;
    }

    let data = {
      name,
      tagline,
    };

    let result = await addcategory(data, configs);
    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
    
      setTimeout(() => {
        getcategorydata();
        handleClose();
      }, 2000);
      
    }
    else{
      toast.dismiss();
      toast.error(result.message);
    }

 
  };

  // =============get categoryby id ===============//
  const iddata = async (categoriesId) => {
    let datas = {
      categoriesId,
    };
    let results = await getcategorydatabyid(datas, configs);
    const { name, tagline } = results.data[0];
    setName(name);
    setTagline(tagline);
  };

  // ========= delate category =============//

  const deletebyid = async (categoriesId) => {
    let deleteid = {
      categoriesId,
    };
    let result = await categorydelete(deleteid, configs);

    if (result.status === false) {
      toast.dismiss();
      toast.error(result.message);
      return;
    }

    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
    }
    getcategorydata();
  };


  const Conn = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deletebyid(id),
        },
        {
          label: "No",
          
        },
      ],
    });
  };
  // ============update category api ===========/
  const onupdatecategory = async () => {
    let err = "This field is required";
    if (!name) {
      setnameerror(err);

      return false;
    }
    if (name.match(/^\s/)) {
      setnameerror("Please enter a valid name");

      return false;
    }
    if (!tagline) {
      settaglineerror("This field is required");

      return false;
    }
    if (tagline.match(/\s/)) {
      settaglineerror("Please enter a valid tagline");

      return false;
    }


    let updatedata = {
      categoriesId: userid,
      name,
      tagline,
    };
    let result = await categoryupdates(updatedata, configs);

    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
    }
    getcategorydata();
    handleClose();
  };

  // ============update category api===========/
  useEffect(() => {
    getcategorydata();
  }, []);

  // ========handlechange for add model==========

  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      setName(value);
      const err = inputFieldValidation(name, value);
      setnameerror(err);
    }
    if (name === "tagline") {
      setTagline(value);
      const err = inputFieldValidation(name, value);
      settaglineerror(err);
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Contest Category</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer">
                  <div className="add-product-btn text-center">
                    <button
                      onClick={() => handleShow()}
                      className="btn btn-primary"
                    >
                      Create Categories
                    </button>

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton><Modal.Title style={{ color: "black" }}>
                        Create Category
                      </Modal.Title></Modal.Header>
                      
                      <Modal.Body>
                        <Form>
                          <Form.Group className="mb-3">
                            <Form.Label> Name </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={name}
                              placeholder="Enter Name"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{nameerror}</span>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Tagline</Form.Label>
                            <Form.Control
                              type="text"
                              name="tagline"
                              placeholder="Enter Tagline"
                              value={tagline}
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{taglineerror}</span>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="secondary" onClick={onsubmit}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Modal show={editshow} onHide={handleClose}>
                      <Modal.Header closeButton> <Modal.Title style={{ color: "black" }}>
                        Update Categories Details
                      </Modal.Title></Modal.Header>
                     
                      <Modal.Body>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label> Name </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={name}
                              placeholder="Enter Name"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{nameerror}</span>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Tagline</Form.Label>
                            <Form.Control
                              type="text"
                              name="tagline"
                              placeholder="Enter Tagline"
                              value={tagline}
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{taglineerror}</span>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="secondary" onClick={onupdatecategory}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contestcategories;
