import Header from "../Widgets/Header";
import React, { useState, useEffect } from "react";
import Navbar from "../Widgets/Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
// import { Link } from "react-router-dom";
import configs from "../Constant/Config";
import { allFixtureContest
  //  conteststatus 
  } from "../services/contest";
import { useParams } from "react-router-dom";
export const Contests = () => {
  const {name}=useParams()
  const [record, setrecord] = useState([]);
  console.log("record", record)
  const [FilterRecord, setFilterRecord] = useState(record);
  const [filter, setfilter] = useState("");
  const [refresh] = useState(false)
  const columns = [
    {
      key: "srno.",
      text: "S.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Contest ID",
      className: "sr_no.",
      align: "left",
      sortable: true,
    
    },

    {
      key: "category",
      text: "Category",
      className: "Category",
      align: "left",
      sortable: true,
    },
    {
      key: "match_name",
      text: "Fixture",
      className: "Fixture",
      align: "left",
      sortable: true,
    },
    // {
    //   key: "contest_total_team",
    //   text: "Total Team",
    //   className: "totalteam",
    //   align: "left",
    //   sortable: true,
    // },

    {
      key: "contesttype",
      text: "Type",
      className: "type",
      align: "left",
      sortable: true,
    },

    {
      key: "entryfees",
      text: "Entry Fee",
      className: "EntryFee",
      align: "left",
      sortable: true,
    },
    {
      key: "totalprize",
      text: "Total Prize",
      className: "Prize",
      align: "left",
      sortable: true,
      cell:(res)=>{
        return (
          <span>{parseFloat(res.totalprize).toFixed(2)}</span>
        )
      }
    },
    {
      key: "createdAt",
      text: "Date",
      className: "Prize",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },

    // {
    //   key: "view",
    //   text: "Action",
    //   className: "view",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return record.status == 1 ? (
    //       <button
    //         className="btn btn-primary btn-sm"
    //         onClick={() => {
    //           changestatus(record.id, 0);
    //         }}
    //       >
    //         Enabled
    //       </button>
    //     ) : (
    //       <button
    //         className="btn btn-danger btn-sm"
    //         onClick={() => {
    //           changestatus(record.id, 1);
    //         }}
    //       >
    //         Disabled
    //       </button>
    //     );
    //   },
    // },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Filter in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {};

  const getallcontestdata = async () => {
    let result = await allFixtureContest(configs);
    if (result.status === true) {
      setrecord(result.data);
      setFilterRecord(result.data);
      if(name){
        setFilterRecord([]);
        const currentDate = new Date();
        const utcDate = moment(currentDate).format("YYYY-MM-DD");
    
        const array = [];
    
        result.data.filter((filterContest) =>
            moment(filterContest.createdAt).format("YYYY-MM-DD").match(utcDate)
          )
          .map((item) => {
            array.push(item);
            return setFilterRecord(array);
          });
      }
    }
  };
  useEffect(() => {
    getallcontestdata();
    // setFilterRecord(record)
  }, [refresh]);

  useEffect(() => {
     if(name){
      setfilter(name)
     }
  }, [name])
  

  // ===========status=============
  // const changestatus = async (id, value) => {
  //   let data = {
  //     id,
  //     status: value,
  //   };
  //   let result = await conteststatus(data, configs);
  //   if (result.status === true) {
  //     getallcontestdata();
  //   }
  // };

  // ============= active contest ==============//
  const filterContest = (type) => {
    setFilterRecord([]);
    const array = [];
    return record
      .filter((filterItem) => filterItem.status.match(type))
      .map((item) => {
        array.push(item);
        return setFilterRecord(array);
      });
  };
  const todayFilterContest = () => {
    setFilterRecord([]);
    const currentDate = new Date();
    const utcDate = moment(currentDate).format("YYYY-MM-DD");

    const array = [];

    record
      .filter((filterContest) =>
        moment(filterContest.createdAt).format("YYYY-MM-DD").match(utcDate)
      )
      .map((item) => {
        array.push(item);

        return setFilterRecord(array);
      });
  };
  const handleFilter = (e) => {
    setfilter(e.target.value);

    if (e.target.value === "today") {
      todayFilterContest();
    } else {
      filterContest(e.target.value);
    }
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="page-heading d-flex flex-wrap justify-content-between align-items-center">
                <div className="section-heading">
                  <h2>
                    <b>Contest</b>
                  </h2>
                </div>
                <div class="form-group verify-select">
                <select value={filter} onChange={handleFilter} className="form-select">
                  <option value="">All Contest</option>
                  <option value="today">Today Joined Contest</option>
                  {/* <option value="1">Active Contest</option>
                  <option value="0">Inactive Contest</option> */}
                </select>
                </div>
                {/* <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Filter contest
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link
                        to=""
                        onClick={() => filterContest()}
                        class="dropdown-item"
                        value={record}
                      >
                        All Contest
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        onClick={() => todayFilterContest()}
                        class="dropdown-item"
                        value={record}
                      >
                        Today Joined Contest
                      </Link>
                    </li>

                    <li>
                      <Link
                        to=""
                        onClick={() => filterContest(1)}
                        class="dropdown-item"
                      >
                        Active Contest
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        onClick={() => filterContest(0)}
                        class="dropdown-item"
                      >
                        Inactive Contest
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>

              <div className="row">
                <div className="col-xxl-5 text-center">
                  <div className="product-list-outer">
                    <ReactDatatable
                      config={config}
                      records={FilterRecord}
                      columns={columns}
                      onPageChange={pageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
