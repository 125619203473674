import * as opsService from "./Ops";
// import { baseUrl } from "../Pages/BaseUrl";
import { addBanner,getBanner,deleteBanner,updateBanner,getBannerBYId,addPromotionBanner,deletePromotionBanner,updatePromotionBanner,getPromotionBanner } from "../Constant/Api";

// ================= banner======================//
const addbanner = async (data, token) => {
  let result = await opsService.postdata(
    addBanner,
    data,
    token
  );
  return result;
};
const getbanner = async (data, token) => {
  let result = await opsService.getData(getBanner, data, token);
  return result;
};
const bannerDelete = async(data, token)=>{
  let result = await opsService.postdata(deleteBanner, data, token);
  return result;
}
const bannerUpdate = async(data, token)=>{
  let result = await opsService.postdata(updateBanner, data, token);
  return result;
}
const bannerById = async(data, token)=>{
  let result = await opsService.postdata(getBannerBYId, data, token);
  return result;
}


const addpromotionbanner = async (data, token) => {
  let result = await opsService.postdata(
    addPromotionBanner,
    data,
    token
  );
  return result;
};
const getpromotionbanner = async ( token) => {
  let result = await opsService.getData(getPromotionBanner, token);
  return result;
};
const promotionBannerDelete = async(data, token)=>{
  let result = await opsService.postdata(deletePromotionBanner, data, token);
  return result;
}
const promotionBannerupdate = async(data, token)=>{
  let result = await opsService.postdata(updatePromotionBanner, data, token);
  return result;
}
  export {
    addbanner,
    getbanner,
    bannerDelete, 
    bannerById,
    bannerUpdate,
    addpromotionbanner,
    getpromotionbanner,
    promotionBannerDelete,
    promotionBannerupdate
  };