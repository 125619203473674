
import * as opsService from "./Ops";
// import { baseUrl } from "../Pages/BaseUrl";
import { addHelpSupport,getHelpSupport,deleteHelpSupport,updateHelpSupport,getHelpSupportById,getContactUs,updateContactUS,updateContactUsById,getLegality,updateLegalities,getSetting,updateSetting } from "../Constant/Api";


const getsupportdata = async(data,token) => {
  let result = await opsService.getData(getHelpSupport, data,token);
  return result;
};
const addsupportdata = async(data,token) => {
  let result = await opsService.postdata(
   addHelpSupport,
    data,token
  );
  return result;
};
const getsupportdatabyid = async(data,token) => {
  let result = await opsService.postdata(getHelpSupportById, data,token);
  return result;
};
const supportdatadelete = async(data,token) => {
  let result = await opsService.postdata(
    deleteHelpSupport,
    data,token
  );
  return result;
};
const supportdataupdates = async(data,token) => {
  let result = await opsService.postdata(
    updateHelpSupport,
    data,token
  );
  return result;
};
// ============ CONTACT US========================//
const getContactUsdata = async(data,token) => {
  let result = await opsService.getData(getContactUs, data,token);
  return result;
};
const updateContactUs = async(data,token) => {
  let result = await opsService.postdata(updateContactUS, data,token);
  return result;
};
const getContactUsbyid = async(data,token) => {
  let result = await opsService.postdata(updateContactUsById, data,token);
  return result;
};


// ===========================legality ==============================//
const getLegalitydata = async(data,token) => {
  let result = await opsService.getData(getLegality, data,token);
  return result;
};
const updateLegality = async(data,token) => {
  let result = await opsService.postdata(updateLegalities, data,token);
  return result;
};
// ==================== setting ==============//
const getSettingdata = async(data,token) => {
  let result = await opsService.getData(getSetting, data,token);
  return result;
};
const updateSettingdata  = async(data,token) => {
  let result = await opsService.postdata(updateSetting, data,token);
  return result;
};

export {
  getsupportdata,
  addsupportdata,
  getsupportdatabyid,
  supportdatadelete,
  supportdataupdates,
  getContactUsdata,
  updateContactUs,
  getContactUsbyid,
  
  getLegalitydata,
  updateLegality,
  getSettingdata,
  updateSettingdata

};
