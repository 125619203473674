import React, { useState, useEffect, Fragment } from "react";
import ReactDatatable from "@mkikets/react-datatable";
// import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
// import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import configs from "../Constant/Config";
// import { inputFieldValidation } from "../Validations/validation";
import {
  getteam,
  updateTeamLogo
  // teamdelete,
  // teambyid,
  // teamupdates,
} from "../services/team";
import "react-confirm-alert/src/react-confirm-alert.css";
// import Modal from "react-bootstrap/Modal";
// import { baseUrls } from "./BaseUrl";
import { baseUrls } from "../Constant/BaseUrl";
// import { Link } from "react-router-dom";
export const Team = () => {
  const [record, setRecord] = useState([]);
  // const [userid, setUserid] = useState([])
  const [teamId, setTeamId] = useState("")


  // const [logo, setLogo] = useState("");
  // const [logos, setLogos] = useState("");
  // const [logoadd, setLogoadd] = useState("");

  
  
 

  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: " Team Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "team_key",
      text: " Team key",
      className: "Name",
      align: "left",
      sortable: true,
    },
 

    {
      key: "logo",
      text: "Team Logo",
      className: "Tagline",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <img
              style={{ height: "50px" }}
              src={record.logo==null?"/assets/images/person-icon.png":
                baseUrls + "/static/teamLogo/" + record.logo}
              alt=""
            />
          </Fragment>
        );
      },
    },
    {
      key: "date",
      text: "Date & Time",
      className: "Tagline",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("lll")}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "type view",
      align: "left",
      sortable: true,
      cell:((response,index)=>{
        return <div className="edit-profile ">
        <input
          type="file"
          // ref={myref}
          id="img1"
          style={{ display: "none" }}
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e)=>{
            saveFile(e)     }}
            name="teamlogo"
        />
        <label htmlFor="img1" className="editlabel" id={response.id} onClick={()=>setTeamId(response.id)}>
          <i className="fa fa-edit" ></i>
        </label>
      
      </div>
      })
    },

    // {
    //   key: "view",
    //   text: "Action",
    //   className: "view",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return (
    //       <Fragment>
    //         <Link
    //           to=""
    //           onClick={() => {
    //             handleeditShow(record.id);
    //           }}
    //           style={{ float: "left", cursor: "pointer" }}
    //         >
    //           <img
    //             src="assets/images/edit-icon.png"
    //             alt=""
    //             title="Edit"
    //             className="img-fluid"
    //           />
    //         </Link>
    //         <Link
    //           to=""
    //           onClick={() => Conn(record.id)}
    //           style={{ marginLeft: "5px" }}
    //         >
    //           <img
    //             src="assets/images/delete-icon.png"
    //             alt=""
    //             title="Delete"
    //             className="img-fluid"
    //           />
    //         </Link>
    //       </Fragment>
    //     );
    //   },
    // },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  // ========== api integration==============//

  const getteamdata = async () => {
    let result = await getteam(configs);
    console.log(result, "result");
    if (result.status === true) {
      setRecord(result.data);
    }
  };
  const saveFile = async (e) => {
    e.preventDefault();
    if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
      toast.error("Select valid image format");
      return false;
    }
    const formData = new FormData();
    formData.append("teamlogo", e.target.files[0]);
    formData.append("teamid", teamId);
    try {
      let result = await updateTeamLogo(formData, configs);
      console.log("res=====",result)
      if(result.status){
        toast.success(result.message);
        getteamdata()
      }
     
    } catch (ex) {
      console.log(ex);
    }
  };

  // const onsubmit = async (e) => {
   
   
  //   const formdata = new FormData();
 
  //   formdata.append("teamlogo", logo);

  //   let result = await addteam(formdata, configs);
  //   if (result.status === true) {
  //     toast.dismiss();
  //     toast.success(result.message);
  //   }
  //   getteamdata();
  
  // };

  // =============get team id ===============//
  // const iddata = async (id) => {
  //   let datas = {
  //     id,
  //   };
  //   let results = await teambyid(datas, configs);
  //   const { name, logo } = results.data[0];
  //   setName(name);
  //   setLogos(baseUrls + "/static/teamLogo/" + logo);
  // };

  // ========= delete team =============//

  // const deletebyid = async (id) => {
  //   let deleteid = {
  //     id,
  //   };
  //   let result = await teamdelete(deleteid, configs);
  //   if (result.status === false) {
  //     toast.dismiss();
  //     toast.error(result.message);
  //     return false;
  //   }

  //   if (result.status === true) {
  //     toast.dismiss();
  //     toast.success(result.message);
  //     getteamdata();
  //   }
  // };

  // const Conn = (getMethodDeleteId) => {
  //   confirmAlert({
  //     title: "Confirm to submit",
  //     message: "Are you sure to do this.",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => deletebyid(getMethodDeleteId),
  //       },
  //       {
  //         label: "No",
  //       },
  //     ],
  //   });
  // };
  // ============update team api ===========/
  // const onupdateteam = async () => {
  //   if (!name) {
  //     setnameerror("This field is required");

  //     return false;
  //   }
  //   if (!logo) {
  //     if (!logos) {
  //       setLogoerror("This field is required");

  //       return false;
  //     }
  //   }
  //   const formdata = new FormData();
  //   formdata.append("id", userid);
  //   formdata.append("name", name);
  //   formdata.append("teamlogo", logo);

  //   let result = await teamupdates(formdata,configs);
  //   if (result.status === true) {
  //     toast.dismiss();
  //     toast.success(result.meassge);
  //   }
  //   getteamdata();
  //   handleClose();
  // };

  // ============update team api===========/
  useEffect(() => {
    getteamdata();
  }, []);

  // ========handlechange for add model==========

  // const handlechange = (e) => {
  //   let { name, value } = e.target;

  //   if (name === "name") {
  //     setName(value);
  //     const err = inputFieldValidation(name, value);
  //     setnameerror(err);
  //   }
  //   if (name === "teamlogo") {
  //     if (value === "" || value === undefined) {
  //       setLogoadd("");
  //       setLogos("");
  //       setLogoerror("This field is required");
  //       setLogo(e.target.files[0]);
  //       return false;
  //     } else {
  //       setLogo(e.target.files[0]);
  //       setLogos(URL.createObjectURL(e.target.files[0]));
  //       setLogoadd(URL.createObjectURL(e.target.files[0]));
  //       setLogoerror("");
  //     }
  //   }
  // };
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Team</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer">
                  <div className="add-product-btn text-center">
                    {/* <button
                      onClick={() => handleShow()}
                      className="btn btn-primary"
                    >
                      Add Team
                    </button> */}

                    {/* <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Title style={{ color: "black" }}>
                        Add Team Details
                      </Modal.Title>
                      <Modal.Body>
                        <Form>
                          <Form.Group className="mb-3">
                            <Form.Label>Team Name </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={name}
                              placeholder="Enter Name"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{nameerror}</span>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <div className="mylabels">
                              <Form.Label>Team Logo</Form.Label>
                            </div>
                            <img
                              style={{ width: "100px", marginBottom: "15px" }}
                              src={logoadd}
                              className="img-fluid"
                              alt=""
                            />
                            <Form.Control
                              type="file"
                              name="teamlogo"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{logoerror}</span>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="secondary" onClick={onsubmit}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal> */}

                    {/* <Modal show={editshow} onHide={handleClose}>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Title
                        style={{ color: "black", paddingLeft: "16px" }}
                      >
                        Update Team Details
                      </Modal.Title>
                      <Modal.Body>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label> Name </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={name}
                              placeholder="Enter Name"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{nameerror}</span>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <div className="mylabels">
                              <Form.Label>Team Logo</Form.Label>
                            </div>
                            <img
                              style={{ width: "100px", marginBottom: "15px" }}
                              src={logos}
                              className="img-fluid"
                              alt=""
                            />
                            <Form.Control
                              type="file"
                              name="teamlogo"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{logoerror}</span>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="secondary" onClick={onupdateteam}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal> */}
                  </div>
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
