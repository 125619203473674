import React,{useState,useEffect} from 'react'
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { MyContestByUserId } from "../services/user";
import configs from "../Constant/Config";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
export const UserContest = (props) => {
  const [userContest, setUserContest] = useState([]);
  useEffect(() => {
    // setWalletBal(mainWalletBal);
    if (props.id) {
      getUserContest();
    }
  }, [props.id]);
  const getUserContest = async () => {
    let data = {
      id: props.id,
    };
    const result = await MyContestByUserId(data, configs);

    if (result.status) {
      setUserContest(result.data);
    }
    else{
      setUserContest([]);
    }
  };
  const columns = [
    {
      key: "S.No.",
      text: "S.No.",
      className: "S.No.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "contestId",
      text: " Contest Id",
      className: "contest-id",
      align: "left",
      sortable: true,
    },
    {
      key: "match_short_name",
      text: "VS",
      className: "Vs",
      align: "left",
      sortable: true,
    },
    {
      key: "created_at",
      text: "Create At",
      className: "Registration Date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return moment(record.created_at).format("DD/MM/YYYY");
      },
    },
  
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };
  return (
    <><Modal
    {...props}
    dialogClassName="modal-500w" size="lg"
    aria-labelledby="example-custom-modal-styling-title"
  >
    <Modal.Header closeButton>
      <Modal.Title id="example-custom-modal-styling-title">
      Contest Details
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Container>
        <hr />
        {/* <Row>
          <Col xs={6} md={2}>
            <h6>Contest Id</h6>55541001
          </Col>
          <Col xs={6} md={3}>
            <h6>Contest Name</h6>H2 to H2
          </Col>
          <Col xs={6} md={3}>
            <h6>No. Of Teams</h6>10
          </Col>
          <Col xs={6} md={3}>
            <h6> Winning Amount</h6>1200(INR)
          </Col>
          <Col xs={6} md={1}>
            <h6>Status</h6>
          </Col>
        </Row> */}
        <div>
        <ReactDatatable
                  config={config}
                  records={userContest}
                  columns={columns}
            
                />
        </div>
      </Container>
    </Modal.Body>
    
  </Modal></>
  )
}
