import * as opsService from "./Ops";
// import { baseUrl } from "../Pages/BaseUrl";
import { getAbout,updateAbout } from "../Constant/Api";
// ===========================ABOUT US ==============================//
// const getAboutUsdata = async(data,token) => {
//     let result = await opsService.getData(baseUrl + "/about-us-get", data,token);
//     return result;
//   };
const getAboutUsdata = async(data,token) => {
  let result = await opsService.getData(getAbout, data,token);
  return result;
};
  const updateAboutUs = async(data,token) => {
    let result = await opsService.postdata(updateAbout, data,token);
    return result;
  };
  // const getAboutUsbyid = async(data,token) => {
  //   let result = await opsService.postdata(baseUrl + "/about-us-byid", data,token);
  //   return result;
  // };

export {
  
    getAboutUsdata,
    updateAboutUs,
    // getAboutUsbyid,
   
  
  };