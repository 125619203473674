import React, { useState, useEffect } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getUser } from "../services/user";
import configs from "../Constant/Config";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
// import { baseUrls } from "./BaseUrl";
import { baseUrls } from "../Constant/BaseUrl";
import io from "socket.io-client";
import $ from "jquery";
import moment from "moment";
const socket = io.connect("https://fantasyapi.technoloaderitservices.com", {
  query: "JWTtoken=" + localStorage.getItem("jwtToken"),
});
export const Support = () => {
  $("#conversation").animate({ scrollTop: 1000000 }, 200);
  const [record, setRecord] = useState([]);
  const [show, setShow] = useState(false);
  const [messageReceived, setMessageReceived] = useState([]);
  const [id, setId] = useState("");
  const [message, setMessage] = useState("");
  const [chatImage, setChatImage] = useState("");
  // const [chatImageName, setChatImageName] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  // var messageReceived=[]
  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    socket.on("receive_message", (data) => {
      let recievedata = {
        query: data.query,
        userType: data.userType,
        chatImage: data.chatImage,
        replyBy: data.replyBy,
        createdAt: data.createdAt,
      };
      setMessageReceived((prevState) => [...prevState, recievedata]);
      showTableHtml();
    });
  }, [socket]);

  const handleShow = (id) => {
    setShow(true);
    console.log("id==", id);
    socket.emit("join_room", id);
    socket.on("recieve-all-message", (data) => {
      setMessageReceived(data.reverse());
      // messageReceived=data
    });
  };
  const handleClose = () => {
    setShow(false);
    setId("");
    setMessageReceived([]);
    // setChatImageName("");
  };

  const columns = [
    {
      key: "SR No.",
      text: "Sr No. ",
      className: "sr-no",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "userName",
      text: "User Name ",
      className: "User Name ",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email Id",
      className: "Email Id",
      align: "left",
      sortable: true,
    },
    {
      key: "mobileNumber",
      text: "Phone No ",
      className: "Phone No ",
      align: "left",
      sortable: true,
    },
    {
      key: "id",
      text: "Room Code",
      className: "room-code",
      align: "left",
      sortable: true,
    },

    {
      key: "Reply ",
      text: "Reply",
      className: "Reply",
      align: "left",
      sortable: true,
      cell: (res) => {
        return (
          <button
            className="btn btn-primary"
            onClick={() => {
              handleShow(res.id);
              setId(res.id);
            }}
          >
            Reply
          </button>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  const getUserDetails = async () => {
    let result = await getUser(configs);
    if (result.status) {
      setRecord(result.data);
    }
  };

  const sendMessage = () => {
    socket.emit("send_message", {
      query: message,
      id,
      userType: "admin",
      chatImage: chatImage,
    });

    setMessage("");
    setChatImage("");
    // setChatImageName("");
    setImagePreview("");
  };

  console.log("daaa", messageReceived);

  const handleImage = (e) => {
    let files = e.target.files;
    // setChatImageName(files[0].name);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    fileReader.onload = (event) => {
      setChatImage(event.target.result);
    };
    fileReader.readAsDataURL(files[0]);
  };

  const showTableHtml = () => {
    const html = [];
    var stylediv = "";
    if(messageReceived){
    messageReceived?.map((value) => {
      // var U_ID = value.id;

      if (value.image) {
        var profilePic = baseUrls + "/static/chatImage/" + value.image;
      }

      if (value.replyBy === "2") {
        stylediv = "charting-part left_side_div";
      } else {
        stylediv = "charting-part right_side_div";
      }

      var utcTime = new Date(value?.createdAt);

      // Convert to IST
      var istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds (5 hours 30 minutes)
      var istTime = new Date(utcTime.getTime() + istOffset);

      // Format the IST time as a string
      var istTimeString = istTime.toISOString().replace("T", " ").slice(0, -5);
      // console.log("time", istTimeString);
      html.push(
        <div className={stylediv}>
          {/* <div dangerouslySetInnerHTML={{ __html: value.chat }} />
            </div> */}
          {value.replyBy === "1" ? (
            <div className="chat-cards">
              {value.image != "" &&
                value.image != undefined &&
                value.image != "NULL" && (
                  <div>
                    {/* <a
                href=""
                data-toggle="modal"
                data-target="#supportImageModel"
                onClick={() => {
                  classThis.setState({ Image: profilePic });
                }}
              > */}
                    <img src={profilePic} alt="" className="chat-img" />
                    {/* </a> */}
                  </div>
                )}

              <div>{value?.query && JSON.parse(value?.query)}</div>
              <p className="time">
                {/* {moment(value?.createdAt).utc().format()} */}
                {moment(istTimeString).format("HH:mm")}
              </p>
            </div>
          ) : (
            <>
              <div className="chat-cards">
                {value.image != "" &&
                  value.image != undefined &&
                  value.image != "NULL" && (
                    <div>
                      {/* <a
                href=""
                data-toggle="modal"
                data-target="#supportImageModel"
                onClick={() => {
                  classThis.setState({ Image: profilePic });
                }}
              > */}
                      <img src={profilePic} alt="" className="chat-img" />
                      {/* </a> */}
                    </div>
                  )}
                  {console.log("value",value?.query)}
                {/* <div dangerouslySetInnerHTML={{ __html: value.chat }} /> */}
                <div>{value?.query && JSON.parse(value?.query)}</div>
                <p className="time">{moment(istTimeString).format("HH:mm")}</p>
              </div>
            </>
          )}
        </div>
      );
      return 1;
    });
    return html;
  }
  };
console.log("messagweee",messageReceived)
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Support</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer">
                  <div className="add-product-btn text-center"></div>
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title style={{ color: "black" }}>
                    Live Chat
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="message-box chatbox" id="conversation">
                    {/* {messageReceived?.map((list)=>{
                  return (
                    <p>{list.query}</p>
                  )
                })} */}
                    {showTableHtml()}
                  </div>
                  <Form className="chatform">
                    <Form.Control
                      type="text"
                      placeholder="Enter Massage"
                      name="name"
                      value={message}
                      onChange={(event) => {
                        setMessage(event.target.value);
                      }}
                    />
                    <img
                      src={imagePreview}
                      alt=""
                      className="img-preview"
                    ></img>
                    {imagePreview ? (
                      ""
                    ) : (
                      <>
                        {/* <Form.Control
                          type="file"
                          name="chatImage"
                          defaultValue={""}
                          value={""}
                          onChange={handleImage}
                          className="chatfile"
                          id="chatfile"
                        />
                        <Form.Label
                          htmlFor="chatfile"
                          className="chatfile-label"
                        >
                          {" "}
                          <span class="mdi mdi-attachment"></span>
                        </Form.Label> */}
                      </>
                    )}

                    <Button
                      variant="secondary"
                      onClick={sendMessage}
                      className="chatsend-btn"
                    >
                      {/* Send */}
                      <span class="mdi mdi-send-outline"></span>
                    </Button>
                  </Form>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
