import * as opsService from "./Ops";
// import { baseUrl } from "../Pages/BaseUrl";
import { rejectKyc,verifiedKyc } from "../Constant/Api";

const rejectedReason = async (data, token) => {
    let result = await opsService.postdata(
      rejectKyc,
      data,
      token
    );
    return result;
  };
  const verifyDoc = async (data, token) => {
    let result = await opsService.postdata(
      verifiedKyc,
      data,
      token
    );
    return result;
  };

 
  export {
    rejectedReason,verifyDoc
  };
