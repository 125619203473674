import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Footer from "../Widgets/Footer";
import "react-confirm-alert/src/react-confirm-alert.css";
import { UserTransactionDetails } from "./UserTransactionDetails";
import { UserUpdateModal } from "./UserUpdateModal";
import ReactDatatable from "@mkikets/react-datatable";
import { UserContest } from "./UserContest";
import { UserBankDetail } from "./UserBankDetail";
import { getUser } from "../services/user";
import configs from "../Constant/Config";
import moment from "moment";
import { useParams } from "react-router-dom";

function Totaluser(props) {
  const { name } = useParams();

  const [mainData, setMainData] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setfilter] = useState("all");
  const [modalTransactionShow, setModalTransactionShow] = useState(false);
  const [modalBankShow, setModalBankShow] = useState(false);
  const [id, setId] = useState("");
  const [walletAmount, setWalletAmount] = useState("");
  const columns = [
    {
      key: "S.No.",
      text: "S.No.",
      className: "S.No.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: " User ID",
      className: " User Id",
      align: "left",
      sortable: true,
    },
    {
      key: "userName",
      text: "User Name",
      className: "User Name",
      align: "left",
      sortable: true,
    },
  
    {
      key: "mobileNumber",
      text: "Phone",
      className: "Phone",
      align: "left",
      sortable: true,
    },
   
    // {
    //   key: "email",
    //   text: "User Email",
    //   className: "User Name",
    //   align: "left",
    //   sortable: true,
    // },
    {
      key: "wallet",
      text: "Wallet Balance",
      className: "Wallet Balance",
      align: "left",
      sortable: true,
      cell: (record) => {
        if (record.wallet) {
          let walletStr = JSON.parse(record.wallet);

          return <p>{parseFloat(walletStr.totalBalance).toFixed(2)}</p>;
        }
      },
    },
    {
      key: "kycStatus",
      text: "KYC Status",
      className: "Document Verified?",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record.kycStatus ? <span>{record.kycStatus}</span> : "";
      },
    },
    {
      key: "createdAt",
      text: "Registration Date",
      className: "Registration Date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return moment(record.createdAt).format("DD/MM/YYYY");
      },
    },
    // {
    //   key: "bankDetail",
    //   text: "Bank Detail",
    //   className: "Action",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return (
    //       <button
    //         className="btn btn-primary"
    //         onClick={() => {
    //           handleBankShow();
    //           setId(record.id);
    //         }}
    //       >
    //         View
    //       </button>
    //     );
    //   },
    // },
    
    {
      key: "Action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        var walletAmount;
        if (record.wallet) {
          let walletStr = JSON.parse(record.wallet);
          walletAmount = walletStr.totalBalance;
        }
        return (
          <div className="d-flex justify-content-between total-user-action">
            {/* <Link to="" onClick={handleUpdateShow}>
              <img
                src="/assets/images/EDIT.png"
                title="Edit"
                alt=""
                class="img-fluid"
              />
            </Link> */}
            <Link
              to=""
              title="Contest Details"
              onClick={() => {
                handleContestShow();
                setId(record.id);
              }}
            
            >
              <span class="mdi mdi-trophy-variant-outline"></span>
            </Link>
            <Link title="User Details" to={`/user-details/${record.id}`}>
              <span className="mdi mdi-eye-outline"></span>
            </Link>
            <Link
              title="Transaction"
              to=""
              onClick={() => {
                handleTransactionShow();
                setId(record.id);
                setWalletAmount(walletAmount);
              }}
            >
              <span className="mdi mdi-cash-check"></span>
            </Link>
            <Link
              title="Bank Details"
              to=""
              onClick={() => {
                          handleBankShow();
                          setId(record.id);
                        }}
            >
              <span class="mdi mdi-bank"></span>
            </Link>
          </div>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    let result = await getUser(configs);
    if (result.status) {
      setData(result.data);
      setMainData(result.data);
      if(name){
        const filteredData = await result.data?.filter((item) => item.kycStatus === name);
         setData(filteredData)
        // userList(name)
      }
    
    }
  };

  const handleTransactionShow = () => {
    setModalTransactionShow(true);
  };

  useEffect(() => {
    setfilter(name);
    if (name) {
      // getUserDetails();
      // userList(name);
    }
  }, [name]);

  const handleButtonClose = () => {
    setModalTransactionShow(false);
    setModalTransactionShow((prev) => {
      return prev;
    });
  };
  const handleBankShow = () => {
    setModalBankShow(true);
  };
  const handleBankClose = () => {
    setModalBankShow(false);
    setModalBankShow((prev) => {
      return prev;
    });
  };
  const handleFilter = (e) => {
    setfilter(e.target.value);
    userList(e.target.value);
  };
  // ===========  Update User Details======================//
  const [userUpdateShow, setUserUpdateShow] = useState(false);
  const handleUpdateShow = () => {
    setUserUpdateShow(true);
  };
  const handleUpdateClose = () => {
    setUserUpdateShow(false);
  };

  // ============= Contest ===================//
  const [userContestShow, setUserContestShow] = useState(false);
  const handleContestShow = () => {
    setUserContestShow(true);
  };
  const handleContestClose = () => {
    setUserContestShow(false);
  };

  const userList =  (type) => {
    // e.preventDefault();
    setData([]);
      const filteredData =  mainData?.filter((item) => item.kycStatus === type);
      type === "all" ? setData(mainData) : setData(filteredData);
     
    
    // console.log("sndjsddf", filteredData);
  };

  const pageChange = (pageData) => {};
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <div className="page-heading">
              <div className="row align-items-center">
                <div className="col-md-10 col-sm-10 col-12">
                  <div class="section-heading">
                    <h2 class="text-black">
                      <b> Total Users</b>
                    </h2>
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 col-12">
                  <div className="form-group verify-select">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      value={filter}
                      onChange={handleFilter}
                    >
                      {/* <option selected>Open this select menu</option> */}
                      <option value="all">All User</option>
                      <option value="Verified">Verified User</option>
                      <option value="Pending"> Pending User</option>
                      <option value="Reject"> Rejected User</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <UserBankDetail
              id={id}
              show={modalBankShow}
              onHide={handleBankClose}
            />
            <UserTransactionDetails
              id={id}
              show={modalTransactionShow}
              onHide={handleButtonClose}
              walletAmount={walletAmount}
            />
            <UserUpdateModal show={userUpdateShow} onHide={handleUpdateClose} />
            <UserContest
              show={userContestShow}
              onHide={handleContestClose}
              id={id}
            />
          </div>
          <div className="row">
            <div className="col-xxl-5">
              <div className="product-list-outer">
                <div className="add-product-btn text-center"></div>
                <ReactDatatable
                  config={config}
                  records={data}
                  columns={columns}
                  onPageChange={pageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default Totaluser;
