import React, { useEffect, useState } from "react";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { transactionDetails } from "../services/user";
import configs from "../Constant/Config";
export const UserTransactionDetails = (props) => {
  console.log("walletAmount===", props.walletAmount);
  const [transactionDetail, setTransactionDetail] = useState([]);
  const [filterTrans, setFilterTrans] = useState([]);
  const [type, settype] = useState("all");
  useEffect(() => {
    // setWalletBal(mainWalletBal);
    if (props.id) {
      getTransaction();
    }
  }, [props.id]);
  const getTransaction = async () => {
    let data = {
      id: props.id,
    };
    const result = await transactionDetails(data, configs);
    console.log("results", result);
    if (result.status) {
      setTransactionDetail(result.data);
      setFilterTrans(result.data);
    }
    else{
      setTransactionDetail([]);
      setFilterTrans([]);
    }
  };

  const itemFilter = (type) => {
    if (type === "all") {
      setFilterTrans(transactionDetail);
    } else {
      let data = transactionDetail.filter((filtered) => filtered.type == type);

      setFilterTrans(data);
    }

    // .map((item) => {
    //   array.push(item);

    //   if (array) {
    //
    //   } else {
    //     <h2 class="text-black text-center">
    //       <b> Data not found</b>
    //     </h2>
    //   }
    // });
  };

  return (
    <>
      {" "}
      <Modal
        {...props}
        dialogClassName="modal-1000w"
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {/* closeButton */}
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Transaction Details
          </Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={6} md={3}>
                <h5>Wallet Balance :-</h5>
              </Col>
              <Col xs={6} md={9}>
                <h5>{parseFloat(props.walletAmount).toFixed(2)}</h5>
              </Col>
            </Row>

            <Row>
              <Col xs={3} md={2}>
                <button
                  type="button"
                  class={`btn ${type === "all" ? "btn-success" : "btn-danger"}`}
                  onClick={() => {
                    itemFilter("all");
                    settype("all");
                  }}
                >
                  All
                </button>
              </Col>
              <Col xs={3} md={2}>
                <button
                  type="button"
                  class={`btn ${
                    type === "deposit" ? "btn-success" : "btn-danger"
                  }`}
                  onClick={() => {
                    itemFilter("deposit");
                    settype("deposit");
                  }}
                >
                  Deposit
                </button>
              </Col>

              <Col xs={3} md={2}>
                <button
                  type="button"
                  class={`btn ${
                    type === "withdrawal" ? "btn-success" : "btn-danger"
                  }`}
                  onClick={() => {
                    itemFilter("withdrawal");
                    settype("withdrawal");
                  }}
                >
                  {" "}
                  Withdraw
                </button>
              </Col>
              <Col xs={3} md={2}>
                <button
                  type="button"
                  class={`btn ${
                    type === "contest" ? "btn-success" : "btn-danger"
                  }`}
                  onClick={() => {
                    itemFilter("contest");
                    settype("contest");
                  }}
                >
                  {" "}
                  Contest
                </button>
              </Col>
              <Col xs={3} md={2}>
                <button
                  type="button"
                  class={`btn ${
                    type === "other" ? "btn-success" : "btn-danger"
                  }`}
                  onClick={() => {
                    itemFilter("other");
                    settype("other");
                  }}
                >
                  {" "}
                  Other
                </button>
              </Col>
            </Row>
            <hr />
            <div className="transaction-table scrool_table">
              <table className="table">
                <tbody>
                  <tr>
                    <th>Sr.No</th>
                    <th>Transaction ID</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Amount</th>

                    <th>Date & Time</th>
                    <th>Status</th>
                  </tr>
                  {filterTrans.length > 0 ? (
                    filterTrans.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{item.transaction_id}</td>
                          <td className="trans-status">{item.description}</td>
                          <td className="trans-status">{item.type}</td>
                          <td>
                            {item.amount}
                            {/* <img
                          style={{ width: "8px" }}
                          src={item.src}
                          alt=""
                          class="img-fluid"
                        /> */}
                          </td>
                         
                         
                          <td> {moment(item.createdAt).format("DD-MM-YYYY , hh:mm:ss A")}</td>
                          <td className="trans-status">{item.status}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="7">No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* <Row>
              <Col xs={6} md={2}>
                <h6>Sr.No</h6>
              </Col>
              <Col xs={6} md={2}>
                <h6>Transaction id</h6>
              </Col>
              <Col xs={6} md={2}>
                <h6>Description</h6>
              </Col>
              <Col xs={6} md={2}>
                <h6>Type</h6>
              </Col>
              <Col xs={6} md={2}>
                <h6>Amount</h6>
                <img
                  style={{ width: "8px" }}
      
                  alt=""
                  class="img-fluid"
                />
              </Col>
              <Col xs={6} md={2}>
                <h6>Date Of Transaction</h6>
              </Col>
              <Col xs={6} md={2}>
                <h6>Status</h6>
              </Col>
            </Row> */}
            {/* {transactionDetail.map((item, i) => {
              return (
                <Row>
                  <Col>{i + 1}</Col>
                  <Col>{item.transaction_id}</Col>
                  <Col>{item.description}</Col>
                  <Col>{item.type}</Col>
                  <Col>
                    {item.amount}
                    {/* <img
                      style={{ width: "8px" }}
                      src={item.src}
                      alt=""
                      class="img-fluid"
                    /> *
                  </Col>
                  <Col>{item.createdAt}</Col>
                  <Col>{item.status}</Col>
                </Row>
              );
            })} */}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
