import * as opsService from "./Ops";
// import { baseUrl } from "../Pages/BaseUrl";
import { getAllUser,getUserDetailBYId,getTransactionById,getBankDetailBYUserId,getContestBYUserId,changePassword,totalUser,totalVerifiedUser,totalPendingUser, userByReferred, referralUser, dateCountUser, kycUserApi, updateNameApi, updateAadharApi, updatePanApi } from "../Constant/Api";

const getUser = async (data, token) => {
  let result = await opsService.getData(
    getAllUser,
    data,
    token
  );
  return result;
};

const userDetailsById = async (data, token) => {
  let result = await opsService.postdata(
    getUserDetailBYId,
    data,
    token
  );
  return result;
};

const userLength = async (data, token) => {
  let result = await opsService.getData(totalUser, data, token);
  return result;
};
const transactionDetails = async (data, token) => {
  let result = await opsService.postdata(getTransactionById, data, token);
  return result;
};
const bankDetail = async (data, token) => {
  let result = await opsService.postdata(getBankDetailBYUserId, data, token);
  return result;
};
const MyContestByUserId = async (data, token) => {
  let result = await opsService.postdata(getContestBYUserId, data, token);
  return result;
};
const adminChangePassword=async(data,token)=>{
  let result = await opsService.postdata(changePassword, data, token);
  return result;
}
const totalverifieduser = async (data, token) => {
  let result = await opsService.getData(totalVerifiedUser, data, token);
  return result;
};
const totalpendinguser = async (data, token) => {
  let result = await opsService.getData(totalPendingUser, data, token);
  return result;
};
const userbyreferred=async(data,token)=>{
  let result = await opsService.getData(userByReferred, data, token);
  return result;
}
const referraluser=async(data,token)=>{
  let result = await opsService.postdata(referralUser, data, token);
  return result;
}
const datecountuser=async(token)=>{
  let result = await opsService.getData(dateCountUser, token);
  return result;
}
const kycUser=async(token)=>{
  let result = await opsService.getData(kycUserApi, token);
  return result;
}
const updateName=async(data,token)=>{
  let result = await opsService.postdata(updateNameApi, data,token);
  return result;
}
const updateAadhar=async(data,token)=>{
  let result = await opsService.postdata(updateAadharApi, data,token);
  return result;
}
const updatePan=async(data,token)=>{
  let result = await opsService.postdata(updatePanApi, data,token);
  return result;
}

export { getUser, userDetailsById,kycUser,updateName,updateAadhar,updatePan, userLength,adminChangePassword,transactionDetails,bankDetail,MyContestByUserId,totalverifieduser,totalpendinguser,userbyreferred,referraluser,datecountuser };
