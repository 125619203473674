import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import config from '../Constant/Config'
import { getFixtureContestById } from '../services/contest'
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";

function FixtureContestView() {
    const {id}=useParams()
    const [contestData, setContestData] = useState([])
    const [rankData, setRankData] = useState([])

    useEffect(() => {
     getData()
    }, [id])
    
    const getData=async()=>{
        const data={
            id:id
        }
        let result=await getFixtureContestById(data,config)
        if(result.status){
            setContestData(result.contest[0])
            setRankData(result.data)
        }
    }

    console.log("contest",contestData)
    console.log("rank",rankData)


  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Contest View</b>
              </h2>
            </div>
            <div className="row mt-4 contest-view">
              <div className='col-md-3'>
                <p><strong>Contest Name : </strong>{contestData.contestName}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Contest : </strong>{contestData.dynamic}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Admin Percentage : </strong>{contestData.admin_percentage}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Entry Fees : </strong>{contestData.entryfees}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Contest Type : </strong>{contestData.contesttype}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Max Team Per User : </strong>{contestData.maxteamperuser}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>First Prize : </strong>{contestData.first_prize}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Total Spots : </strong>{contestData.total_spots}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Left Spots : </strong>{contestData.left_spots}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Total Prize : </strong>{contestData.totalprize}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Fixture Name : </strong>{contestData.fixtureName}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Category Name : </strong>{contestData.categoryName}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Auto Create : </strong>{contestData.autocreate}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Add Spots : </strong>{contestData.add_spots}</p>
              </div>
              <div className='col-md-3'>
              <p><strong>Description : </strong>{contestData.description}</p>
              </div>
              
              

            </div>
            <div className='row'>
              <h3>Rank</h3>
                <div className='col-md-6 rank-table' >
                    <table>
                      <thead>
                        <tr>
                          <th>Rank</th>
                          <th>Percentage</th>
                          <th>Price</th>
                         
                          <th>Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rankData.map((list)=>{
                          return (
                            <tr>
                              <td>{list.rankfrom}-{list.torank}</td>
                              <td>{list.totalpercentage}</td>
                              <td>{list.prize}</td>
                              <td>{list.totalprizes}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FixtureContestView
