import * as opsService from "./Ops";
import { baseUrl } from "../Pages/BaseUrl";


// =================manage reward point===================//
const addmanagereward = async (data, token) => {
    console.log(token, "token");
    let result = await opsService.postdata(
      baseUrl +"/managereward-insert",
      data,
      token
    );
    return result;
  };
  const getmanagereward = async (data, token) => {
    let result = await opsService.getData(
      baseUrl +"/managereward-get",
      data,
      token
    );
    return result;
  };
  const rewardpointdelete = async (data, token) => {
    let result = await opsService.postdata(
      baseUrl +"/managereward-delete",
      data,
      token
    );
    return result;
  };
  const rewardpointbyid = async (data, token) => {
    let result = await opsService.postdata(
      baseUrl +"/managereward-byid",
      data,
      token
    );
    return result;
  };

  export {
    addmanagereward,
    getmanagereward,
    rewardpointdelete,
    rewardpointbyid,
  };