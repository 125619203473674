import * as opsService from "./Ops";
import { baseUrl } from "../Pages/BaseUrl";
import { getTeam,updateteamLogo } from "../Constant/Api";

// ============================ team============================//
const addteam = async (data, token) => {
    let result = await opsService.postdata(baseUrl +"/team-insert", data, token);
    return result;
  };
  const getteam = async (data, token) => {
    let result = await opsService.getData(getTeam, data, token);
    return result;
  };
  const getjointeam = async (data, token) => {
    let result = await opsService.getData(baseUrl +"/jointeam-get", data, token);
    return result;
  };
  const teamdelete = async (data, token) => {
    let result = await opsService.postdata(baseUrl +"/team-delete", data, token);
    return result;
  };
  const teambyid = async (data, token) => {
    let result = await opsService.postdata(baseUrl +"/team-byid", data, token);
    return result;
  };
  
  const teamupdates = async (data, token) => {
    console.log("call team=====")
    let result = await opsService.postdata(baseUrl +"/team-update", data, token);
    return result;
  };
  const updateTeamLogo = async (data, token) => {
    let result = await opsService.postdata(
      updateteamLogo,
      data,
      token
    );
    return result;
  };
  export {
  
   
    addteam,
    getteam,
    getjointeam,
    teamdelete,
    teambyid,
    teamupdates,
    updateTeamLogo
    
  };