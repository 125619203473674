import React,{useState,useEffect} from "react";
import { baseUrls } from "../Constant/BaseUrl";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactPanZoom from 'react-image-pan-zoom-rotate';
function RotateImage(props) {
    
    const [image, setImage] = useState("")
    useEffect(() => {
     setImage(props.image)
    }, [props])
    
    const handleClose = () => {
        props.setImageShow(false);
      };
  return (
    <div>
      <Modal show={props.imageShow} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          
        </Modal.Header>

        <Modal.Body className="img-rotate">
        <ReactPanZoom
      image={baseUrls+image}
      alt="Image alt text"
    />
        </Modal.Body>
        <Modal.Footer>
        
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RotateImage;
