import * as opsService from "./Ops";
import { baseUrl } from "../Pages/BaseUrl";
import { getPlayerImage,updatePlayerImage,getPlayerById } from "../Constant/Api";

// ==============================player=============================
const addplayer = async (data, token) => {
    let result = await opsService.postdata(
      baseUrl +"/player-insert",
      data,
      token
    );
    return result;
  };
 
  const getplayer = async (data, token) => {
    let result = await opsService.getData(baseUrl +"/player-get", data, token);
    return result;
  };
  const playerdelete = async (data, token) => {
    let result = await opsService.postdata(
      baseUrl +"/player-delete",
      data,
      token
    );
    return result;
  };
  const playerbyid = async (data, token) => {
    let result = await opsService.postdata(baseUrl +"/player-byid", data, token);
    return result;
  };
  const playerupdates = async (data, token) => {
    let result = await opsService.postdata(
      baseUrl +"/player-update",
      data,
      token
    );
    return result;
  };
  const getplayerImage = async ( token) => {
    let result = await opsService.getData(getPlayerImage, token);
    return result;
  };
  const UpdateplayerImage = async (data, token) => {
    let result = await opsService.postdata(
      updatePlayerImage,
      data,
      token
    );
    return result;
  };

  const getplayerByID = async (data, token) => {
    let result = await opsService.postdata(
      getPlayerById,
      data,
      token
    );
    return result;
  };

  export {
  
    addplayer,
    getplayer,
    playerdelete,
    playerbyid,
    playerupdates,
    UpdateplayerImage,
    getplayerImage,
    getplayerByID
    
  };