import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { FirebaseAppProvider } from "reactfire";


const firebaseConfig = {
  apiKey: "AIzaSyAr9VrcK7yJAfGAYRL93LjAHOl1H5ekRdk",
  authDomain: "fantasy-75ad8.firebaseapp.com",
  projectId: "fantasy-75ad8",
  storageBucket: "fantasy-75ad8.appspot.com",
  messagingSenderId: "61411370054",
  appId: "1:61411370054:web:6c398900a4960aa555d647",
  measurementId: "G-0679FD3LZC"
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <App />
  </FirebaseAppProvider>
);
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js', {
      updateViaCache: 'none',
      scope: '/'
    })
    .then((registration) => {
      console.log('Firebase service worker registered:', registration);
    })
    .catch((err) => {
      console.error('Error registering Firebase service worker:', err);
    });
  });
}
