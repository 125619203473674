import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import configs from "../Constant/Config";
import { userLength,totalverifieduser,totalpendinguser, datecountuser, kycUser } from "../services/user";
import { getTotalContest } from "../services/contest";
// import { getTotalContestFilter } from "../services/contest";
import { todayDeposit,todayWithdrawal,totalWithdrawalRequest } from "../services/transactionDetail";
import Graph from "./Graph";
import KycGraph from "./KycGraph";
// import { baseUrl } from "../Pages/BaseUrl";
// import axios from "axios";
// const baseUrl = myConstList.baseUrl;
function Dashboard() {
 const [dataPoints, setDataPoints] = useState([])
  const [count, setCount] = useState("");
  const [contestCreated, setcontestCreated] = useState("");
  // const [activeContest, setActiveContest] = useState("");
  const [todayCreateContest, setTodayCreateContest] = useState("");
  // const [inactiveContest, setInactiveContest] = useState("");
  const [todayDeposite, setTodayDeposite] = useState("")
  const [todayWithdraw, setTodayWithdraw] = useState("")
  const [totalReq, setTotalReq] = useState("")
  const [totalVerifiedUser, setTotalVerifiedUser] = useState("")
  const [totalPendingUser, setTotalPendingUser] = useState("")
  const [kycuser, setKycUser] = useState([])
  useEffect(() => {
    totalUser();
    totalContest();
    // contestFilter();
    todaydeposit()
    todaywithdraw()
    totalRequest()
    totalVerifieduser()
    totalPendinguser()
    dateCountUser()
    kycUserData()
  }, []);
 
  const totalUser = async () => {
    let result = await userLength(configs);
    const userLengthS = result.data[0]["count(id)"];
    setCount(userLengthS);
  };

  const totalContest = async () => {
    let result = await getTotalContest(configs);
    const contestLength = result.contestLength[0]["count(id)"];

    const TodayDate = result.todayCreateContest[0]["count(createdAt)"];
    setTodayCreateContest(TodayDate);

    setcontestCreated(contestLength);
  };

  // const contestFilter = async () => {
  //   let result = await getTotalContestFilter(configs);
  //   // console.log(result.activeContest[0]["count(id)"]);
  //   setActiveContest(result.activeContest[0]["count(id)"]);
  //   setInactiveContest(
  //     result.inactiveContest[0]["count(id)"],
  //     "result.data[0]"
  //   );
  // };

  const todaydeposit=async()=>{
    let result = await todayDeposit(configs);
   
    if(result.status){
      if(result.data[0].totalamount!=null){
      setTodayDeposite(result.data[0].totalamount)
      }
      else{
        setTodayDeposite("0")
      }
    }
  }
  const todaywithdraw=async()=>{
    let result = await todayWithdrawal(configs);
 
    if(result.status){
      if(result.data[0].totalamount!=null){

        setTodayWithdraw(result.data[0].totalamount)
      }
      else{
        setTodayWithdraw("0")
      }
    }
  }
  const totalRequest=async()=>{
    let result = await totalWithdrawalRequest(configs);
   
    if(result.status){
      setTotalReq(result.data[0].totalrequest)
    }
  }
  const totalVerifieduser=async()=>{
    let result = await totalverifieduser(configs);
   
    if(result.status){
      setTotalVerifiedUser(result.data[0].verifieduser)
    }
  }
  const totalPendinguser=async()=>{
    let result = await totalpendinguser(configs);
   
    if(result.status){
      setTotalPendingUser(result.data[0].pendinguser)
    }
  }
  const dateCountUser=async()=>{
    let result = await datecountuser(configs);
   
    if(result.status){
      setDataPoints(result.data)
    }
  }
  
  const kycUserData=async()=>{
    let result=await kycUser(configs)
    if(result.status){
      setKycUser(result.data)
    }
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">

         
          <div className="container-fluid p-0 mt-2">
            <div className="row dashbord_man">
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/total-user">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Users</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {count}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span class="mdi mdi-account-group-outline"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/manage-payment">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Today Total Deposit</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {todayDeposite}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span class="mdi mdi-cash-fast"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/contest">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Created Contests</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {contestCreated}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <i class="mdi mdi-trophy-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
             
              
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/contest/today">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Today Created Constests</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {todayCreateContest}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <i class="mdi mdi-trophy-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/total-user/Verified">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Verified Users</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {totalVerifiedUser}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span class="mdi mdi-account-check-outline"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/total-user/Pending">
                    <div className="card-body">
                      <p className="fw-medium mb-0"> Unverified Users</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {totalPendingUser}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span class="mdi mdi-account-remove-outline"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/manage-withdraw-request">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Withdrawal Request</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {totalReq}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span class="mdi mdi-hand-coin-outline"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/manage-payment">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Today Total Withdrawal</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {todayWithdraw}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                             <span class="mdi mdi-calendar-today"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-12">
              <div className="white_box">
                <h3 className="text-center">Users</h3>
              <Graph dataPoints={dataPoints} />
              </div>
              </div>
              <div className="col-md-6 col-sm-6 col-12">
              <div className="white_box">
                <h3 className="text-center">KYC User</h3>
              <KycGraph kycuser={kycuser}/>
              </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
export default Dashboard;
