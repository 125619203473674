import React, { useState, useEffect, Fragment } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import configs from "../Constant/Config";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import {
  getcategory,
  getFixtureContext,
  getcontestdatabyid,
  deleteFixtureContest,
  contestpointupdates,
} from "../services/contest";
import "react-confirm-alert/src/react-confirm-alert.css";

const Fixturescontestlist = () => {
    const {id}=useParams()
  const [record, setRecord] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const initialValue = {
    rankfrom: "",
    torank: "",
    totalpercentage: "",
    prize: "",
    totalprizes: "",
  };
  const [formValueserr, setFormValueserr] = useState([{ initialValue }]);
  const [updateformValues, setupdateformValues] = useState([{ initialValue }]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [contesttype, setcontesttype] = useState("");
  const [totalprize, settotalprize] = useState("");
  const [entryfees, setentryfees] = useState("");
  const [maxteamperuser, setmaxteamperuser] = useState(2);
  const [autocreate, setautocreate] = useState("");
  const [contestcategory, setcontestcategory] = useState("");
  const [bonus, setbonus] = useState("");
  const [dynamiccontest, setdynamiccontest] = useState("");
  const [s_time, sets_time] = useState("")
  const [e_time, sete_time] = useState("")
   const [s_date, sets_date] = useState("")
   const [e_date, sete_date] = useState("")

  const [categorydata, setCategorydata] = useState([]);
  // const [show, setShow] = useState(false);

  const [contestid, setcontestid] = useState([]);
  const [updateshow, setupdateshow] = useState(false);
  

  const [nameerr, setNameerr] = useState(false);
  const [descriptionerr, setDescriptionerr] = useState(false);
  const [contesttypeerr, setcontesttypeerr] = useState(false);
  const [totalprizeerr, settotalprizeerr] = useState(false);
  const [entryfeeserr, setentryfeeserr] = useState(false);
  const [maxteamsperusererr, setmaxteamsperusererr] = useState(false);
  const [autocreateerr, setautocreateerr] = useState(false);
  const [contestcategoryerr, setcontestcategoryerr] = useState(false);
  const [bonuserr, setbonuserr] = useState(false);
  const [dynamiccontesterr, setdynamiccontesterr] = useState(false);
  const [s_timeErr, sets_timeErr] = useState(false)
  const [e_timeErr, sete_timeErr] = useState(false)
   const [s_dateErr, sets_dateErr] = useState(false)
   const [e_dateErr, sete_dateErr] = useState(false)
  const [formValues, setFormValues] = useState([{ initialValue }]);

  // ============= for more add field ==================//

 
  

 

  

  // ====================================== update modal add more field ========================//

  let handleChangemoreupdatefield = (e, i) => {
    let newFormValuess = [...updateformValues];
    let newFormValuesErr = [...formValueserr];

    newFormValuess[i][e.target.name] = e.target.value;
    setupdateformValues(newFormValuess);

    if (e.target.name === "rankfrom") {
      if (
        !newFormValuess[i].rankfrom ||
        newFormValuess[i].rankfrom === undefined
      ) {
        newFormValuesErr[i][e.target.name] = "This field is required";
        setupdateformValues(newFormValuess);

        setFormValueserr(newFormValuesErr);
        return false;
      } else {
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setupdateformValues(newFormValuess);
      }
    }

    if (e.target.name === "torank") {
      if (!newFormValuess[i].torank || newFormValuess[i].torank === undefined) {
        newFormValuesErr[i][e.target.name] = "This field is required";

        setupdateformValues(newFormValuess);
        setFormValueserr(newFormValuesErr);
        return false;
      } else {
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setupdateformValues(newFormValuess);
      }
    }

    if (e.target.name === "totalpercentage") {
      if (
        !newFormValuess[i].totalpercentage ||
        newFormValuess[i].totalpercentage === undefined
      ) {
        newFormValuesErr[i][e.target.name] = "This field is required";

        setupdateformValues(newFormValuess);
        setFormValueserr(newFormValuesErr);
        return false;
      } else {
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setupdateformValues(newFormValuess);
      }
    }

    if (e.target.name === "prize") {
      if (!newFormValuess[i].prize || newFormValuess[i].prize === undefined) {
        newFormValuesErr[i][e.target.name] = "This field is required";

        setupdateformValues(newFormValuess);
        setFormValueserr(newFormValuesErr);
        return false;
      } else {
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setupdateformValues(newFormValuess);
      }
    }

    if (e.target.name === "totalprizes") {
      if (
        !newFormValuess[i].totalprizes ||
        newFormValuess[i].totalprizes === undefined
      ) {
        newFormValuesErr[i][e.target.name] = "This field is required";

        setupdateformValues(newFormValuess);
        setFormValueserr(newFormValuesErr);
        return false;
      } else {
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setupdateformValues(newFormValuess);
      }
    }
  };

  let addFormupdateFields = () => {
    setupdateformValues([...updateformValues, {}]);
    setFormValueserr([...formValueserr, { initialValue }]);
  };

  let updateremoveFormFields = (i) => {
    let newFormValues = [...updateformValues];
    newFormValues.splice(i, 1);
    setupdateformValues(newFormValues);
  };
  // ========================================
  const handleClose = () => {
    setName("");
    setDescription("");
    settotalprize("");
    setentryfees("");
    settotalprize("");
    setcontesttype("");
    setautocreate("");
    setcontestcategory("");
    setbonus("");
    setdynamiccontest("");
    sets_time("")
    sets_date("")
    sete_date("")
    sete_time("")
    setNameerr("");
    setDescriptionerr("");
    settotalprizeerr("");
    setentryfeeserr("");
    settotalprizeerr("");
    setmaxteamsperusererr("");
    setautocreateerr("");
    setcontesttypeerr("");
    setcontestcategoryerr("");
    setbonuserr("");
    setdynamiccontesterr("");
    sets_timeErr("")
    sets_dateErr("")
    sete_timeErr("")
    sete_dateErr("")
    setFormValues([{}]);
    setFormValueserr([{}]);
    // setShow(false);
    setupdateshow(false);
  };


  // ============ errors========================//
 

  const handleupdateShow = (id) => {
    iddata(id);
    setupdateshow(true);
    setcontestid(id);
  };
  // const handleShow = (id) => {
  //   setShow(true);
  // };
  // =================  for react data table =============================//
  const columns = [
    {
      key: "id",
      text: "S.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Contest ID",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },


    {
      key: "contestName",
      text: "Contest Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "category",
      text: "Category",
      className: "Name",
      align: "left",
      sortable: true,
    },

    {
      key: "entryfees",
      text: "Entry Fees",
      className: "Entry Fee",
      align: "left",
      sortable: true,
    },
    {
      key: "total_spots",
      text: "Total Spots",
      className: "Total Teams",
      align: "left",
      sortable: true,
    },

    {
      key: "totalprize",
      text: " Total Prize",
      className: "Prize",
      align: "left",
      sortable: true,
    },
    {
      key: "left_spots",
      text: "Left Spots",
      className: "Total Teams",
      align: "left",
      sortable: true,
    },
    {
      key: "view",
      text: "Contest View",
      className: "view1 text-center",
      align: "left",
      sortable: true,
      cell:(response)=>{
        return (
          <Link to={`/fixture-contest-view/${response.id}`} className="btn btn-primary">View</Link>
        )
      }
    },
    {
      key: "action",
      text: "Action",
      className: "view",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {/* {record.dynamic=="Dynamic"?<Link
              to=""
              onClick={() => {
                handleupdateShow(record.id);
              }}
              // style={{ float: "left", cursor: "pointer" }}
              style={{float: "left", cursor: "pointer",pointerEvents:record.match_status=="started" || record.match_status=="completed"?"none":"auto"}}
            >
              <span class="mdi mdi-square-edit-outline"></span>
            </Link>:""} */}
            
            <Link
              to=""
              onClick={() => Conn(record.id)}
              // style={{ marginLeft: "5px" }}
              style={{marginLeft: "5px" ,pointerEvents:record.match_status=="started" || record.match_status=="completed"?"none":"auto"}}

            >
              <span class="mdi mdi-trash-can-outline"></span>
            </Link>
          </Fragment>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Filter in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {};


  

  //  ============ contestbyid==================//
  const iddata = async (id) => {
    let datas = {
      id,
    };
    let data = await getcontestdatabyid(datas, configs);
    const {
      contestName,
      description,
      contesttype,
      totalprize,
      entryfees,
      maxteamperuser,
      autocreate,
      categoryId,
      bonus,
      dynamic,
      start_time,
      start_date,
      end_time,
      end_date
    } = data.data.databyid[0];

    setName(contestName);
    setDescription(description);
    setcontesttype(contesttype);
    settotalprize(totalprize);
    if (contesttype === "Free") {
      setIsDisabled(true);
    }
    setentryfees(entryfees);
    setmaxteamperuser(maxteamperuser);
    setautocreate(autocreate);
    setcontestcategory(categoryId);
    setbonus(bonus);
    setdynamiccontest(dynamic);
    sets_time(start_time)
    sets_date(moment(start_date).format("YYYY-MM-DD"))
    sete_time(end_time)
    sete_date(moment(end_date).format("YYYY-MM-DD"))
    setupdateformValues(data.data.databyid2);
  };

  // ================ contestbyid==================//

  const getdata = async () => {
    const data={
        id:id
    }
    let result = await getFixtureContext(data,configs);
    if (result.status === true) {
      setRecord(result.data);
    }
  };

  // ========= for contest category dropdown api ===========

  const getcategorydata = async () => {
    let result = await getcategory(configs);
    if (result.status === true) {
      setCategorydata(result.data.categorydata);
    }
  };
  // ============== for delete=-========//
  const deletecontest = async (id) => {
    let data = {
      id,
    };
    let res = await deleteFixtureContest(data, configs);
    if (res.status === true) {
      toast.dismiss();
      toast.success(res.message);
    }
    else{
      toast.dismiss();
      toast.error(res.message);
    }
    getdata();
  };

  const Conn = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deletecontest(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  // =======for update ==================//
  const contestupdate = async () => {
    if (!name) {
      setNameerr("This field is required");
      return false;
    }
    if (!description) {
      setDescriptionerr("This field is required");
      return false;
    }
    if (!contesttype) {
      setcontesttypeerr("This field is required");
      return false;
    }
    if (!totalprize) {
      settotalprizeerr("This field is required");
      return false;
    }
    if (totalprize <= 0) {
      settotalprizeerr("Enter correct input value");
      return false;
    }
    if (entryfees !== "") {
      if (entryfees <= 0) {
        setentryfeeserr("Enter correct input value");
        return false;
      }
    }

    if (!maxteamperuser) {
      setmaxteamsperusererr("This field is required");
      return false;
    }
    var regex = /^[-+]?[0-9]+\.[0-9]+$/;
    var isValidated = regex.test(maxteamperuser);

    if (isValidated) {
      setmaxteamsperusererr("Enter valid format");
      return false;
    }
    if (maxteamperuser < 2) {
      setmaxteamsperusererr("Minimum 2 player are required");
      return false;
    }
    if (maxteamperuser < 2) {
      setmaxteamsperusererr("Enter correct input value");
      return false;
    }
    if (!autocreate) {
      setautocreateerr("This field is required");
      return false;
    }
    if (!contestcategory) {
      setcontestcategoryerr("This field is required");
      return false;
    }

    if (!dynamiccontest) {
      setdynamiccontesterr("This field is required");
      return false;
    }
    if (!s_time) {
      sets_timeErr("This field is required");
      return false;
    }
    if (!s_date) {
      sets_dateErr("This field is required");
      return false;
    }
    if (!e_time) {
      sete_timeErr("This field is required");
      return false;
    }
    if (!e_date) {
      sete_dateErr("This field is required");
      return false;
    }
    for (let i = 0; i < updateformValues.length; i++) {
      let newFormValuesErr = [...formValueserr];

      if (
        !updateformValues[i].rankfrom ||
        updateformValues[i].rankfrom === undefined
      ) {
        newFormValuesErr[i].rankfrom = "This field is required";

        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (updateformValues[i].rankfrom <= 0) {
        newFormValuesErr[i].rankfrom = "Enter valid input format";
        setFormValueserr(newFormValuesErr);
        return false;
      }

      if (!updateformValues[i].torank) {
        newFormValuesErr[i].torank = "This field is required";
        setFormValueserr(newFormValuesErr);
        return false;
      }

      if (updateformValues[i].torank < updateformValues[i].rankfrom) {
        newFormValuesErr[i].rankfrom = "Enter valid rank format";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (!updateformValues[i].totalpercentage) {
        newFormValuesErr[i].totalpercentage = "This field is required";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (updateformValues[i].totalpercentage <= 0) {
        newFormValuesErr[i].totalpercentage = "Enter valid input format";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (!updateformValues[i].prize) {
        newFormValuesErr[i].prize = "This field is required";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (updateformValues[i].prize <= 0) {
        newFormValuesErr[i].prize = "Enter valid input format";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (!updateformValues[i].totalprizes) {
        newFormValuesErr[i].totalprizes = "This field is required";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (updateformValues[i].totalprizes <= 0) {
        newFormValuesErr[i].totalprizes = "Enter valid input format";
        setFormValueserr(newFormValuesErr);

        return false;
      }
    }

    let data = {
      id: contestid,
      contestName: name,
      description,
      contesttype,
      totalprize,
      entryfees,
      maxteamperuser,
      autocreate,
      categoryId: contestcategory,
      bonus,
      dynamic: dynamiccontest,
      start_time:s_time,
      start_date:s_date,
      end_time:e_time,
      end_date:e_date,
      atribute: updateformValues,
    };
    let resp = await contestpointupdates(data, configs);
    if (resp.status === true) {
      toast.dismiss();
      toast.success(resp.message);
    }
    // combinedata();
    handleClose();
  };

  useEffect(() => {
    getdata()
       
    // combinedata();
  }, []);

  const handlechange = (e) => {
    const { name, value } = e.target;

    const validateInput = (name, value) => {
      if (value === "" || value === undefined) {
        return ["This field is required", false];
      } else {
        return ["", true];
      }
    };

    switch (name) {
      case "name":
        const [nameErr] = validateInput(name, value);
        setName(value);
        setNameerr(nameErr);
        break;

      case "description":
        const [descErr] = validateInput(name, value);
        setDescription(value);
        setDescriptionerr(descErr);
        break;

      case "contesttype":
        const [contestTypeErr] = validateInput(name, value);
        setcontesttype(value);
        setcontesttypeerr(contestTypeErr);

        if (value === "Free") {
          setIsDisabled(true);
          setentryfeeserr("");
        } else {
          setIsDisabled(false);
        }
        break;

      case "totalprize":
        const [totalPrizeErr] = validateInput(name, value);
        settotalprize(value);
        settotalprizeerr(totalPrizeErr);
        break;

      case "entryfees":
        const [entryFeesErr] = validateInput(name, value);
        setentryfees(value);
        setentryfeeserr(entryFeesErr);
        break;

      case "maxteamsperuser":
        const [maxTeamsErr, isMaxTeamsValid] = validateInput(name, value);
        setmaxteamperuser(value);

        if (isMaxTeamsValid) {
          const regex = /^[-+]?[0-9]+\.[0-9]+$/;
          const isValidated = regex.test(value);

          if (isValidated) {
            setmaxteamsperusererr("Enter valid format");
          } else {
            setmaxteamsperusererr("");
          }
        } else {
          setmaxteamsperusererr(maxTeamsErr);
        }
        break;

      case "autocreate":
        const [autoCreateErr] = validateInput(name, value);
        setautocreate(value);
        setautocreateerr(autoCreateErr);
        break;

      case "contestcategory":
        const [contestCatErr] = validateInput(name, value);
        setcontestcategory(value);
        setcontestcategoryerr(contestCatErr);
        break;

      case "bonus":
        setbonus(value);

        if (value !== "" && value <= 0) {
          setbonuserr("Enter valid input value");
        } else {
          setbonuserr("");
        }
        break;

      case "dynamiccontest":
        const [dynamicContestErr] = validateInput(name, value);
        setdynamiccontest(value);
        setdynamiccontesterr(dynamicContestErr);
        break;
        case "s_time":
        const [s_timeErr] = validateInput(name, value);
        sets_time(value);
        sets_timeErr(s_timeErr);
        break;
        case "e_time":
        const [e_timeErr] = validateInput(name, value);
        sete_time(value);
        sete_timeErr(e_timeErr);
        break;
        case "s_date":
        const [s_dateErr] = validateInput(name, value);
        sets_date(value);
        sets_dateErr(s_dateErr);
        break;
        case "e_date":
          const [e_dateErr] = validateInput(name, value);
          sete_date(value);
          sete_dateErr(e_dateErr);
          break;

      default:
        break;
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Contest Templates</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer">
                  <div className="add-product-btn text-center">
                 
                  <Link  to={`/fixturescontest/${id}`} className="btn btn-primary" > Create Contest</Link>
                    {/* <button
                    
                      className="btn btn-primary"
                    >
                      Create Contest
                    </button> */}
                    {/* ========================== Add modal=============================== */}
              
                    {/* ========================= =update modal============================= */}
                    <Modal size="lg" show={updateshow} onHide={handleClose}>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Title style={{ color: "black" }}>
                        Update Contest Template
                      </Modal.Title>
                      <Modal.Body>
                        <Row>
                          <Col xs={12} md={5}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Name </Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                value={name}
                                placeholder="Enter Name `"
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{nameerr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={7}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Description </Form.Label>
                              <Form.Control
                                type="text"
                                name="description"
                                value={description}
                                placeholder="Enter Description"
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>
                                {descriptionerr}
                              </span>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={2}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Contest Type </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="contesttype"
                                onChange={handlechange}
                                value={contesttype}
                              >
                                <option value="Paid">Paid</option>
                                <option value="Free">Free</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>

                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Total Prize </Form.Label>
                              <Form.Control
                                type="number"
                                name="totalprize"
                                value={totalprize}
                                placeholder="Enter Total Prize"
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>
                                {totalprizeerr}
                              </span>
                            </Form.Group>
                          </Col>
                          {isDisabled === false || contesttype === "Paid" ? (
                            <Col xs={12} md={3}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Entry Fee</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="entryfees"
                                  placeholder="Enter Entry Fee"
                                  value={entryfees}
                                  onChange={handlechange}
                                />
                                <span style={{ color: "red" }}>
                                  {entryfeeserr}
                                </span>
                              </Form.Group>
                            </Col>
                          ) : null}

                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Max Teams Per User</Form.Label>
                              <Form.Control
                                type="number"
                                name="maxteamsperuser"
                                placeholder="Max Teams Per User"
                                value={maxteamperuser}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>
                                {maxteamsperusererr}
                              </span>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Auto Create When Contest Full
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="autocreate"
                                onChange={handlechange}
                                value={autocreate}
                              >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {autocreateerr}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Contest Category</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="contestcategory"
                                onChange={handlechange}
                                value={contestcategory}
                              >
                                {categorydata.map((category, index) => {
                                  return (
                                    <>
                                      <option value={category.categoriesId}>
                                        {category.name}
                                      </option>
                                    </>
                                  );
                                })}
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {contestcategoryerr}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Bonus</Form.Label>
                              <Form.Control
                                type="number"
                                name="bonus"
                                placeholder="bonus"
                                value={bonus}
                                onChange={handlechange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Dynamic Contest</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="dynamiccontest"
                                onChange={handlechange}
                                value={dynamiccontest}
                              >
                                <option value="Normal">Normal</option>
                                <option value="Dynamic">Dynamic</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {dynamiccontesterr}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                             <Form.Label>Start Time </Form.Label>
                              <Form.Control
                                type="time"
                                name="s_time"
                                placeholder="Start Time"
                                value={s_time}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{s_timeErr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                             <Form.Label>Start Date </Form.Label>
                              <Form.Control
                                type="date"
                                name="s_date"
                                placeholder="Start Time"
                                value={s_date}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{s_dateErr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                             <Form.Label>End Time </Form.Label>
                              <Form.Control
                                type="time"
                                name="e_time"
                                placeholder="End Time"
                                value={e_time}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{e_timeErr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                             <Form.Label>End Date </Form.Label>
                              <Form.Control
                                type="date"
                                name="e_date"
                                placeholder="End Time"
                                value={e_date}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{e_dateErr}</span>
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* ============add more update================== */}
                        {updateformValues.map((item, index) => {
                          return (
                            <>
                              <Row>
                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label> Rank From</Form.Label>
                                    <Form.Control
                                      style={{ color: "#1a1d21" }}
                                      type="number"
                                      name="rankfrom"
                                      placeholder=" Enter rankfrom"
                                      value={item.rankfrom || ""}
                                      onChange={(e) =>
                                        handleChangemoreupdatefield(e, index)
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.rankfrom}
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label> To Rank </Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="torank"
                                      placeholder="To rank"
                                      value={item.torank}
                                      onChange={(e) =>
                                        handleChangemoreupdatefield(e, index)
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.torank}
                                    </span>
                                  </Form.Group>
                                </Col>

                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label> Total Percentage</Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="totalpercentage"
                                      placeholder=" Total Percentage"
                                      value={item.totalpercentage}
                                      onChange={(e) =>
                                        handleChangemoreupdatefield(e, index)
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.totalpercentage}
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>Prize Per User</Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="prize"
                                      placeholder="Prize"
                                      value={item.prize}
                                      onChange={(e) =>
                                        handleChangemoreupdatefield(e, index)
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.prize}
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label> Total Prize</Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="totalprizes"
                                      placeholder=" Total Prize"
                                      value={item.totalprizes}
                                      onChange={(e) =>
                                        handleChangemoreupdatefield(e, index)
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.totalprizes}
                                    </span>
                                  </Form.Group>
                                  {index ? (
                                    <button
                                      type="button"
                                      className="button remove"
                                      onClick={() =>
                                        updateremoveFormFields(index)
                                      }
                                    >
                                      - Remove
                                    </button>
                                  ) : null}
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                        <div className="add-row">
                          <a href="#!" onClick={() => addFormupdateFields()}>
                            + Add More
                          </a>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button variant="secondary" onClick={contestupdate}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Fixturescontestlist;
