const sideMenuList = [
  {
    id: 1,
    title: "Dashboard",
    route: "/dashboard",
    icon: "mdi mdi-speedometer",
  },
  {
    id: 2,
    title: "User Management",
    route: "/user-management",
    icon: "mdi mdi-account-outline",
    subMenu: [
      {
        id: 1,
        title: "User",
        route: "/total-user",
        icon: "mdi mdi-account-outline",
      },
      {
        id: 2,
        title: "Referral Leaderboard",
        route: "/referral-board",
        icon: "mdi mdi-hand-coin-outline",
      },
    ],
  },

  {
    id: 3,
    title: "Wallet Management",
    route: "",
    icon: "mdi mdi-wallet-outline",
    subMenu: [
      {
        id: 1,
        title: "Manage Payment",
        route: "/manage-payment",
        icon: "mdi mdi-currency-usd",
      },

      {
        id: 2,
        title: "Payment Withdraw Request",
        route: "/manage-withdraw-request",
        icon: "mdi mdi-hand-coin-outline",
      },
      
    ],
  },
  {
    id: 4,
    title: "Verification",
    route: "/verification",
    icon: "mdi mdi-check-all",
  },
  {
    id: 5,
    title: "Contests",
    route: "",
    icon: "mdi mdi-trophy-outline",
    subMenu: [
      {
        id: 1,
        title: "Contest Categories",
        route: "/contest-categories",
        icon: "mdi mdi-sitemap-outline",
      },
      {
        id: 2,
        title: "Contest-Template",
        route: "/contest-template",
        icon: "mdi mdi-laptop",
      },
      {
        id: 3,
        title: "Contest",
        route: "/contest",
        icon: "mdi mdi-trophy-outline",
      },
    ],
  },
  // {
  //   id: 6,
  //   title: "Rank Categories",
  //   route: "/rank-categories",
  //   icon: "mdi mdi-poll",
  // },
  // {
  //   id: 7,
  //   title: "Fixtures",
  //   route: "/fixture",
  //   icon: "mdi mdi-speedometer",
  // },
  {
    id: 7,
    title: "Fantasy Point",
    route: "/fantasy-points",
    icon: "mdi mdi-cricket",
  },
  {
    id: 8,
    title: "Team Management",
    route: "/team-management",
    icon: "mdi mdi-account-group-outline",
    subMenu: [
      {
        id: 1,
        title: "Team",
        route: "/team",
        icon: "mdi mdi-account-group-outline",
      },
      // {
      //   id: 2,
      //   title: "Player",
      //   route: "/player",
      //   icon: "mdi mdi-speedometer",
      // },
      {
        id: 2,
        title: "Player",
        route: "/player-image",
        icon: "mdi mdi-account-outline",
      },
    ],
  },

  {
    id: 9,
    title: "Cricket",
    route: "/cricket",
    icon: "mdi mdi-cricket",
    subMenu: [
      {
        id: 1,
        title: "Fixtures",
        route: "/fixture",
        icon: "mdi mdi-sitemap-outline",
      },
      // {
      //   id: 1,
      //   title: "Manage Matches",
      //   route: "/manage-matches",
      //   icon: "mdi mdi-speedometer",
      // },
      {
        id: 2,
        title: "Competitions",
        route: "/competitions",
        icon: "mdi mdi-trophy-outline",
      },
      // {
      //   id: 3,
      //   title: "Contest Manager",
      //   route: "/contest-manager",
      //   icon: "mdi mdi-trophy-outline",
      // },
      // {
      //   id: 4,
      //   title: "Revenue Management",
      //   route: "/revenue-management",
      //   icon: "mdi mdi-speedometer",
      // },
      // {
      //   id: 5,
      //   title: "Manage Reward Points",
      //   route: "/manage-reward-points",
      //   icon: "mdi mdi-gift-open-outline",
      // },
      // {
      //   id: 6,
      //   title: "Manage Reports",
      //   route: "/manage-reports",
      //   icon: "mdi mdi-chart-box-outline",
      // },
      // {
      //   id: 7,
      //   title: "Notification",
      //   route: "/notification",
      //   icon: "mdi mdi-bell-outline",
      // },
    ],
  },
  {
    id: 10,
    title: "Detail Pages",
    route: "/detail-pages",
    icon: "mdi mdi-format-page-break",
    subMenu: [
      {
        id: 1,
        title: "About Us ",
        route: "/about-us",
        icon: "mdi mdi-account-multiple-outline",
      },
      {
        id: 2,
        title: "Contact Us",
        route: "/contact-us",
        icon: "mdi mdi-email-alert-outline",
      },
      {
        id: 3,
        title: "Help And Support",
        route: "/help-support",
        icon: "mdi mdi-headphones",
      },
      {
        id: 4,
        title: "Legality ",
        route: "/legality",
        icon: "mdi mdi-scale-balance",
      },
      {
        id: 5,
        title: "Term And Condition",
        route: "/term-and-condition",
        icon: "mdi mdi-format-list-checks",
      },
        ],
  },
  {
    id: 11,
    title: "Cms Management",
    route: "/cms-management",
    icon: "mdi mdi-cog-outline",
    subMenu: [
      
      {
        id: 1,
        title: "Support  ",
        route: "/support",
        icon: "mdi mdi-ticket-outline",
      },
      {
        id: 2,
        title: "Send Mail",
        route: "/send-mail",
        icon: "mdi mdi-email-check-outline",
      },
      {
        id: 3,
        title: " Push Notification",
        route: "/push-notification",
        icon: "mdi mdi-bell-outline",
      },
    ],
  },
  // {
  //   id: 12,
  //   title: "Notifications",
  //   route: "/notifications",
  //   icon: "mdi mdi-bell-outline",
  // },

  {
    id: 12,
    title: "Settings",
    route: "/Settings",
    icon: "mdi mdi-cog-outline",
  },
  // {
  //   id: 15,
  //   title: "FAQ",
  //   route: "/faq",
  //   icon: "mdi mdi-speedometer",
  // },
  {
    id: 13,
    title: "Banner Management",
    route: "/banner-management",
    icon: "mdi mdi-speedometer",
    subMenu: [
      {
        id: 1,
        title: "Slider Banner",
        route: "/banner",
        icon: "mdi mdi-speedometer",
      },
      {
        id: 2,
        title: "Promotion Banner",
        route: "/promotion-banner",
        icon: "mdi mdi-playlist-check",
      },
     
    ],
  },
  // {
  //   id: 15,
  //   title: "Banner",
  //   route: "/banner",
  //   icon: "mdi mdi-speedometer",
  // },

  
];
export default sideMenuList;
