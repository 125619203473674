import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import ReactDatatable from "@mkikets/react-datatable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { confirmAlert } from "react-confirm-alert";
import {
  addmanagereward,
  getmanagereward,
  rewardpointdelete,
  rewardpointbyid,
} from "../services/rewards";
import "react-confirm-alert/src/react-confirm-alert.css";
// import { baseUrls } from "./BaseUrl";
import { baseUrls } from "../Constant/BaseUrl";
import configs from "../Constant/Config";
import {inputFieldValidation} from "../Validations/validation";

export const Managerewardpoints = () => {
  const [record, setRecord] = useState([]);
  const [show, setShow] = useState(false);
  const [showupdate, setShowupdate] = useState(false);
  // const [Updateid ,setUpdateid] = useState([]);
  const [gametype, setGametype] = useState("");
  const [pdf, setpdf] = useState(false);
  const [name, setName] = useState("");
  const [point, setPoint] = useState("");
  const [gametypeerr, setGametypeerr] = useState(false);
  const [pdferr, setpdferr] = useState(false);
  const [nameerr, setNameerr] = useState(false);
  const [pointerr, setPointerr] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setGametype("");
    setpdf("");
    setName("");
    setPoint("");
    setGametypeerr("");
    setpdferr("");
    setNameerr("");
    setPointerr("");
  };
  const handleShowupdate = (id) => {
   
    iddata(id);
    setShowupdate(true);
    // setUpdateid(id);
  };
  const handleCloseUpdate = () => {
    setShowupdate(false);
  };

  const columns = [
    {
      key: "S.no",
      text: "S.no",
      className: "S.no",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "gameType",
      text: "Game Type ",
      className: "Game Type ",
      align: "left",
      sortable: true,
    },
    {
      key: "description",
      text: "desc",
      className: "desc",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Link to={baseUrls + "/static/managePoint/" + record.description}>
            <i className="fa fa-file-pdf-o" title="pdf" aria-hidden="true"></i>
          </Link>
        );
      },
    },
    {
      key: "name",
      text: "Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "points",
      text: "Points",
      className: "Points",
      align: "left",
      sortable: true,
    },

    {
      key: "view",
      text: "Action",
      className: "view",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link to="" onClick={() =>{handleShowupdate(record?.id)}}>
              <img
                src="assets/images/edit-icon.png"
                alt=""
                title="Edit"
                className="img-fluid"
              />
            </Link>
            <Link to="" onClick={() => Conn(record.id)}>
              <img
                src="assets/images/delete-icon.png"
                alt=""
                title="Delete"
                className="img-fluid"
              />
            </Link>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const onsubmit = async (e) => {
    let err = "This field is required";
    if (!gametype) {
      setGametypeerr(err);
      return false;
    }

    if (!pdf) {
      setpdferr(err);
      return false;
    }

    if (!pdf.name.match("pdf")) {
      setpdferr("Only pdf supported");
      return false;
    }

    if (!name) {
      setNameerr(err);
      return false;
    }
    if (!point) {
      setPointerr(err);
      return false;
    }

    const formdata = new FormData();
    formdata.append("gameType", gametype);
    formdata.append("rewardpoint", pdf);
    formdata.append("name", name);
    formdata.append("points", point);

    let result = await addmanagereward(formdata, configs);

    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
      getdata();
      handleClose();
    }
  };
  const getdata = async () => {
    let result = await getmanagereward(configs);
    setRecord(result.data);
  };
  useEffect(() => {
    getdata();
  }, []);
  const deletereward = async (id) => {
    let data = {
      id,
    };
    let res = await rewardpointdelete(data, configs);

    if (res.status === true) {
      toast.dismiss();
      toast.success(res.message);
    }
    getdata();
  };
  const iddata = async (id) => {
    let datas = {
      id,
    };
    let data = await rewardpointbyid(datas, configs);
    const { gameType, description, name, points } = data.data[0];
    setGametype(gameType);
    setpdf(description);
    setName(name);
    setPoint(points);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;

    if (name === "gametype") {
      setGametype(value);
      const err = inputFieldValidation(name, value);
      setGametypeerr(err);
    }
    if (name === "pdf") {
      if (value === "" || value === undefined) {
        setpdf(e.target.files[0]);
        setpdferr("This field is required");
        return false;
      } else {
        setpdf(e.target.files[0]);
        setpdferr("");
      }
      if (!e.target.files[0].name.match("pdf")) {
        setpdf(e.target.files[0]);
        setpdferr("Only pdf supported");
        return false;
      } else {
        setpdf(e.target.files[0]);
        setpdferr("");
      }
    }
    if (name === "name") {
      setName(value);
      const err = inputFieldValidation(name, value);
      setNameerr(err);
    }

    if (name === "Points") {
      setPoint(value);
      const err = inputFieldValidation(name, value);
      setPointerr(err);
    }
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  const Conn = (getMethodDeleteId) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deletereward(getMethodDeleteId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Manage Reward Points</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer">
                  <div className="add-product-btn text-center">
                    <button
                      onClick={() => handleShow()}
                      className="btn btn-primary"
                    >
                      Add Reward Point
                    </button>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Title style={{ color: "black" }}>
                        Add Reward Point Details
                      </Modal.Title>
                      <Modal.Body>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label> Game Type </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="gametype"
                              onChange={handlechange}
                            >
                              <option selected disabled>
                                Please select
                              </option>
                              <option value="IPL">IPL</option>
                              <option value="20-20">20-20</option>
                              <option value="50-50">50-50</option>
                              <option value="TEST">TEST</option>
                              <option value="WORLD-CUP">WORLD-CUP</option>
                            </Form.Select>
                            <span style={{ color: "red" }}>{gametypeerr}</span>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Description</Form.Label>
                            <img src={pdf} alt="" />
                            <Form.Control
                              type="file"
                              name="pdf"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{pdferr}</span>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={name}
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{nameerr}</span>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Points</Form.Label>
                            <Form.Control
                              type="text"
                              name="Points"
                              value={point}
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{pointerr}</span>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={onsubmit}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Modal show={showupdate} onHide={handleCloseUpdate}>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Title style={{ color: "black" }}>
                        Update Reward Point Details
                      </Modal.Title>
                      <Modal.Body>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label> Game Type </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="gametype"
                              onChange={handlechange}
                              value={gametype}
                            >
                              <option selected disabled>
                                Please select
                              </option>
                              <option value="IPL">IPL</option>
                              <option value="20-20">20-20</option>
                              <option value="50-50">50-50</option>
                              <option value="TEST">TEST</option>
                              <option value="WORLD-CUP">WORLD-CUP</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>pdf</Form.Label>
                            <Form.Control
                              type="file"
                              name="pdf"
                              onChange={handlechange}
                            />
                            {/* <span style={{ color: "red" }}>{imageerr}</span> */}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={name}
                              onChange={handlechange}
                            />
                            {/* <span style={{ color: "red" }}>{imageerr}</span> */}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Points</Form.Label>
                            <Form.Control
                              type="text"
                              name="Points"
                              value={point}
                              onChange={handlechange}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseUpdate}>
                          Close
                        </Button>
                        <Button variant="primary">Save</Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
