import React from "react";
import { Doughnut, Pie } from "react-chartjs-2";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

Chart.register(CategoryScale);

function KycGraph({ kycuser }) {
  const counts = kycuser.map((data) => data.count);
  const status = kycuser.map((data) => data.kycStatus);

  const chartData = {
    labels: status,
    datasets: [
      {
        label: "Count",
        data: counts,
        backgroundColor: ["orange", "red", "green", "yellow"], // Custom color for bars
      },
    ],
  };
  const chartOptions = {
    maintainAspectRatio: false ,
    // scales: {
    //   x: {
    //     type: "category",
    //     title: {
    //       display: true,
    //       text: "Status",
    //     },
    //   },
    //   y: {
    //     title: {
    //       display: true,
    //       text: "Count",
    //     },
    //   },
    // },
  };

  return (
    <div>
      {/* <DateRangePicker ranges={selectedRange} onChange={handleDateSelect} /> */}
      <Doughnut data={chartData} options={chartOptions}  width={100}
  height={330}/>
    </div>
  );
}

export default KycGraph;
