import React, { useState, useEffect } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import { toast } from "react-toastify";
import { getLegalitydata, updateLegality } from "../services/support";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import { baseUrls } from "./BaseUrl";
import { baseUrls } from "../Constant/BaseUrl";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import configs from "../Constant/Config";
import Form from "react-bootstrap/Form";

export const Legality = () => {
  const [record, setRecord] = useState([]);
  const [name, setName] = useState("");
  const [doc, setDoc] = useState("");
  const [docErr, setDocErr] = useState("");

  const getdata = async () => {
    let data = await getLegalitydata(configs);

    setRecord(data.data);
    setName(data.data[0].name);
  };
  useEffect(() => {
    getdata();
  }, []);
  const onSubmitHandler = async (id, e) => {
    if (!doc) {
      setDocErr("This field is required");
      return false;
    }
    if (!doc.name.match("pdf")) {
      setDocErr("Only pdf supported");
      return false;
    }
    console.log(name, "happy");
    const formdata = new FormData();
    formdata.append("id", record[0].id);
    formdata.append("name", name);
    formdata.append("document", doc);

    let result = await updateLegality(formdata, configs);
    if (result.status === true) {
      toast.success(result.message);
    }

    if (result.status === false) {
      toast.dismiss();
      toast.success(result.message);
    }
  };
  const imageChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "document") {
      if (!value || value === undefined) {
        setDoc(e.target.files[0]);
        setDocErr("This field is required");
        return false;
      } else {
        setDoc(e.target.files[0]);
        setDocErr("");
      }
      if (!e.target.files[0].name.match("pdf")) {
        setDocErr("Only pdf supported");
        return false;
      }
    }
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>Legality</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-5 text-center">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center"></div>
                  </div>
                  <CKEditor
                    editor={ClassicEditor}
                    data={name}
                    config={{
                      toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
                      heading: {
                          options: [
                              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                              { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                          ]
                      }
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData(name);
                      setName(data);
                    }}
                  />
                  <Form.Group className="mb-3">
                    <div className="row">
                      <div className="col-md-4">
                        <img
                          style={{ width: "100px", marginBottom: "15px" }}
                          src={doc}
                          className="img-fluid"
                          alt=""
                        />
                        <Form.Control
                          type="file"
                          name="document"
                          onChange={imageChangeHandler}
                        />
                        {docErr && (
                          <span style={{ color: "red" }}>{docErr}</span>
                        )}
                      </div>
                      <div className="col-md-2 mt-4">
                        <a
                          href={
                            baseUrls +
                            "/static/legalitypdf/" +
                            record[0]?.image
                          }
                          title="View"
                        >
                          <button type="button" className="btn btn-primary">
                            View Document
                          </button>
                        </a>
                      </div>
                    </div>
                  </Form.Group>

                  <button
                    onClick={onSubmitHandler}
                    type="button"
                    class="btn btn-primary ml-5 mt-4 "
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
