import React, { useState, useEffect } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import configs from "../Constant/Config";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import moment from "moment";
import {
    getTournament,
    // insertMatch
  } from "../services/contest";
function Competitions() {
    const [record, setRecord] = useState([]);
   
    const columns = [
      // {
      //   key:"is_selected",
      //   text:"Select",
      //   className:"select",
      //   sortable: true,
      //   cell:(record)=>{
      //     return <input type="checkbox" checked={record.is_selected=='0'?"":"checked"} onClick={()=>{insertmatch(record.id,record.is_selected=='0'?'1':'0')}}/>
      //   }
      // },
        {
          key: "id",
          text: "Sr.No.",
          className: "catergory",
          align: "left",
          sortable: true,
          cell: (row, index) => index + 1,
        },
        {
            key: "competitions_key",
            text: "key",
            className: "Name",
            align: "left",
            sortable: true,
          },
        {
          key: "competitions_name",
          text: "Name",
          className: "Name",
          align: "left",
          sortable: true,
        },
        {
            key: "competitions_short_name",
            text: "Short Name",
            className: "Name",
            align: "left",
            sortable: true,
          },
          {
            key: "competitions_format",
            text: "Format",
            className: "Name",
            align: "left",
            sortable: true,
          },
          {
            key: "competitions_start_date",
            text: "Start Date",
            className: "Name",
            align: "left",
            sortable: true,
            cell:(res)=>{
              return (
                <>{moment(res.competitions_start_date).format("DD/MM/YYYY, hh:mm:ss A")}</>
              )
            }
          },
    
       
    
       
    
      ];
      const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Fund Request List",
        no_data_text: "No record found!",
        button: {
          // print: true,
          // csv: true,
        },
        language: {
          filter: "Filter in records...",
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
      };
      const getdata = async () => {
        let result = await getTournament(configs);
        console.log("resuklt", result)
        if (result.status) {
          setRecord(result.data);
        }
      };
      useEffect(() => {
        getdata();
      }, []);
      // const insertmatch=async(id,val)=>{
      //   const data={
      //     id:id,
      //     val:val
      //   }
      //   let result = await insertMatch(data, configs);
      //   if(result.status){
      //     getdata();
      //   }
      // }
  return (
    <>
      <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Competitions</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer">
                 
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                   
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Competitions
