import React, { useState,useEffect } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import configs from "../Constant/Config";
import { confirmAlert } from "react-confirm-alert";
// import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {addRank, deleteRank, getRank,getRankById,updateRank} from '../services/rankCategories'
import {inputFieldValidation} from "../Validations/validation";
import { Link } from "react-router-dom";
export const Rankcategories = () => {
  const handleClose = () => {
    setName("");
    setWinner("");
    setFrom("");
    setTo("");
    setPrizePercentage("");
    setShow(false);
    setEditShow(false)
  };
  const [name, setName] = useState("");
  const [winner, setWinner] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [prizePercentage, setPrizePercentage] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [winnerErr, setWinnerErr] = useState("");
  const [fromErr, setFromErr] = useState("");
  const [toErr, setToErr] = useState("");
  const [prizePercentageErr, setPrizePercentageErr] = useState("");
  const [show, setShow] = useState(false);
  const [record, setRecord] = useState([])
  const [editShow, setEditShow] = useState(false);
  const [id, setId] = useState("")
  console.log("record", record)
  const handleShow = (id) => {
    setShow(true);
  };
  const handleEditShow = (id) => {
    getDataById(id);
    setEditShow(true);
    setId(id);
  };
  const columns = [
    {
      key: "sr_no",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "name",
      text: "name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "winner",
      text: "winner",
      className: "winner",
      align: "left",
      sortable: true,
    },

    {
      key: "view",
      text: "Action",
      className: "view",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link to=""
              onClick={() => {
                handleEditShow(record.id);
              }}
              style={{ float: "left", cursor: "pointer" }}
            >
             <span className="mdi mdi-square-edit-outline"></span>
            </Link>
            <Link
              to=""
              onClick={() => Conn(record.id)}
              style={{ marginLeft: "5px" }}
            >
              <span className="mdi mdi-trash-can-outline"></span>
            </Link>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    console.log("configs", configs)
    let result = await getRank(configs);
    console.log(result, "result");
    if (result.status === true) {
      setRecord(result.data);
    }
  };
  const getDataById = async (id) => {
    let data = {
      id,
    };
    let result = await getRankById(data, configs);
    console.log("get data by id", result)
    if(result.status){
      const { name, winner, from_rank,to_rank ,percentage } = result.data[0]
      setName(name);
      setWinner(winner);
      setFrom(from_rank);
      setTo(to_rank);
      setPrizePercentage(percentage)
        }


  };
  const Conn = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deletebyid(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deletebyid = async (id) => {
    let deleteid = {
      id,
    };
    let result = await deleteRank(deleteid, configs);
    if (result.status === false) {
      toast.dismiss();
      toast.error(result.message);
      return false;
    }

    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
      getData();
    }
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    console.log(value, "valuess");
    if (name === "name") {
      setName(value);
      const err = inputFieldValidation(name, value);
      setNameErr(err);
      // return false;
    }
    if (name === "winner") {
      setWinner(value);
      const err = inputFieldValidation(name, value);
      setWinnerErr(err);
      // return false;
    }

    if (name === "from") {
      setFrom(value);
      const err = inputFieldValidation(name, value);
      setFromErr(err);
      // return false;
    }
    if (name === "to") {
      setTo(value);
      const err = inputFieldValidation(name, value);
      setToErr(err);
    }
    if (name === "prizePercentage") {
      setPrizePercentage(value);
      const err = inputFieldValidation(name, value);
      setPrizePercentageErr(err);
    }
  };
  const onSubmit=async(e)=>{
     e.preventDefault()
     let err = "This field is required";
     if (!name) {
       setNameErr(err);
       return false;
     }
     if (!winner) {
      setWinnerErr(err);
      return false;
    }
    if (!from) {
      setFromErr(err);
      return false;
    }
    if (!to) {
      setToErr(err);
      return false;
    }
    if (!prizePercentage) {
      setPrizePercentageErr(err);
      return false;
    }
    const data={
      name,
      winner,
      fromRank:from,
      toRank:to,
      percentage:prizePercentage
    }
    const result= await addRank(data,configs)
    if(result.status){
      toast.dismiss();
      toast.success(result.message);
      getData();
      handleClose();
    }
    else{
      toast.dismiss();
      toast.error(result.data.message);
    }
  }
  const onUpdate=async(e)=>{
    e.preventDefault()
    let err = "This field is required";
    if (!name) {
      setNameErr(err);
      return false;
    }
    if (!winner) {
     setWinnerErr(err);
     return false;
   }
   if (!from) {
     setFromErr(err);
     return false;
   }
   if (!to) {
     setToErr(err);
     return false;
   }
   if (!prizePercentage) {
     setPrizePercentageErr(err);
     return false;
   }
   const data={
     name,
     winner,
     fromRank:from,
     toRank:to,
     percentage:prizePercentage,
     id:id
   }
   const result= await updateRank(data,configs)
   console.log("gvdsahgvhsadfb", result)
   if(result.status===true){
     toast.dismiss();
     toast.success(result.message);
     getData();
     handleClose();
   }
   else{
     toast.dismiss();
     toast.error(result.data.message);
   }
 }

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading"></div>
              <div className="row">
                <div className="col-xxl-5 ">
                  <div className="product-list-outer">
                    <div className="add-product-btn ">
                      <button
                        onClick={() => handleShow()}
                        className="btn btn-primary"
                      >
                        Create Rank
                      </button>

                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton><Modal.Title
                          id="contained-modal-title-vcenter"
                          style={{ color: "black" }}
                        >
                          Add Rank Details
                        </Modal.Title></Modal.Header>
                        <Modal.Body>
                          <Row>
                            <Col xs={12} md={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label> Name </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  value={name}
                                  placeholder="Enter Name `"
                                  autoFocus
                                  onChange={handlechange}
                                />
                                  <span style={{ color: "red" }}>
                              {nameErr}
                            </span>
                              </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Winner</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="winner"
                                  placeholder="Enter Winner"
                                  value={winner}
                                  autoFocus
                                  onChange={handlechange}
                                />
                                  <span style={{ color: "red" }}>
                              {winnerErr}
                            </span>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Modal.Title style={{ color: "black" }}>
                              Prize Breakup
                            </Modal.Title>
                            <Col xs={6} md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label> From </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="from"
                                  value={from}
                                  autoFocus
                                  onChange={handlechange}
                                />
                                  <span style={{ color: "red" }}>
                              {fromErr}
                            </span>
                              </Form.Group>
                            </Col>
                            <Col xs={6} md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>To</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="to"
                                  value={to}
                                  autoFocus
                                  onChange={handlechange}
                                />
                                  <span style={{ color: "red" }}>
                              {toErr}
                            </span>
                              </Form.Group>
                            </Col>
                            <Col xs={6} md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Prize Percentage</Form.Label>
                                <Form.Control
                                  type="number"
                                  min={1}
                                  name="prizePercentage"
                                  value={prizePercentage}
                                  autoFocus
                                  onChange={handlechange}
                                />
                                  <span style={{ color: "red" }}>
                              {prizePercentageErr}
                            </span>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={onSubmit}>Save</Button>
                        </Modal.Footer>
                      </Modal>
                      <Modal show={editShow} onHide={handleClose}>
                        <Modal.Header closeButton> <Modal.Title
                          id="contained-modal-title-vcenter"
                          style={{ color: "black" }}
                        >
                          Add Rank Details
                        </Modal.Title></Modal.Header>
                       
                        <Modal.Body>
                          <Row>
                            <Col xs={12} md={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label> Name </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  value={name}
                                  placeholder="Enter Name `"
                                  autoFocus
                                  onChange={handlechange}
                                />
                                  <span style={{ color: "red" }}>
                              {nameErr}
                            </span>
                              </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Winner</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="winner"
                                  placeholder="Enter Winner"
                                  value={winner}
                                  autoFocus
                                  onChange={handlechange}
                                />
                                  <span style={{ color: "red" }}>
                              {winnerErr}
                            </span>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Modal.Title style={{ color: "black" }}>
                              Prize Breakup
                            </Modal.Title>
                            <Col xs={6} md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label> From </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="from"
                                  value={from}
                                  autoFocus
                                  onChange={handlechange}
                                />
                                  <span style={{ color: "red" }}>
                              {fromErr}
                            </span>
                              </Form.Group>
                            </Col>
                            <Col xs={6} md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>To</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="to"
                                  value={to}
                                  autoFocus
                                  onChange={handlechange}
                                />
                                  <span style={{ color: "red" }}>
                              {toErr}
                            </span>
                              </Form.Group>
                            </Col>
                            <Col xs={6} md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Prize Percentage</Form.Label>
                                <Form.Control
                                  type="number"
                                  min={1}
                                  name="prizePercentage"
                                  value={prizePercentage}
                                  autoFocus
                                  onChange={handlechange}
                                />
                                  <span style={{ color: "red" }}>
                              {prizePercentageErr}
                            </span>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={onUpdate}>Save</Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <ReactDatatable
                      config={config}
                      columns={columns}
                      records={record}
                      onPageChange={pageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
