import React, { useState, useEffect,useReducer } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { confirmAlert } from "react-confirm-alert";
import Card from "react-bootstrap/Card";
import configs from "../Constant/Config";
import { Link } from "react-router-dom";
import {inputFieldValidation} from "../Validations/validation";
import {
  getsupportdata,
  addsupportdata,
  getsupportdatabyid,
  supportdatadelete,
  supportdataupdates,
} from "../services/support";

export const Helpsupport = () => {
  const [record, setRecord] = useState([]);
  // ============= useReducer===============//
  const reducer = (action,state)=>{

    if(action.type==="Add_Item"){
      return state+1
    }
    else if(action.type==="Remove_Item"){
      return state-1

    }

  }

  const HandleOnclick = ()=>{
    dispatch({type:"Add_Item"})
  }
  const[state,dispatch] = useReducer(reducer,{})


  // ============= useReducer===============//

  const handleClose = () => {
    setQuestion("");
    setQuestionerr("");
    setAnswer("");
    setAnswererr("");
    setShow(false);
    setupdateShow(false);
  };
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [Id, setId] = useState([]);
  const [questionerr, setQuestionerr] = useState("");
  const [answererr, setAnswererr] = useState("");
  const [show, setShow] = useState(false);
  const [updateshow, setupdateShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const handleEditShow = (id) => {
    setId(id);
    databyid(id);
    setupdateShow(true);
  };

  const onSubmit = async () => {
    if (!question) {
      setQuestionerr("This field is required");
      return false;
    }
    if(question.match(/^\s/)){
      setQuestionerr("This field is required");
      return false;
    }
    if (!answer) {
      setAnswererr("This field is required");
      return false;
    }
    if(answer.match(/^\s/)){
      setAnswererr("This field is required");
      return false;
    }
    let data = {
    question,
      answer,
    };
    let res = await addsupportdata(data, configs);
    if (res.status === true) {
      toast.dismiss();
      toast.success(res.message);
    }
    getSupport();
    handleClose();
  };

  const databyid = async (id) => {
    let data = {
      id,
    };
    let resp = await getsupportdatabyid(data, configs);
    const { answer, question } = resp.data[0];
    setAnswer(answer);
    setQuestion(question);
  };
  const onUpdate = async () => {
    if (!question) {
      setQuestionerr("This field is required");
      return false;
    }
    if(question.match(/^\s/)){
      setQuestionerr("This field is required");
      return false;
    }
    if (!answer) {
      setAnswererr("This field is required");
      return false;
    }
    if(answer.match(/^\s/)){
      setAnswererr("This field is required");
      return false;
    }
    let data = {
      id: Id,
      question,
      answer,
    };
    let res = await supportdataupdates(data, configs);
    console.log(res, "res");
    if (res.status === true) {
      toast.dismiss();
      toast.success(res.message);
    }
    getSupport();
    handleClose();
  };
  const deleteSuppport = async (id) => {
    let data = {
      id,
    };
    let result = await supportdatadelete(data, configs);
    if (result.status === false) {
      toast.dismiss();
      toast.error(result.message);
      return;
    }
    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
    }
    getSupport();
  };

  const Conn = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSuppport(id),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };
  const checkCall = () => {
    return false;
  };
  const getSupport = async () => {
    let result = await getsupportdata(configs);
    setRecord(result.data);
  };
  useEffect(() => {
    getSupport();
  }, []);

  const _renderData = (dataList) => {
    return dataList.map((values, index) => {
      return (
	   <div className="mb-3">
        <Card key={values.id}>
              <Card.Header as="h5">
			   <div className="faq_btn">
                <Link
                  to =""
                  onClick={() => {
                    handleEditShow(values.id);
                  }}
                  
                >
                 <span className="mdi mdi-square-edit-outline"></span>
                </Link>
                <Link
                  to=""
                  onClick={() => Conn(values.id)}
                  style={{
                    marginLeft: "8px",
                  }}
                >
                  <span className="mdi mdi-trash-can-outline"></span>
                </Link>
              </div>
                Question {index + 1}: {values.question}
              </Card.Header>
              <Card.Body>
                <Card.Text>Answer : {values.answer}</Card.Text>
              </Card.Body>
          
        </Card>
        </div>
      );
    });
  };

  const handlechange = (e) => {
    let { name, value } = e.target;

    if (name === "question") {
      setQuestion(value);
      const err = inputFieldValidation(name, value);
      setQuestionerr(err);
    }
   
    if (name === "answer") {
      setAnswer(value);
      const err = inputFieldValidation(name, value);
      setAnswererr(err);
    }
  };
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              
              <div className="row">
			  <div className="col-md-6">
			  <div className="section-heading">
                <h2>
                  <b>Help And Support</b>
                </h2>
              </div>
              </div>
                <div className="col-md-6 text-center">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center mb-3">
                      <button
                        onClick={() => handleShow()}
                        className="btn btn-primary"
                      >
                        Add Help And Support
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="setting-accordion-outer">
                <div className="row">
                  {record.length !== 0 ? (
                    _renderData(record || [])
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <h5>Data Not Found</h5>
                      </td>
                    </tr>
                  )}
                </div>
              </div>
              <div className="main-content">
                <div className="page-content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-xxl-5 text-center">
                        <div className="product-list-outer">
                          <div className="add-product-btn text-center">
                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton><Modal.Title style={{ color: "black" }}>
                                Add Help And Support
                              </Modal.Title></Modal.Header>
                              
                              <Modal.Body>
                                <Form>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Group className="mb-3">
                                      <Form.Label>Question </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="question"
                                        value={question}
                                        placeholder="Enter Name"
                                        onChange={handlechange}
                                      />
                                    </Form.Group>
                                    <span style={{ color: "red" }}>
                                      {questionerr}
                                    </span>
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>Answer </Form.Label>

                                    <textarea
									class="form-control"
                                      value={answer}
                                      onChange={handlechange}
                                      name="answer"
                                      rows="8"
                                      cols="51.9"
                                    ></textarea>
                                    <span style={{ color: "red" }}>
                                      {answererr}
                                    </span>
                                  </Form.Group>
                                </Form>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button variant="primary" onClick={onSubmit}>
                                  Save
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <Modal show={updateshow} onHide={handleClose}>
                              <Modal.Header closeButton> <Modal.Title style={{ color: "black" }}>
                                Update Help And Support
                              </Modal.Title></Modal.Header>
                             
                              <Modal.Body>
                                <Form>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Group className="mb-3">
                                      <Form.Label>Question </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="question"
                                        value={question}
                                        placeholder="Enter Name"
                                        onChange={handlechange}
                                      />
                                    </Form.Group>
                                    <span style={{ color: "red" }}>
                                      {questionerr}
                                    </span>
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>Answer </Form.Label>

                                    <textarea
									class="form-control"
                                      type="text"
                                      value={answer}
                                      onChange={handlechange}
                                      name="answer"
                                     
                                      cols="51.9"
                                    ></textarea>
                                    <span style={{ color: "red" }}>
                                      {answererr}
                                    </span>
                                  </Form.Group>
                                </Form>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button variant="primary" onClick={onUpdate}>
                                  Save
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
