import React, { useState, useEffect, Fragment } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import configs from "../Constant/Config";
import {inputFieldValidation} from "../Validations/validation";
import {
  getteam,
} from "../services/team";
import {
  
  addplayer,
  getplayer,
  playerdelete,
  playerbyid,
  playerupdates,
} from "../services/player";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-bootstrap/Modal";
// import { baseUrls } from "./BaseUrl";
import { baseUrls } from "../Constant/BaseUrl";
// import { Link } from "react-router-dom";
export const Player = () => {
  const [record, setRecord] = useState([]);
  const [teamdata, setTeamdata] = useState([]);
  const [userid, setUserid] = useState([]);

  const handleClose = () => {
    setTeamname("");
    setPlayername("");
    setPlayerphoto("");
    setPlayerphotos(false);
    setPlayerphotosadd("");
    setPlayerrolls();
    setteamnameerr("");
    setplayerphotoerr("");
    setPlayerrollserr("");
    setPlayernameerr("");
    setShow(false);
    seteditShow(false);
  };

  const [teamname, setTeamname] = useState("");
  const [playername, setPlayername] = useState("");
  const [playerphoto, setPlayerphoto] = useState("");
  const [playerphotos, setPlayerphotos] = useState("");
  const [playerphotosadd, setPlayerphotosadd] = useState("");
  const [playerrolls, setPlayerrolls] = useState("");
  const [teamnameerr, setteamnameerr] = useState(false);
  const [playernameerr, setPlayernameerr] = useState(false);
  const [playerphotoerr, setplayerphotoerr] = useState(false);
  const [playerrollserr, setPlayerrollserr] = useState(false);
  const [show, setShow] = useState(false);

  const [editshow, seteditShow] = useState(false);
  const handleShow = (id) => {
    setShow(true);
  };
  // const handleeditShow = (id) => {
  //   dataById(id);
  //   seteditShow(true);
  //   setUserid(id);
  // };

  const columns = [
    {
      key: "Sr.No.",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "team_name",
      text: " Team Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "player_name",
      text: " Player Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "playerPhoto",
      text: " Player Photo",
      className: "Name",
      align: "left",
      sortable: true,
      cell: (record) => {
      
        return (
          <Fragment>
            <img
              style={{ height: "50px" }}
              src={baseUrls + "/static/playerPic/" + record.playerPhoto}
              alt=""
            />
          </Fragment>
        );
      },
    },
    {
      key: "player_role",
      text: " Player Role",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "tournament_name",
      text: "Tournament Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "date",
      text: "Date & Time",
      className: "Tagline",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("lll")}</>;
      },
    },

    // {
    //   key: "view",
    //   text: "Action",
    //   className: "view",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return (
    //       <Fragment>
    //         <Link to=""
    //           onClick={() => {
    //             handleeditShow(record.id);
    //           }}
    //           style={{ float: "left", cursor: "pointer" }}
    //         >
    //           <img
    //             src="assets/images/edit-icon.png"
    //             alt=""
    //             title="Edit"
    //             className="img-fluid"
    //           />
    //         </Link>
    //         <Link
    //           to=""
    //           onClick={() => Conn(record.id)}
    //           style={{ marginLeft: "5px" }}
    //         >
    //           <img
    //             src="assets/images/delete-icon.png"
    //             alt=""
    //             title="Delete"
    //             className="img-fluid"
    //           />
    //         </Link>
    //       </Fragment>
    //     );
    //   },
    // },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    
  };

  // ========== api integration==============//

  // =========get api for team name=============//
  const getteamdata = async () => {
    let result = await getteam(configs);
    if (result.status === true) {
      setTeamdata(result.data);
    }
  };
  // =========get api for team name=============//

  const getplayerdata = async () => {
    let result = await getplayer(configs);

    if (result.status === true) {
      setRecord(result.data);
    }
  };

  const onsubmit = async (e) => {
    let err = "This field is required";
    if (!teamname) {
      setteamnameerr(err);
      return false;
    }
    if (!playername) {
      setPlayernameerr(err);
      return false;
    }
    if (!playerphoto) {
      if (!playerphotosadd) {
        setplayerphotoerr(err);
        return false;
      }
    }
    if (!playerrolls) {
      setPlayerrollserr(err);
      return false;
    }
    const formdata = new FormData();
    formdata.append("teamName", teamname);
    formdata.append("playerName", playername);
    formdata.append("playerphoto", playerphoto);
    formdata.append("playerRoll", playerrolls);

    let result = await addplayer(formdata, configs);
    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
    }
    getplayerdata();
    handleClose();
  };


  const dataById = async (id) => {
    let datas = {
      id,
    };
    let results = await playerbyid(datas, configs);
    const { teamName, playerName, playerPhoto, playerRoll } = results.data[0];
    setTeamname(teamName);

    setPlayername(playerName);
    setPlayerphotos(baseUrls + "/static/playerPic/" + playerPhoto);
    setPlayerrolls(playerRoll);
  };

  // ========= delate player =============//

  const deletebyid = async (id) => {
    let deleteid = {
      id,
    };
    let result = await playerdelete(deleteid, configs);
    if (result.status === false) {
      toast.dismiss();
      toast.error(result.message);
      return false;
    }

    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
      getplayerdata();
    }
  };

  const checkCall = () => {
    return false;
  };
  const Conn = (getMethodDeleteId) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deletebyid(getMethodDeleteId),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };
  // ============update player api ===========/
  const onupdateplayer = async () => {
    let err = "This field is required";
    if (!teamname) {
      setteamnameerr(err);
      return false;
    }
    if (!playername) {
      setPlayernameerr(err);
      return false;
    }
    if (!playerphoto) {
      if (!playerphotos) {
        setplayerphotoerr(err);
        return false;
      }
    }
    if (!playerrolls) {
      setPlayerrollserr(err);
      return false;
    }
    // const formdata = new FormData();
    // formdata.append("id", userid);
    // formdata.append("teamName", teamname);
    // formdata.append("playerName", playername);
    // formdata.append("playerpic", playerphoto);
    // formdata.append("playerRoll", playerrolls);
    const formdata = new FormData();
    const player = {
      id: userid,
      teamName: teamname,
      playerName: playername,
      playerpic: playerphoto,
      playerRoll: playerrolls,
    };
    for (const [key, value] of Object.entries(player)) {
      formdata.append(key, value);
    }
    let result = await playerupdates(formdata, configs);
    if (result.status === true) {
      toast.dismiss();
      toast.success(result.meassge);
    }
    getplayerdata();
    handleClose();
  };

  useEffect(() => {
    getplayerdata();
    getteamdata();
  }, []);

  // ========handlechange for add model==========
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "teamname") {
      setTeamname(value);
      const err = inputFieldValidation(name, value);
      setteamnameerr(err);
    }
    if (name === "playername") {
      setPlayername(value);
      const err = inputFieldValidation(name, value);
      setPlayernameerr(err);
    }

    if (name === "playerphoto") {
      let image = e.target.files[0];
      if (value === "" || value === undefined) {
        setPlayerphoto(image);
        setPlayerphotosadd("");
        setplayerphotoerr("This field is required");
        setPlayerphotos("");
        return false;
      } else {
        setPlayerphotosadd(URL.createObjectURL(image));
        setPlayerphoto(e.target.files[0]);
        setPlayerphotos(URL.createObjectURL(image));
        setplayerphotoerr("");
      }
    }
    if (name === "playerroll") {
      setPlayerrolls(value);
      const err = inputFieldValidation(name, value);
      setPlayerrollserr(err);
    }
  };
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Player list</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer">
                  <div className="add-product-btn text-center">
                    {/* <button
                      onClick={() => handleShow()}
                      className="btn btn-primary"
                    >
                      Add Player
                    </button> */}

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Title style={{ color: "black" }}>
                        Add Player Details
                      </Modal.Title>
                      <Modal.Body>
                        <Form>
                          <Form.Group className="mb-3">
                            <Form.Label>Team Name </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="teamname"
                              onChange={handlechange}
                            >
                              <option selected disabled>
                                Please select
                              </option>
                              {teamdata.map((item, index) => {
                                return (
                                  <option value={item.id}>{item.name}</option>
                                );
                              })}
                            </Form.Select>
                            <span style={{ color: "red" }}>{teamnameerr}</span>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Player Name </Form.Label>
                            <Form.Control
                              type="text"
                              name="playername"
                              value={playername}
                              placeholder="Enter Name"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>
                              {playernameerr}
                            </span>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <div className="mylabels">
                              {" "}
                              <Form.Label>Player Photo</Form.Label>
                            </div>

                            <img
                              style={{ width: "100px", marginBottom: "15px" }}
                              src={playerphotosadd}
                              className="img-fluid"
                              alt=""
                            />
                            <Form.Control
                              type="file"
                              name="playerphoto"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>
                              {playerphotoerr}
                            </span>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Player Roll </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="playerroll"
                              onChange={handlechange}
                            >
                              <option selected disabled>
                                Please select
                              </option>
                              <option value="Bowling">Bowling</option>
                              <option value="Batsman">Batsman</option>
                              <option value="Wicket Keeper">
                                Wicket Keeper
                              </option>
                              <option value="All Rounder">All Rounder</option>
                              <option value="Extra">Extra</option>
                            </Form.Select>
                            <span style={{ color: "red" }}>
                              {playerrollserr}
                            </span>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="secondary" onClick={onsubmit}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Modal show={editshow} onHide={handleClose}>
                      <Modal.Header closeButton></Modal.Header>
                      <div className="titlesss">
                        <Modal.Title>
                          <h3>Update Player Details</h3>
                        </Modal.Title>
                      </div>
                      <Modal.Body>
                        <Form>
                          <Form.Group className="mb-3">
                            <Form.Label>Team Name </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="teamname"
                              onChange={handlechange}
                              value={teamname}
                            >
                              <option selected disabled>
                                Please select
                              </option>
                              {teamdata.map((item, index) => {
                                console.log(item, "item");
                                return (
                                  <option value={item.id}>{item.name}</option>
                                );
                              })}
                            </Form.Select>
                            <span style={{ color: "red" }}>{teamnameerr}</span>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Player Name </Form.Label>
                            <Form.Control
                              type="text"
                              name="playername"
                              value={playername}
                              placeholder="Enter Name"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>
                              {playernameerr}
                            </span>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <div className="mylabels">
                              {" "}
                              <Form.Label>Player Photo</Form.Label>
                            </div>
                            <img
                              style={{ width: "100px", marginBottom: "15px" }}
                              src={playerphotos}
                              className="img-fluid"
                              alt=""
                            />
                            <Form.Control
                              type="file"
                              name="playerphoto"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>
                              {playerphotoerr}
                            </span>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Player Roll </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="playerroll"
                              onChange={handlechange}
                              value={playerrolls}
                            >
                              <option value="Bowling">Bowler</option>
                              <option value="Batsman">Batsman</option>
                              <option value="Wicket Keeper">
                                Wicket Keeper
                              </option>
                              <option value="All Rounder">All Rounder</option>
                              <option value="Extra">Extra</option>
                            </Form.Select>
                            <span style={{ color: "red" }}>
                              {playerrollserr}
                            </span>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="secondary" onClick={onupdateplayer}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
