import React, { useState,useEffect } from "react";
import moment from "moment";
import ReactDatatable from "@mkikets/react-datatable";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import { withdrawrequest,updateRequest } from "../services/transactionDetail";
import configs from "../Constant/Config";
import "react-confirm-alert/src/react-confirm-alert.css";

export const Managewithdrawrequest = () => {
  const [record,setRecord] = useState([]);

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "user_id",
      text: "User Id",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },

    {
      key: "request_id",
      text: "Request Id",
      className: "Request Id",
      align: "left",
      sortable: true,
     
    },

    {
      key: "amount",
      text: "Amount",
      className: "Amount",
      align: "left",
      sortable: true,
    },
    {
      key: "request_date",
      text: " Request Date & Time",
      className: "Date & Time",
      align: "left",
      sortable: true,
      cell:(res)=>{
        return (
          <span>{moment(res.request_date).format("DD-MM-YYYY , hh:mm:ss A")}</span>
        )
      }
    },
    {
      key: "Status",
      text: "Status",
      className: "Status",
      align: "left",
      sortable: true,
      cell:(record)=>{
         return (
          record.status=='0'?"Pending":record.status=='1'?"Approved":"Rejected"
         )
      }
    },

    {
      key: "Acction",
      text: "Action",
      className: "Status",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              onClick={() => {
                approved(record.id,'1');
              }}
              type="button"
              class="btn btn-success"
              disabled={record.status=='0'?"":true}
            >
              Approve
            </button>{" "}
            <button
              onClick={() => {
                decline(record.id,'2');
              }}
              type="button"
              class="btn btn-danger"
              disabled={record.status=='0'?"":true}
            >
              Decline
            </button>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  const approvePayment = async(id,value) => {
    let data={
      id:id,
      type:value
    }
    let result=await updateRequest(data,configs)
    console.log("approve result",result)
    if(result.status){
      toast.dismiss();
      toast.success(result.message);
      getWithdraw()
    }
  };
  const declinePayment = async(id,value) => {
    let data={
      id:id,
      type:value
    }
    let result=await updateRequest(data,configs)
    console.log("approve result",result)
    if(result.status){
      toast.dismiss();
      toast.success(result.message);
      getWithdraw()
    }
  };
  const checkCall = () => {
    return false;
  };
  const approved = (id,approve) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Do you want to approve this payment ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => approvePayment(id,approve),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const decline = (id,decline) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Do you want to decline this payment ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => declinePayment(id,decline),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  useEffect(() => {
    getWithdraw()
  }, [])
  const getWithdraw = async () => {
    let data = await withdrawrequest(configs);
    console.log("datat=========",data)
    if(data.status){
    setRecord(data.data);
    }
    
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Payment Withdraw Request</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer">
                  <div className="add-product-btn text-center"></div>
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
