import React, { useState, useEffect, Fragment } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import configs from "../Constant/Config";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import {
  getContestCombine,
  getcategory,
  addcontest,
  getcontestdatabyid,
  deleteContest,
  contestpointupdates,
} from "../services/contest";
import "react-confirm-alert/src/react-confirm-alert.css";

const Contesttemplate = () => {
  const [record, setRecord] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const initialValue = {
    rankfrom: "",
    torank: "",
    totalpercentage: "",
    prize: "",
    totalprizes: "",
  };
  const [formValueserr, setFormValueserr] = useState([{}]);
  const [updateformValues, setupdateformValues] = useState([{ initialValue }]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [contesttype, setcontesttype] = useState("");
  const [totalprize, settotalprize] = useState("");
  const [entryfees, setentryfees] = useState("");
  const [adminPercentage, setAdminpercentage] = useState("0");
  const [maxteamperuser, setmaxteamperuser] = useState(2);
  const [autocreate, setautocreate] = useState("yes");
  const [addSpots, setAddSpots] = useState("10")
  const [contestcategory, setcontestcategory] = useState("");
  const [bonus, setbonus] = useState("");
  const [dynamiccontest, setdynamiccontest] = useState("");

  const [totalSpots, setTotalSpots] = useState("");
  const [firstPrize, setFirstPrize] = useState("");
  // const [s_time, sets_time] = useState("");
  // const [e_time, sete_time] = useState("");
  // const [s_date, sets_date] = useState("");
  // const [e_date, sete_date] = useState("");

  const [categorydata, setCategorydata] = useState([]);
  const [show, setShow] = useState(false);

  const [contestid, setcontestid] = useState([]);
  const [updateshow, setupdateshow] = useState(false);

  const [nameerr, setNameerr] = useState(false);
  const [descriptionerr, setDescriptionerr] = useState(false);
  const [contesttypeerr, setcontesttypeerr] = useState(false);
  const [totalprizeerr, settotalprizeerr] = useState(false);
  const [entryfeeserr, setentryfeeserr] = useState(false);
  const [adminPercentageErr, setAdminPercentageErr] = useState(false);
  const [maxteamsperusererr, setmaxteamsperusererr] = useState(false);
  const [autocreateerr, setautocreateerr] = useState(false);
  const [contestcategoryerr, setcontestcategoryerr] = useState(false);
  const [bonuserr, setbonuserr] = useState(false);
  const [dynamiccontesterr, setdynamiccontesterr] = useState(false);
  const [totalSpotsErr, setTotalSoptsErr] = useState(false);
  const [firstPrizeErr, setFirstPrizeErr] = useState(false);
  // const [s_timeErr, sets_timeErr] = useState(false);
  // const [e_timeErr, sete_timeErr] = useState(false);
  // const [s_dateErr, sets_dateErr] = useState(false);
  // const [e_dateErr, sete_dateErr] = useState(false);
  const [formValues, setFormValues] = useState([{ initialValue }]);

  // ============= for more add field ==================//

  let handleChangemorefield = (i, e) => {
    let newFormValues = [...formValues];
    let newFormValuesErr = [...formValueserr];
    newFormValues[i][e.target.name] = e.target.value;

    setFormValues(newFormValues);

    if (e.target.name === "rankfrom") {
      if (
        !newFormValues[i].rankfrom ||
        newFormValues[i].rankfrom === undefined
      ) {
        newFormValuesErr[i][e.target.name] = "This field is required";
        setFormValues(newFormValues);

        setFormValueserr(newFormValuesErr);
        return false;
      } else {
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setFormValues(newFormValues);
        // if (newFormValues[i].prize){
        //   let rankfrom=newFormValues[i].rankfrom
        //   let rankto=newFormValues[i].torank

        //   newFormValues[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValues[i].prize
        //     setFormValues(newFormValues);
        // }
        
        if(newFormValues[i].rankfrom&& newFormValues[0].rankfrom!="1"){
          console.log("rank===",newFormValues[0].rankfrom)
          newFormValuesErr[0][e.target.name] = "Rank will be started 1";
          setFormValueserr(newFormValuesErr);
         }
       
        
        if(newFormValues[i].torank && newFormValues[i].rankfrom>newFormValues[i].torank){
          console.log("Please enter the valid rank")
          newFormValuesErr[i]["torank"] = "Please enter the valid rank";
          setFormValueserr(newFormValuesErr);
         }
    
         if(newFormValues[i-1].torank && newFormValues[i].rankfrom==newFormValues[i-1].torank ){
     

            newFormValuesErr[i][e.target.name] = "Please enter a valid rank";
            setFormValueserr(newFormValuesErr);
          
         }
         if(newFormValues[i-1].torank &&  parseInt(newFormValues[i].rankfrom)!==parseInt((newFormValues[i-1].torank))+1){
          newFormValuesErr[i][e.target.name] = "Please enter a valid rank";
          setFormValueserr(newFormValuesErr);
         }
        
        if (
          newFormValues[i].torank &&
          newFormValues[i].totalpercentage &&
          totalprize
        ) {
          if (newFormValues[i].totalpercentage == 0) {
            console.log("call percentage3");
            newFormValues[i]["prize"] = "0";
            newFormValues[i]["totalprizes"] = "0";
          } else {
            if (
              newFormValues[i].torank &&
              newFormValues[i].totalpercentage &&
              totalprize
            ) {
              let rankfrom = newFormValues[i].rankfrom;
              let rankto = newFormValues[i].torank;
              let per = newFormValues[i].totalpercentage;
              let totalprizes =
                parseFloat(totalprize) * (parseFloat(per) / 100);
              let totalrank = parseInt(rankto) - parseInt(rankfrom) + 1;
              let perPrize = parseFloat(totalprizes) / parseInt(totalrank);
              // newFormValues[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValues[i].prize
              newFormValues[i]["prize"] = perPrize;
              newFormValues[i]["totalprizes"] = totalprizes;
              setFormValues(newFormValues);
            }
          }
        }
      }
    }

    if (e.target.name === "torank") {
      newFormValuesErr[i][e.target.name] = "";
      setFormValueserr(newFormValuesErr);
      setFormValues(newFormValues);
      // if (!newFormValues[i].torank || newFormValues[i].torank === undefined) {
      //   newFormValuesErr[i][e.target.name] = "This field is required";

      //   setFormValues(newFormValues);
      //   setFormValueserr(newFormValuesErr);
      //   return false;
      // } else {
      //   newFormValuesErr[i][e.target.name] = "";
      //   setFormValueserr(newFormValuesErr);
      //   setFormValues(newFormValues);
      // }
      // if (newFormValues[i].prize){
      //   let rankfrom=newFormValues[i].rankfrom
      //   let rankto=newFormValues[i].torank

      //   newFormValues[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValues[i].prize
      //     setFormValues(newFormValues);
      // }

       if(newFormValues[i].rankfrom && parseInt(newFormValues[i].rankfrom)>newFormValues[i].torank){
        console.log("Please enter the valid rank")
        newFormValuesErr[i][e.target.name] = "Please enter the valid rank";
        setFormValueserr(newFormValuesErr);
       }

      if (
        newFormValues[i].rankfrom &&
        newFormValues[i].totalpercentage &&
        totalprize
      ) {
        if (newFormValues[i].totalpercentage == 0) {
          console.log("call percentage3");
          newFormValues[i]["prize"] = "0";
          newFormValues[i]["totalprizes"] = "0";
        } else {
          if (
            newFormValues[i].rankfrom &&
            newFormValues[i].totalpercentage &&
            totalprize
          ) {
            let rankfrom = newFormValues[i].rankfrom;
            let rankto = newFormValues[i].torank;
            let per = newFormValues[i].totalpercentage;
            let totalprizes = parseFloat(totalprize) * (parseFloat(per) / 100);
            let totalrank = parseInt(rankto) - parseInt(rankfrom) + 1;
            let perPrize = parseFloat(totalprizes) / parseInt(totalrank);
            // newFormValues[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValues[i].prize
            newFormValues[i]["prize"] = perPrize;
            newFormValues[i]["totalprizes"] = totalprizes;
            setFormValues(newFormValues);
          }
        }
      }
    }

    if (e.target.name === "totalpercentage") {
      console.log("call percentage");
      if (
        !newFormValues[i].totalpercentage ||
        newFormValues[i].totalpercentage === undefined
      ) {
        newFormValuesErr[i][e.target.name] = "This field is required";

        setFormValues(newFormValues);
        setFormValueserr(newFormValuesErr);
        return false;
      } else {
        console.log("call percentage2");
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setFormValues(newFormValues);
        if (newFormValues[i].totalpercentage == 0) {
          console.log("call percentage3");
          newFormValues[i]["prize"] = "0";
          newFormValues[i]["totalprizes"] = "0";
        } else {
          if (
            newFormValues[i].rankfrom &&
            newFormValues[i].torank &&
            totalprize
          ) {
            let rankfrom = newFormValues[i].rankfrom;
            let rankto = newFormValues[i].torank;
            let per = newFormValues[i].totalpercentage;
            let totalprizes = parseFloat(totalprize) * (parseFloat(per) / 100);
            let totalrank = parseInt(rankto) - parseInt(rankfrom) + 1;
            let perPrize = parseFloat(totalprizes) / parseInt(totalrank);
            console.log("peruserprize----", perPrize);

            // newFormValues[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValues[i].prize
            newFormValues[i]["prize"] = perPrize;
            newFormValues[i]["totalprizes"] = totalprizes;
            setFormValues(newFormValues);
          }
        }
      }
    }

    // if (e.target.name === "prize") {
    //   if (!newFormValues[i].prize || newFormValues[i].prize === undefined) {
    //     newFormValuesErr[i][e.target.name] = "This field is required";

    //     setFormValues(newFormValues);
    //     setFormValueserr(newFormValuesErr);
    //     return false;
    //   } else {
    //     newFormValuesErr[i][e.target.name] = "";

    //     setFormValueserr(newFormValuesErr);
    //     setFormValues(newFormValues);
    //    if(newFormValues[i].rankfrom && newFormValues[i].torank){
    //     let rankfrom=newFormValues[i].rankfrom
    //     let rankto=newFormValues[i].torank

    //     newFormValues[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValues[i].prize
    //     setFormValues(newFormValues);
    //    }

    //     // // setFormValues({...formValues, totalprizes:(rankto-rankfrom)+1*newFormValues[i].prize});
    //     // console.log("newfdsfsdf",formValues)
    //   }
    // }

    // if (e.target.name === "totalprizes") {
    //   if (
    //     !newFormValues[i].totalprizes ||
    //     newFormValues[i].totalprizes === undefined
    //   ) {
    //     newFormValuesErr[i][e.target.name] = "This field is required";

    //     setFormValues(newFormValues);
    //     setFormValueserr(newFormValuesErr);
    //     return false;
    //   } else {
    //     newFormValuesErr[i][e.target.name] = "";
    //     setFormValueserr(newFormValuesErr);
    //     setFormValues(newFormValues);

    //         }
    // }
  };

  let addFormFields = () => {
    setFormValues([...formValues, {}]);

    setFormValueserr([...formValueserr, { initialValue }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  // ====================================== update modal add more field ========================//

  let handleChangemoreupdatefield = (e, i) => {
    let newFormValuess = [...updateformValues];
    let newFormValuesErr = [...formValueserr];

    newFormValuess[i][e.target.name] = e.target.value;
    setupdateformValues(newFormValuess);

    if (e.target.name === "rankfrom") {
      if (
        !newFormValuess[i].rankfrom ||
        newFormValuess[i].rankfrom === undefined
      ) {
        newFormValuesErr[i][e.target.name] = "This field is required";
        setupdateformValues(newFormValuess);

        setFormValueserr(newFormValuesErr);
        return false;
      } else {
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setupdateformValues(newFormValuess);
        // if (newFormValuess[i].prize) {
        //   console.log("prize4", newFormValuess[i].prize);
        //   let rankfrom = newFormValuess[i].rankfrom;
        //   let rankto = newFormValuess[i].torank;

        //   newFormValuess[i]["totalprizes"] =
        //     (rankto - rankfrom + 1) * newFormValuess[i].prize;
        //   setupdateformValues(newFormValuess);
        // }
        if(newFormValuess[i].rankfrom && newFormValuess[0].rankfrom!="1"){
          console.log("rank===",newFormValuess[0].rankfrom)
          newFormValuesErr[0][e.target.name] = "Rank will be started 1";
          setFormValueserr(newFormValuesErr);
         }
       
        
        if(newFormValuess[i].torank && parseInt(newFormValuess[i].rankfrom)>newFormValuess[i].torank){
          console.log("Please enter the valid rank")
          newFormValuesErr[i]["torank"] = "Please enter the valid rank";
          setFormValueserr(newFormValuesErr);
         }
    
         if(newFormValuess[i-1].torank && newFormValuess[i].rankfrom==newFormValuess[i-1].torank ){
     

            newFormValuesErr[i][e.target.name] = "Please enter a valid rank";
            setFormValueserr(newFormValuesErr);
          
         }
         if(newFormValuess[i-1].torank &&  parseInt(newFormValuess[i].rankfrom)!==parseInt((newFormValuess[i-1].torank))+1){
          newFormValuesErr[i][e.target.name] = "Please enter a valid rank";
          setFormValueserr(newFormValuesErr);
         }
        if (
          newFormValuess[i].torank &&
          newFormValuess[i].totalpercentage &&
          totalprize
        ) {
          let rankfrom = newFormValuess[i].rankfrom;
          let rankto = newFormValuess[i].torank;
          let per = newFormValuess[i].totalpercentage;
          let totalprizes = parseFloat(totalprize) * (parseFloat(per) / 100);
          let totalrank = parseInt(rankto) - parseInt(rankfrom) + 1;
          let perPrize = parseFloat(totalprizes) / parseInt(totalrank);
          // newFormValues[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValues[i].prize
          newFormValuess[i]["prize"] = perPrize;
          newFormValuess[i]["totalprizes"] = totalprizes;
          setupdateformValues(newFormValuess);
          // setFormValues(newFormValues);
        }
      }
    }

    if (e.target.name === "torank") {
      if (!newFormValuess[i].torank || newFormValuess[i].torank === undefined) {
        newFormValuesErr[i][e.target.name] = "This field is required";

        setupdateformValues(newFormValuess);
        setFormValueserr(newFormValuesErr);
        return false;
      } else {
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setupdateformValues(newFormValuess);
        // if (newFormValuess[i].prize) {
        //   let rankfrom = newFormValuess[i].rankfrom;
        //   let rankto = newFormValuess[i].torank;

        //   newFormValuess[i]["totalprizes"] =
        //     (rankto - rankfrom + 1) * newFormValuess[i].prize;
        //   setupdateformValues(newFormValuess);
        // }
        if(newFormValuess[i].rankfrom && parseInt(newFormValuess[i].rankfrom)>newFormValuess[i].torank){
          console.log("Please enter the valid rank")
          newFormValuesErr[i][e.target.name] = "Please enter the valid rank";
          setFormValueserr(newFormValuesErr);
         }
        if (
          newFormValuess[i].rankfrom &&
          newFormValuess[i].totalpercentage &&
          totalprize
        ) {
          if (newFormValuess[i].totalpercentage == 0) {
            console.log("call percentage3");
            newFormValuess[i]["prize"] = "0";
            newFormValuess[i]["totalprizes"] = "0";
          } else {
            if (
              newFormValuess[i].rankfrom &&
              newFormValuess[i].totalpercentage &&
              totalprize
            ) {
              let rankfrom = newFormValuess[i].rankfrom;
              let rankto = newFormValuess[i].torank;
              let per = newFormValuess[i].totalpercentage;
              let totalprizes =
                parseFloat(totalprize) * (parseFloat(per) / 100);
              let totalrank = parseInt(rankto) - parseInt(rankfrom) + 1;
              let perPrize = parseFloat(totalprizes) / parseInt(totalrank);
              // newFormValues[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValues[i].prize
              newFormValuess[i]["prize"] = perPrize;
              newFormValuess[i]["totalprizes"] = totalprizes;
              setupdateformValues(newFormValuess);
              // setFormValues(newFormValues);
            }
          }
        }
      }
    }

    if (e.target.name === "totalpercentage") {
      if (
        !newFormValuess[i].totalpercentage ||
        newFormValuess[i].totalpercentage === undefined
      ) {
        newFormValuesErr[i][e.target.name] = "This field is required";

        setupdateformValues(newFormValuess);
        setFormValueserr(newFormValuesErr);
        return false;
      } else {
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setupdateformValues(newFormValuess);
        if (newFormValuess[i].totalpercentage == 0) {
          console.log("call percentage3");
          newFormValuess[i]["prize"] = "0";
          newFormValuess[i]["totalprizes"] = "0";
        } else {
          if (
            newFormValuess[i].rankfrom &&
            newFormValuess[i].torank &&
            totalprize
          ) {
            let rankfrom = newFormValuess[i].rankfrom;
            let rankto = newFormValuess[i].torank;
            let per = newFormValuess[i].totalpercentage;
            let totalprizes = parseFloat(totalprize) * (parseFloat(per) / 100);
            let totalrank = parseInt(rankto) - parseInt(rankfrom) + 1;
            let perPrize = parseFloat(totalprizes) / parseInt(totalrank);
            console.log("peruserprize----", perPrize);

            // newFormValues[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValues[i].prize
            newFormValuess[i]["prize"] = perPrize;
            newFormValuess[i]["totalprizes"] = totalprizes;
            setupdateformValues(newFormValuess);
            // setFormValues(newFormValues);
          }
        }
      }
    }

    // if (e.target.name === "prize") {
    //   if (!newFormValuess[i].prize || newFormValuess[i].prize === undefined) {
    //     newFormValuesErr[i][e.target.name] = "This field is required";

    //     setupdateformValues(newFormValuess);
    //     setFormValueserr(newFormValuesErr);
    //     return false;
    //   } else {
    //     newFormValuesErr[i][e.target.name] = "";
    //     setFormValueserr(newFormValuesErr);
    //     setupdateformValues(newFormValuess);
    //     if (newFormValuess[i].rankfrom && newFormValuess[i].torank) {
    //       let rankfrom = newFormValuess[i].rankfrom;
    //       let rankto = newFormValuess[i].torank;

    //       newFormValuess[i]["totalprizes"] =
    //         (rankto - rankfrom + 1) * newFormValuess[i].prize;
    //       setupdateformValues(newFormValuess);
    //     }
    //   }
    // }

    // if (e.target.name === "totalprizes") {
    //   if (
    //     !newFormValuess[i].totalprizes ||
    //     newFormValuess[i].totalprizes === undefined
    //   ) {
    //     newFormValuesErr[i][e.target.name] = "This field is required";

    //     setupdateformValues(newFormValuess);
    //     setFormValueserr(newFormValuesErr);
    //     return false;
    //   } else {
    //     newFormValuesErr[i][e.target.name] = "";
    //     setFormValueserr(newFormValuesErr);
    //     setupdateformValues(newFormValuess);
    //   }
    // }
  };

  let addFormupdateFields = () => {
    setupdateformValues([...updateformValues, {}]);
    setFormValueserr([...formValueserr, { initialValue }]);
  };

  let updateremoveFormFields = (i) => {
    let newFormValues = [...updateformValues];
    newFormValues.splice(i, 1);
    setupdateformValues(newFormValues);
  };
  // ========================================
  const handleClose = () => {
    setName("");
    setDescription("");
    settotalprize("");
    setentryfees("");
    settotalprize("");
    setcontesttype("");
    setautocreate("");
    setcontestcategory("");
    setbonus("");
    setdynamiccontest("");
    // sets_time("");
    // sets_date("");
    // sete_date("");
    // sete_time("");
    setNameerr("");
    setDescriptionerr("");
    settotalprizeerr("");
    setentryfeeserr("");
    settotalprizeerr("");
    setmaxteamsperusererr("");
    setautocreateerr("");
    setcontesttypeerr("");
    setcontestcategoryerr("");
    setbonuserr("");
    setdynamiccontesterr("");
    // sets_timeErr("");
    // sets_dateErr("");
    // sete_timeErr("");
    // sete_dateErr("");
    setFormValues([{}]);
    setFormValueserr([{}]);
    setShow(false);
    setupdateshow(false);
  };

  // ============ errors========================//

  const handleupdateShow = (id) => {
    iddata(id);
    setupdateshow(true);
    setcontestid(id);
  };
  const handleShow = (id) => {
    setShow(true);
  };
  // =================  for react data table =============================//
  const columns = [
    {
      key: "id",
      text: "S.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "contestName",
      text: "Contest Name",
      className: "Name",
      align: "left",
      sortable: true,
    },

    {
      key: "entryfees",
      text: "Entry Fees",
      className: "Entry Fee",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.entryfees == 0 ? "Free" : record.entryfees}</span>;
      },
    },
    {
      key: "contesttype",
      text: "Type",
      className: "type",
      align: "left",
      sortable: true,
    },
    {
      key: "maxteamperuser",
      text: "Max Teams",
      className: "Total Teams",
      align: "left",
      sortable: true,
    },

    {
      key: "totalprize",
      text: " Total Prize",
      className: "Prize",
      align: "left",
      sortable: true,
    },
    {
      key: "createdAt",
      text: "Date & time",
      className: "date",
      align: "left",
      sortable: true,
      cell:(res)=>{
        return (
          <>{moment(res.createdAt).format("DD-MM-YYYY, hh:mm:ss A")}</>
        )
      }
    },

    {
      key: "action",
      text: "Action",
      className: "view",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <Link
              to=""
              onClick={() => {
                handleupdateShow(record.id);
              }}
              style={{ float: "left", cursor: "pointer" }}
            >
             <span className="mdi mdi-square-edit-outline"></span>
            </Link>
            <Link
              to=""
              onClick={() => Conn(record.id)}
              style={{ marginLeft: "5px" }}
            >
              <span className="mdi mdi-trash-can-outline"></span>
            </Link>
          </Fragment>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Filter in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const onaddcontest = async () => {
    if (!name) {
      setNameerr("This field is required");
      return false;
    }
    if (name.match(/^\s/)) {
      setNameerr("This field is required");
      return false;
    }
    if (!description) {
      setDescriptionerr("This field is required");
      return false;
    }
    if (description.match(/^\s/)) {
      setDescriptionerr("This field is required");
      return false;
    }
    if (!contesttype) {
      setcontesttypeerr("This field is required");
      return false;
    }

    if (isDisabled === false) {
      if (!entryfees) {
        setentryfeeserr("This field is required");
        return false;
      }

      if (entryfees <= 0) {
        setentryfeeserr("Enter correct input value");
        return false;
      }
      if (!adminPercentage) {
        setAdminPercentageErr("This field is required");
        return false;
      }
    } else {
      setentryfeeserr("");
      setentryfees("");
      setAdminpercentage("");
      setAdminPercentageErr("");
    }
    if (!totalSpots) {
      setTotalSoptsErr("This field is required");
      return false;
    }
    if (totalSpots < 2) {
      setTotalSoptsErr("Please enter minimum 2 spots");
      return false;
    }
    if (!totalprize) {
      settotalprizeerr("This field is required");
      return false;
    }
    if (totalprize < 0) {
      settotalprizeerr("Enter correct input value");
      return false;
    }
    if (!maxteamperuser) {
      setmaxteamsperusererr("This field is required");
      return false;
    }
    var regex = /^[-+]?[0-9]+\.[0-9]+$/;
    var isValidated = regex.test(maxteamperuser);

    if (isValidated) {
      setmaxteamsperusererr("Enter valid format");
      return false;
    }
    if (maxteamperuser < 2) {
      setmaxteamsperusererr("Minimum 2 player are required");
      return false;
    }

    if (!autocreate) {
      setautocreateerr("This field is required");
      return false;
    }
    if (!contestcategory) {
      setcontestcategoryerr("This field is required");
      return false;
    }

    if (bonus !== "" && bonus <= 0) {
      setbonuserr("Enter valid input value");
      return false;
    }

    if (!dynamiccontest) {
      setdynamiccontesterr("This field is required");
      return false;
    }

    if (!firstPrize) {
      setFirstPrizeErr("This field is required");
      return false;
    }
    if (firstPrize <= 0) {
      setFirstPrizeErr("Please enter the valid value");
      return false;
    }
    // if (!s_time) {
    //   sets_timeErr("This field is required");
    //   return false;
    // }
    // if (!s_date) {
    //   sets_dateErr("This field is required");
    //   return false;
    // }
    // if (!e_time) {
    //   sete_timeErr("This field is required");
    //   return false;
    // }
    // if (!e_date) {
    //   sete_dateErr("This field is required");
    //   return false;
    // }

    for (let i = 0; i < formValues.length; i++) {
      // !formValues[i].totalpercentage ||
      //   formValues[i].totalpercentage <= 0 ||
      let newFormValuesErr = [...formValueserr];
      // !formValues[i].torank ||
      // formValues[i].torank <= 0 ||
      // formValues[i].torank < formValues[i].rankfrom ||
      if (
        !formValues[i].rankfrom ||
        formValues[i].rankfrom <= 0 ||
        !formValues[i].prize ||
        parseFloat(formValues[i].prize) < 0 ||
        !formValues[i].totalprizes ||
        formValues[i].totalprizes < 0
      ) {
        if (!formValues[i].rankfrom) {
          newFormValuesErr[i].rankfrom = "This field is required";
        } else if (formValues[i].rankfrom <= 0) {
          newFormValuesErr[i].rankfrom = "Enter valid input format";
        }else if(formValues[i].rankfrom && formValues[0].rankfrom!="1"){
          newFormValuesErr[0]["rankfrom"] = "Rank will be started 1";
         }else if(formValues[i].torank && parseFloat(formValues[i].rankfrom)>parseFloat(formValues[i].torank)){
          newFormValuesErr[i]["torank"] = "Please enter the valid rank";
         }else if(formValues[i-1].torank && formValues[i].rankfrom==formValues[i-1].torank ){
          newFormValuesErr[i]["rankfrom"] = "Please enter a valid rank";
       }
       if(formValues[i-1]?.torank &&  parseInt(formValues[i].rankfrom)!==parseInt((formValues[i-1].torank))+1){
        console.log("calll==========")
        newFormValuesErr[i]["rankfrom"] = "Please enter a valid rank";
       }

        if (!formValues[i].torank) {
          newFormValuesErr[i].torank = "This field is required";
        } else if (formValues[i].torank <= 0) {
          newFormValuesErr[i].torank = "Enter valid input format";
        } else if (formValues[i].torank < formValues[i].rankfrom) {
          newFormValuesErr[i].rankfrom = "Enter valid rank format";
        }

        if (!formValues[i].totalpercentage) {
          newFormValuesErr[i].totalpercentage = "This field is required";
        } else if (formValues[i].totalpercentage <= 0) {
          newFormValuesErr[i].totalpercentage = "Enter valid input format";
        }

        if (!formValues[i].prize) {
          newFormValuesErr[i].prize = "This field is required";
        } else if (parseFloat(formValues[i].prize) < 0) {
          newFormValuesErr[i].prize = "Enter valid input format";
        }

        if (!formValues[i].totalprizes) {
          newFormValuesErr[i].totalprizes = "This field is required";
        } else if (formValues[i].totalprizes < 0) {
          newFormValuesErr[i].totalprizes = "Enter valid input format";
        }

        setFormValueserr(newFormValuesErr);
        return false;
      }
    }
    var sum=0
     for(var j=0;j<formValues.length;j++){
          sum=sum+parseFloat(formValues[j].totalpercentage)
     }
     let totalvalue=0
     for (let i = 0; i < formValues.length; i++){
       totalvalue=parseFloat(totalvalue)+parseFloat(formValues[i].totalprizes)
     }
     if(totalvalue!=totalprize){
       toast.error("Distributed amount is not equal to total amount")
       return false
     }
     if(sum>100){
      toast.dismiss();
      toast.error("Total percentage is heigh");
     }
     else{
      let contestdata = {
        contestName: name,
        description,
        contesttype,
        totalprize,
        entryfees,
        adminPercentage,
        maxteamperuser: maxteamperuser,
        autocreate,
        categoryId: contestcategory,
        bonus,
        dynamic: dynamiccontest,
        // start_time: s_time,
        // start_date: s_date,
        // end_time: e_time,
        // end_date: s_date,
        atribute: formValues,
        total_spots: totalSpots,
        first_prize: firstPrize,
        addSpots
      };
      let result = await addcontest(contestdata, configs);
      if (result.status === true) {
        toast.dismiss();
        toast.success(result.message);
      }
  
      combinedata();
      handleClose();
     }
   
  };

  //  ============ contestbyid==================//
  const iddata = async (id) => {
    let datas = {
      id,
    };
    let data = await getcontestdatabyid(datas, configs);
    const {
      contestName,
      description,
      contesttype,
      totalprize,
      entryfees,
      admin_percentage,
      maxteamperuser,
      autocreate,
      categoryId,
      bonus,
      dynamic,
      // start_time,
      // start_date,
      // end_time,
      // end_date,
      total_spots,
      first_prize,
      add_spots
    } = data.data.databyid[0];

    setName(contestName);
    setDescription(description);
    setcontesttype(contesttype);
    settotalprize(totalprize);
    if (contesttype === "Free") {
      setIsDisabled(true);
    }
    setentryfees(entryfees);
    setAdminpercentage(admin_percentage);
    setmaxteamperuser(maxteamperuser);
    setautocreate(autocreate);
    setcontestcategory(categoryId);
    setbonus(bonus);
    setdynamiccontest(dynamic);
    setTotalSpots(total_spots);
    setFirstPrize(first_prize);
    // sets_time(start_time);
    // sets_date(moment(start_date).format("YYYY-MM-DD"));
    // sete_time(end_time);
    // sete_date(moment(end_date).format("YYYY-MM-DD"));
    setAddSpots(add_spots)
    setupdateformValues(data.data.databyid2);
    var arr = [];
    for (var i = 0; i < data.data.databyid2.length; i++) {
      arr.push({});
    }
    setFormValueserr(arr);
  };

  // ================ contestbyid==================//

  const combinedata = async () => {
    let result = await getContestCombine(configs);
    if (result.status === true) {
      setRecord(result.data);
    }
  };

  // ========= for contest category dropdown api ===========

  const getcategorydata = async () => {
    let result = await getcategory(configs);
    if (result.status === true) {
      setCategorydata(result.data.categorydata);
    }
  };
  // ============== for delete=-========//
  const deletecontest = async (id) => {
    let data = {
      id,
    };
    let res = await deleteContest(data, configs);
    if (res.status === true) {
      toast.dismiss();
      toast.success(res.message);
    }
    combinedata();
  };

  const Conn = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deletecontest(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  // =======for update ==================//
  const contestupdate = async () => {
    if (!name) {
      setNameerr("This field is required");
      return false;
    }
    if (name.match(/^\s/)) {
      setNameerr("This field is required");
      return false;
    }
    if (!description) {
      setDescriptionerr("This field is required");
      return false;
    }
    if (description.match(/^\s/)) {
      setDescriptionerr("This field is required");
      return false;
    }
    if (!contesttype) {
      setcontesttypeerr("This field is required");
      return false;
    }
  
    if(entryfees!=0){
      if(!entryfees){
        setentryfeeserr("This field is required");
          return false;
      }
      if (entryfees !== "") {
        if (entryfees <= 0) {
          setentryfeeserr("Enter correct input value");
          return false;
        }
      }
      if (!adminPercentage) {
        setAdminPercentageErr("This field is required");
        return false;
      }
    }
    if (!totalSpots) {
      setTotalSoptsErr("This field is required");
      return false;
    }
    if (totalSpots < 2) {
      setTotalSoptsErr("Please enter minimum 2 spots");
      return false;
    }
    if (!totalprize) {
      settotalprizeerr("This field is required");
      return false;
    }
    if (totalprize < 0) {
      settotalprizeerr("Enter correct input value");
      return false;
    }
 

    if (!maxteamperuser) {
      setmaxteamsperusererr("This field is required");
      return false;
    }
    var regex = /^[-+]?[0-9]+\.[0-9]+$/;
    var isValidated = regex.test(maxteamperuser);

    if (isValidated) {
      setmaxteamsperusererr("Enter valid format");
      return false;
    }
    if (maxteamperuser < 2) {
      setmaxteamsperusererr("Minimum 2 player are required");
      return false;
    }
    if (maxteamperuser < 2) {
      setmaxteamsperusererr("Enter correct input value");
      return false;
    }
    if (!autocreate) {
      setautocreateerr("This field is required");
      return false;
    }
    if (!contestcategory) {
      setcontestcategoryerr("This field is required");
      return false;
    }

    if (!dynamiccontest) {
      setdynamiccontesterr("This field is required");
      return false;
    }
   
    if (!firstPrize) {
      setFirstPrizeErr("This field is required");
      return false;
    }
    if (firstPrize <= 0) {
      setFirstPrizeErr("Please enter the valid value");
      return false;
    }
    // if (!s_time) {
    //   sets_timeErr("This field is required");
    //   return false;
    // }
    // if (!s_date) {
    //   sets_dateErr("This field is required");
    //   return false;
    // }
    // if (!e_time) {
    //   sete_timeErr("This field is required");
    //   return false;
    // }
    // if (!e_date) {
    //   sete_dateErr("This field is required");
    //   return false;
    // }
    for (let i = 0; i < updateformValues.length; i++) {
      let newFormValuesErr = [...formValueserr];

      if (
        !updateformValues[i].rankfrom ||
        updateformValues[i].rankfrom === undefined
      ) {
        newFormValuesErr[i].rankfrom = "This field is required";

        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (updateformValues[i].rankfrom <= 0) {
        newFormValuesErr[i].rankfrom = "Enter valid input format";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if(updateformValues[i].rankfrom && updateformValues[0].rankfrom!="1"){
        newFormValuesErr[0].rankfrom = "Rank will be started 1";
        setFormValueserr(newFormValuesErr);
        return false;
       }
       if(updateformValues[i].torank && parseFloat(updateformValues[i].rankfrom)>parseFloat(updateformValues[i].torank)){
        newFormValuesErr[i].torank = "Please enter the valid rank";
        setFormValueserr(newFormValuesErr);
        return false;
       }
       if(updateformValues[i-1]?.torank && updateformValues[i].rankfrom==updateformValues[i-1].torank ){
        newFormValuesErr[i].rankfrom = "Please enter a valid rank";
        setFormValueserr(newFormValuesErr);
        return false;
     }
      if(updateformValues[i-1]?.torank &&  parseInt(updateformValues[i].rankfrom)!==parseInt((updateformValues[i-1].torank))+1){
      newFormValuesErr[i].rankfrom = "Please enter a valid rank";
      setFormValueserr(newFormValuesErr);
        return false;
     }

      if (!updateformValues[i].torank) {
        newFormValuesErr[i].torank = "This field is required";
        setFormValueserr(newFormValuesErr);
        return false;
      }

      if (parseFloat(updateformValues[i].torank) < parseFloat(updateformValues[i].rankfrom)) {
        newFormValuesErr[i].rankfrom = "Enter valid rank format";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (!updateformValues[i].totalpercentage) {
        newFormValuesErr[i].totalpercentage = "This field is required";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (updateformValues[i].totalpercentage <= 0) {
        newFormValuesErr[i].totalpercentage = "Enter valid input format";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (!updateformValues[i].prize) {
        newFormValuesErr[i].prize = "This field is required";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (updateformValues[i].prize < 0) {
        newFormValuesErr[i].prize = "Enter valid input format";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (!updateformValues[i].totalprizes) {
        newFormValuesErr[i].totalprizes = "This field is required";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (updateformValues[i].totalprizes < 0) {
        newFormValuesErr[i].totalprizes = "Enter valid input format";
        setFormValueserr(newFormValuesErr);

        return false;
      }
    }
    var sum=0
    for(var j=0;j<updateformValues.length;j++){
         sum=sum+parseFloat(updateformValues[j].totalpercentage)
    }
    let totalvalue=0
    for (let i = 0; i < updateformValues.length; i++){
      totalvalue=parseFloat(totalvalue)+parseFloat(updateformValues[i].totalprizes)
    }
    if(totalvalue!=totalprize){
      toast.error("Distributed amount is not equal to total amount")
      return false
    }
    if(sum>100){
     toast.dismiss();
     toast.error("Total percentage is heigh");
    }
    else{
      let data = {
        id: contestid,
        contestName: name,
        description,
        contesttype,
        totalprize,
        entryfees,
        adminPercentage,
        maxteamperuser,
        autocreate,
        categoryId: contestcategory,
        bonus,
        dynamic: dynamiccontest,
        // start_time: s_time,
        // start_date: s_date,
        // end_time: e_time,
        // end_date: e_date,
        atribute: updateformValues,
        total_spots: totalSpots,
        first_prize: firstPrize,
        addSpots
      };
      let resp = await contestpointupdates(data, configs);
      if (resp.status === true) {
        toast.dismiss();
        toast.success(resp.message);
      }
      combinedata();
      handleClose();
    }
  
  };

  useEffect(() => {
    getcategorydata();
    combinedata();
  }, []);

  const handlechange = (e) => {
    const { name, value } = e.target;

    const validateInput = (name, value) => {
      if (value === "" || value === undefined || value.match(/^\s/)) {
        return ["This field is required", false];
      } else {
        return ["", true];
      }
    };
    const validateSpots = (name, value) => {
      if (value === "" || value === undefined || value.match(/^\s/)) {
        return ["This field is required", false];
      }
      if (value == 0) {
        return ["Please enter the valid value", false];
      } else {
        return ["", true];
      }
    };
    const validatePercentage = (name, value) => {
      if (value === "" || value === undefined) {
        return ["This field is required", false];
      }
      if (value >= 100) {
        return ["Please enter the valid value", false];
      } else {
        return ["", true];
      }
    };

    switch (name) {
      case "name":
        const [nameErr] = validateInput(name, value);
        setName(value);
        setNameerr(nameErr);
        break;

      case "description":
        const [descErr] = validateInput(name, value);
        setDescription(value);
        setDescriptionerr(descErr);
        break;

      case "contesttype":
        const [contestTypeErr] = validateInput(name, value);
        setcontesttype(value);
        setcontesttypeerr(contestTypeErr);

        if (value === "Free") {
          setIsDisabled(true);
          setentryfeeserr("");
          setAdminPercentageErr("");
          settotalprize("0");
          setautocreate("no");
        } else {
          setIsDisabled(false);
        }
        break;

      case "totalprize":
        const [totalPrizeErr] = validateInput(name, value);
        settotalprize(value);
        settotalprizeerr(totalPrizeErr);
        break;

      case "entryfees":
        const [entryFeesErr] = validateInput(name, value);
        setentryfees(value);
        setentryfeeserr(entryFeesErr);
        if (adminPercentage && totalSpots) {
          let totalvalue = parseInt(value) * parseInt(totalSpots);
          let per = totalvalue * (parseFloat(adminPercentage) / 100);
          let totalprizes = parseFloat(totalvalue) - parseFloat(per);
          settotalprize(totalprizes);
        }
        break;
      case "adminPercentage":
        const [adminPercentageErr] = validatePercentage(name, value);
        setAdminpercentage(value);
        setAdminPercentageErr(adminPercentageErr);
        if (entryfees && totalSpots) {
          let totalvalue = parseInt(entryfees) * parseInt(totalSpots);
          let per = totalvalue * (parseFloat(value) / 100);
          let totalprizes = parseFloat(totalvalue) - parseFloat(per);
          settotalprize(totalprizes);
        }
        break;

      case "maxteamsperuser":
        const [maxTeamsErr, isMaxTeamsValid] = validateInput(name, value);
        setmaxteamperuser(value);

        if (isMaxTeamsValid) {
          const regex = /^[-+]?[0-9]+\.[0-9]+$/;
          const isValidated = regex.test(value);

          if (isValidated) {
            setmaxteamsperusererr("Enter valid format");
          } else {
            setmaxteamsperusererr("");
          }
        } else {
          setmaxteamsperusererr(maxTeamsErr);
        }
        break;

      case "autocreate":
        const [autoCreateErr] = validateInput(name, value);
        setautocreate(value);
        setautocreateerr(autoCreateErr);
        break;
        case "addspots":
          
          setAddSpots(value);
     
          break;
      case "contestcategory":
        const [contestCatErr] = validateInput(name, value);
        setcontestcategory(value);
        setcontestcategoryerr(contestCatErr);
        break;

      case "bonus":
        setbonus(value);

        if (value !== "" && value <= 0) {
          setbonuserr("Enter valid input value");
        } else {
          setbonuserr("");
        }
        break;

      case "dynamiccontest":
        const [dynamicContestErr] = validateInput(name, value);
        setdynamiccontest(value);
        setdynamiccontesterr(dynamicContestErr);
        break;
      case "totalSopts":
        const [totalSpotsErr] = validateSpots(name, value);
        setTotalSpots(value);
        setTotalSoptsErr(totalSpotsErr);
        if (entryfees && adminPercentage) {
          let totalvalue = parseInt(entryfees) * parseInt(value);
          let per = totalvalue * (parseFloat(adminPercentage) / 100);
          let totalprizes = parseFloat(totalvalue) - parseFloat(per);
          settotalprize(totalprizes);
        }
        break;
      case "firstPrize":
        const [firstPrizeErr] = validateSpots(name, value);
        setFirstPrize(value);
        setFirstPrizeErr(firstPrizeErr);
        break;
      // case "s_time":
      //   const [s_timeErr] = validateInput(name, value);
      //   sets_time(value);
      //   sets_timeErr(s_timeErr);
      //   break;
      // case "e_time":
      //   const [e_timeErr] = validateInput(name, value);
      //   sete_time(value);
      //   sete_timeErr(e_timeErr);
      //   break;
      // case "s_date":
      //   const [s_dateErr] = validateInput(name, value);
      //   sets_date(value);
      //   sets_dateErr(s_dateErr);
      //   break;
      // case "e_date":
      //   const [e_dateErr] = validateInput(name, value);
      //   sete_date(value);
      //   sete_dateErr(e_dateErr);
      //   break;

      default:
        break;
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Contest Templates</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer">
                  <div className="add-product-btn text-center">
                    <button
                      onClick={() => handleShow()}
                      className="btn btn-primary"
                    >
                      Create Contest
                    </button>
                    {/* ========================== Add modal=============================== */}
                    <Modal size="lg" show={show} onHide={handleClose}>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Title style={{ color: "black" }}>
                        Add Contest Template
                      </Modal.Title>
                      <Modal.Body>
                        <Row>
                          <Col xs={12} md={5}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Name </Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                value={name}
                                placeholder="Enter Name"
                                // autoFocus
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{nameerr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={7}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Description </Form.Label>
                              <Form.Control
                                type="text"
                                name="description"
                                value={description}
                                placeholder="Enter Description"
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>
                                {descriptionerr}
                              </span>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Contest Type </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="contesttype"
                                onChange={handlechange}
                              >
                                <option selected disabled>
                                  Please select
                                </option>
                                <option value="Paid">Paid</option>
                                <option value="Free">Free</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {contesttypeerr}
                              </span>
                            </Form.Group>
                          </Col>
                          {isDisabled === false ? (
                            <>
                              <Col xs={12} md={3}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Entry Fee</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="entryfees"
                                    placeholder="Enter Entry Fee"
                                    value={entryfees}
                                    onChange={handlechange}
                                  />
                                  <span style={{ color: "red" }}>
                                    {entryfeeserr}
                                  </span>
                                </Form.Group>
                              </Col>
                              <Col xs={12} md={3}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label> Admin Percentage </Form.Label>
                                  <Form.Control
                                    type="tel"
                                    name="adminPercentage"
                                    value={adminPercentage}
                                    placeholder="Enter Admin Percentage"
                                    onChange={handlechange}
                                    onKeyPress={(event) => {
                                      if (!/[0-9.]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    maxLength="4"
                                  />
                                  <span style={{ color: "red" }}>
                                    {adminPercentageErr}
                                  </span>
                                </Form.Group>
                              </Col>
                            </>
                          ) : null}
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Total Spots</Form.Label>
                              <Form.Control
                                type="text"
                                name="totalSopts"
                                placeholder="Enter Total Sopts"
                                value={totalSpots}
                                onChange={handlechange}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <span style={{ color: "red" }}>
                                {totalSpotsErr}
                              </span>
                            </Form.Group>
                          </Col>

                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Total Prize </Form.Label>
                              <Form.Control
                                type="number"
                                name="totalprize"
                                value={totalprize}
                                placeholder="Enter Total Prize"
                                onChange={handlechange}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                disabled={contesttype=='Paid'?true:""}
                              />
                              <span style={{ color: "red" }}>
                                {totalprizeerr}
                              </span>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Max Team Per User</Form.Label>
                              <Form.Control
                                type="number"
                                name="maxteamsperuser"
                                placeholder="Enter Max Teams Per User"
                                defaultValue={2}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>
                                {maxteamsperusererr}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Auto Create When Contest Full
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="autocreate"
                                onChange={handlechange}
                                value={autocreate}
                              >
                                <option selected disabled>
                                  Please select
                                </option>
                                {contesttype=="Free"? 
                                <option value="no">No</option>: <><option value="yes">Yes</option>
                                <option value="no">No</option></> }
                              
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {autocreateerr}
                              </span>
                            </Form.Group>
                          </Col>
                          {
                            autocreate=='yes'?<Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Add Spots
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="addspots"
                                onChange={handlechange}
                                value={addSpots}
                              >
                               
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {autocreateerr}
                              </span>
                            </Form.Group>
                          </Col>:""
                          }
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Contest Category</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="contestcategory"
                                onChange={handlechange}
                              >
                                <option selected disabled>
                                  Please select
                                </option>
                                {categorydata.map((category, index) => {
                                  return (
                                    <>
                                      <option value={category.id}>
                                        {category.name}
                                      </option>
                                    </>
                                  );
                                })}
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {contestcategoryerr}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Bonus</Form.Label>
                              <Form.Control
                                type="number"
                                name="bonus"
                                placeholder="bonus"
                                value={bonus}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{bonuserr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Dynamic Contest</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="dynamiccontest"
                                onChange={handlechange}
                              >
                                <option selected disabled>
                                  Please select
                                </option>
                                <option value="Normal">Normal</option>
                                <option value="Dynamic">Dynamic</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {dynamiccontesterr}
                              </span>
                            </Form.Group>
                          </Col>

                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>First Prize</Form.Label>
                              <Form.Control
                                type="tel"
                                name="firstPrize"
                                placeholder="First Prize"
                                value={firstPrize}
                                onChange={handlechange}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <span style={{ color: "red" }}>
                                {firstPrizeErr}
                              </span>
                            </Form.Group>
                          </Col>
                          {/* <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Start Time </Form.Label>
                              <Form.Control
                                type="time"
                                name="s_time"
                                placeholder="Start Time"
                                value={s_time}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{s_timeErr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Start Date </Form.Label>
                              <Form.Control
                                type="date"
                                name="s_date"
                                placeholder="Start Time"
                                value={s_date}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{s_dateErr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>End Time </Form.Label>
                              <Form.Control
                                type="time"
                                name="e_time"
                                placeholder="End Time"
                                value={e_time}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{e_timeErr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>End Date </Form.Label>
                              <Form.Control
                                type="date"
                                name="e_date"
                                placeholder="End Time"
                                value={e_date}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{e_dateErr}</span>
                            </Form.Group>
                          </Col> */}
                        </Row>

                        {formValues.map((element, index) => {
                          console.log("user prize==", element.prize);
                          return (
                            <>
                              {" "}
                              <Row>
                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label> Rank From</Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="rankfrom"
                                      placeholder=" Enter rankfrom"
                                      value={element.rankfrom || ""}
                                      onChange={(e) =>
                                        handleChangemorefield(index, e)
                                      }
                                    />

                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.rankfrom}
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label> To Rank </Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="torank"
                                      placeholder="To rank"
                                      value={element.torank || ""}
                                      onChange={(e) =>
                                        handleChangemorefield(index, e)
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.torank}
                                    </span>
                                  </Form.Group>
                                </Col>

                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label> Total Percentage</Form.Label>
                                    <Form.Control
                                      type="tel"
                                      name="totalpercentage"
                                      placeholder=" Total Percentage"
                                      value={element.totalpercentage || ""}
                                      onChange={(e) =>
                                        handleChangemorefield(index, e)
                                      }
                                      onKeyPress={(event) => {
                                        if (!/[0-9.]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      maxLength="4"
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.totalpercentage}
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>Prize Per User</Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="prize"
                                      placeholder="Prize"
                                      value={element.prize?.toString() || ""}
                                      onChange={(e) =>
                                        handleChangemorefield(index, e)
                                      }
                                      readOnly
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.prize}
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label> Total Prize</Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="totalprizes"
                                      placeholder=" Total Prize"
                                      value={
                                        element.totalprizes?.toString() || ""
                                      }
                                      onChange={(e) =>
                                        handleChangemorefield(index, e)
                                      }
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      readOnly
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.totalprizes}
                                    </span>
                                  </Form.Group>
                                  {index ? (
                                    <button
                                      type="button"
                                      className="button remove"
                                      onClick={() => removeFormFields(index)}
                                    >
                                      - Remove
                                    </button>
                                  ) : null}
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                        <div className="add-row">
                          <a href="#!" onClick={() => addFormFields()}>
                            + Add More
                          </a>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button variant="secondary" onClick={onaddcontest}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {/* ========================= =update modal============================= */}
                    <Modal size="lg" show={updateshow} onHide={handleClose}>
                      <Modal.Header closeButton><Modal.Title style={{ color: "black" }}>
                        Update Contest Template
                      </Modal.Title></Modal.Header>
                      
                      <Modal.Body>
                        <Row>
                          <Col xs={12} md={5}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Name </Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                value={name}
                                placeholder="Enter Name `"
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{nameerr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={7}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Description </Form.Label>
                              <Form.Control
                                type="text"
                                name="description"
                                value={description}
                                placeholder="Enter Description"
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>
                                {descriptionerr}
                              </span>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={2}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Contest Type </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="contesttype"
                                onChange={handlechange}
                                value={contesttype}
                              >
                                <option value="Paid">Paid</option>
                                <option value="Free">Free</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>

                          {isDisabled === false || contesttype === "Paid" ? (
                            <>
                              <Col xs={12} md={3}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Entry Fee</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="entryfees"
                                    placeholder="Enter Entry Fee"
                                    value={entryfees}
                                    onChange={handlechange}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                  <span style={{ color: "red" }}>
                                    {entryfeeserr}
                                  </span>
                                </Form.Group>
                              </Col>
                              <Col xs={12} md={3}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label> Admin Percentage </Form.Label>
                                  <Form.Control
                                    type="tel"
                                    name="adminPercentage"
                                    value={adminPercentage}
                                    placeholder="Enter Percentage"
                                    onChange={handlechange}
                                    onKeyPress={(event) => {
                                      if (!/[0-9.]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    maxLength="4"
                                  />
                                  <span style={{ color: "red" }}>
                                    {adminPercentageErr}
                                  </span>
                                </Form.Group>
                              </Col>
                            </>
                          ) : null}
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Total Sopts</Form.Label>
                              <Form.Control
                                type="text"
                                name="totalSopts"
                                placeholder="Total Spots "
                                value={totalSpots}
                                onChange={handlechange}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <span style={{ color: "red" }}>
                                {totalSpotsErr}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Total Prize </Form.Label>
                              <Form.Control
                                type="text"
                                name="totalprize"
                                value={totalprize}
                                placeholder="Enter Total Prize"
                                onChange={handlechange}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                disabled={contesttype=='Paid'?true:""}
                              />
                              <span style={{ color: "red" }}>
                                {totalprizeerr}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Max Team Per User</Form.Label>
                              <Form.Control
                                type="number"
                                name="maxteamsperuser"
                                placeholder="Enter Max Team Per User"
                                value={maxteamperuser}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>
                                {maxteamsperusererr}
                              </span>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Auto Create When Contest Full
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="autocreate"
                                onChange={handlechange}
                                value={autocreate}
                              >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {autocreateerr}
                              </span>
                            </Form.Group>
                          </Col>
                          {
                            autocreate=='yes'?<Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Add Spots
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="addspots"
                                onChange={handlechange}
                                value={addSpots}
                              >
                               
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {autocreateerr}
                              </span>
                            </Form.Group>
                          </Col>:""
                          }
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Contest Category</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="contestcategory"
                                onChange={handlechange}
                                value={contestcategory}
                              >
                                {categorydata.map((category, index) => {
                                  return (
                                    <>
                                      <option value={category.id}>
                                        {category.name}
                                      </option>
                                    </>
                                  );
                                })}
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {contestcategoryerr}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Bonus</Form.Label>
                              <Form.Control
                                type="number"
                                name="bonus"
                                placeholder="Enter Bonus"
                                value={bonus}
                                onChange={handlechange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Dynamic Contest</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="dynamiccontest"
                                onChange={handlechange}
                                value={dynamiccontest}
                              >
                                <option value="Normal">Normal</option>
                                <option value="Dynamic">Dynamic</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {dynamiccontesterr}
                              </span>
                            </Form.Group>
                          </Col>

                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>First Prize</Form.Label>
                              <Form.Control
                                type="tel"
                                name="firstPrize"
                                placeholder="Enter First Prize"
                                value={firstPrize}
                                onChange={handlechange}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <span style={{ color: "red" }}>
                                {firstPrizeErr}
                              </span>
                            </Form.Group>
                          </Col>
                          {/* <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Start Time </Form.Label>
                              <Form.Control
                                type="time"
                                name="s_time"
                                placeholder="Start Time"
                                value={s_time}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{s_timeErr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Start Date </Form.Label>
                              <Form.Control
                                type="date"
                                name="s_date"
                                placeholder="Start Time"
                                value={s_date}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{s_dateErr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>End Time </Form.Label>
                              <Form.Control
                                type="time"
                                name="e_time"
                                placeholder="End Time"
                                value={e_time}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{e_timeErr}</span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>End Date </Form.Label>
                              <Form.Control
                                type="date"
                                name="e_date"
                                placeholder="End Time"
                                value={e_date}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{e_dateErr}</span>
                            </Form.Group>
                          </Col> */}
                        </Row>
                        {/* ============add more update================== */}
                        {updateformValues.map((item, index) => {
                          return (
                            <>
                              <Row>
                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label> Rank From</Form.Label>
                                    <Form.Control
                                      style={{ color: "#1a1d21" }}
                                      type="number"
                                      name="rankfrom"
                                      placeholder=" Enter rankfrom"
                                      value={item.rankfrom || ""}
                                      onChange={(e) =>
                                        handleChangemoreupdatefield(e, index)
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.rankfrom}
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label> To Rank </Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="torank"
                                      placeholder="To rank"
                                      value={item.torank}
                                      onChange={(e) =>
                                        handleChangemoreupdatefield(e, index)
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.torank}
                                    </span>
                                  </Form.Group>
                                </Col>

                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label> Total Percentage</Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="totalpercentage"
                                      placeholder=" Total Percentage"
                                      value={item.totalpercentage}
                                      onChange={(e) =>
                                        handleChangemoreupdatefield(e, index)
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.totalpercentage}
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>Prize Per User</Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="prize"
                                      placeholder="Prize"
                                      value={item.prize}
                                      onChange={(e) =>
                                        handleChangemoreupdatefield(e, index)
                                      }
                                      readOnly
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.prize}
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label> Total Prize</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="totalprizes"
                                      placeholder=" Total Prize"
                                      value={item.totalprizes}
                                      onChange={(e) =>
                                        handleChangemoreupdatefield(e, index)
                                      }
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      readOnly
                                    />
                                    <span style={{ color: "red" }}>
                                      {formValueserr[index]?.totalprizes}
                                    </span>
                                  </Form.Group>
                                  {index ? (
                                    <button
                                      type="button"
                                      className="button remove"
                                      onClick={() =>
                                        updateremoveFormFields(index)
                                      }
                                    >
                                      - Remove
                                    </button>
                                  ) : null}
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                        <div className="add-row">
                          <a href="#!" onClick={() => addFormupdateFields()}>
                            + Add More
                          </a>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button variant="secondary" onClick={contestupdate}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    // onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contesttemplate;
