import React, { useState, useEffect } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Footer from "../Widgets/Footer";
import { userDetailsById } from "../services/user";
import configs from "../Constant/Config";
import { useParams } from "react-router-dom";

const UserDetails = () => {
  const [userDetails, setUserDetails] = useState([]);
  const id = useParams();

  const UserDetails = async () => {
    let result = await userDetailsById(id, configs);
    setUserDetails(result.data[0]);
  };
  useEffect(() => {
    UserDetails();
  }, []);

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>User Details</b>
              </h2>
            </div>
           <div class="white_box">   
		   <form>
              <div class="row mt-5">
                <div class="col-md-1">
                  <label>User Id </label>
                </div>
                <div class="col-md-4">
                  <input
                    type="text"
                    disabled
                    value={userDetails.id}
                    class="form-control"
                    placeholder="User Id"
                  />
                </div>
                <div class="col-md-1">
                  <label>User Name</label>
                </div>
                <div class="col-md-4">
                  <input
                    type="text"
                    disabled
                    value={userDetails.userName}
                    class="form-control"
                    placeholder="User Name"
                  />
                </div>
              </div>
              <div class="row mt-5 mb-5">
                <div class="col-md-1">
                  <label>Email </label>
                </div>
                <div class="col-md-4">
                  <input
                    type="text"
                    disabled
                    value={userDetails.email}
                    class="form-control"
                    placeholder="Email"
                  />
                </div>
                <div class="col-md-1">
                  <label>Phone No.</label>
                </div>
                <div class="col-md-4">
                  <input
                    type="number"
                    disabled
                    value={userDetails.mobileNumber}
                    class="form-control"
                    placeholder="Last name"
                  />
                </div>
              </div>
              <div class="row mt-5 mb-5">
                <div class="col-md-1">
                  <label>Aadhar No. </label>
                </div>
                <div class="col-md-4">
                  <input
                    type="text"
                    disabled
                    value={userDetails.aadharNo}
                    class="form-control"
                    placeholder="First name"
                  />
                  <img src="" alt="" />
                </div>
                <div class="col-md-1">
                  <label>Pan No.</label>
                </div>
                <div class="col-md-4">
                  <input
                    type="text"
                    disabled
                    value={userDetails.panNo}
                    class="form-control"
                    placeholder="Last name"
                  />
                  <img src="" alt="" />
                </div>
              </div>
            </form>
        
          </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default UserDetails;
