import React, { useState, useEffect } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Footer from "../Widgets/Footer";
import "react-confirm-alert/src/react-confirm-alert.css";
import configs from "../Constant/Config";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { updateName, userDetailsById } from "../services/user";
import { useParams } from "react-router-dom";
import { baseUrls } from "./BaseUrl";
import { rejectedReason, verifyDoc } from "../services/kyc";
import { useRef } from "react";
import RotateImage from "../partials/RotateImage";
import moment from "moment";
import AadharUpdateModal from "../partials/AadharUpdateModal";
import PanUpdateModal from "../partials/PanUpdateModal";

export const Verifykyc = () => {
  const id = useParams();
  const [userDetails, setUserDetails] = useState([]);
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [diableName, setdiableName] = useState(true);
  const [reject, setReject] = useState("");
  const [rejecterr, setRejecterr] = useState("");
  const [show, setShow] = useState(false);
  const [imageShow, setImageShow] = useState(false);
  const [image, setimage] = useState("");
  const [type, setType] = useState("");
  const [refresh, setrefresh] = useState(false);
  const [aadhaarEditShow, setAadharEditShow] = useState(false);
  const [panEditShow, setPanEditShow] = useState(false);
  const handleShow = (type) => {
    setShow(true);
    setType(type);
  };
  const handleAddharShow = () => {
    setAadharEditShow(true);
  };
  const handlePanShow = () => {
    setPanEditShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setRejecterr("");
  };
  const handlechange = (e) => {
    setReject(e.target.value);
    if (e.target.value === "") {
      setRejecterr("This field is required");
    } else {
      setRejecterr("");
    }
  };

  // const ref = useRef();

  useEffect(() => {
    UserDetails();
  }, [refresh]);
  const UserDetails = async () => {
    let result = await userDetailsById(id, configs);
    setUserDetails(result.data[0]);
    setName(result.data[0].name);
    setUserId(result.data[0].id);
  };
  const rejectedHandler = async () => {
    if (!reject) {
      setRejecterr("This field is required");
      return false;
    }
    let data = { reason: reject, type: type, id: userDetails.id };
    let result = await rejectedReason(data, configs);

    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
    }
    UserDetails();
    handleClose();
  };
  const verifyHandler = async (type) => {
    let data = { type: type, id: userDetails.id };
    let result = await verifyDoc(data, configs);

    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
    }
    UserDetails();
    handleClose();
  };
  const handleImageShow = (image) => {
    setimage(image);
    setImageShow(true);
  };
  const editName = () => {
    setdiableName(false);
  };
  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const updatename = async (e) => {
    e.preventDefault();
    const data = {
      name: name,
      id: userId,
    };
    let result = await updateName(data, configs);
    if (result.status) {
      setdiableName(true);
      setrefresh(!refresh);
      toast.dismiss();
      toast.success(result.message);
    }
  };
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>User Detail</b>
              </h2>
            </div>
            <div className="white_box">
              <div className="row">
                <div className="col-md-6">
                  <div className="product-list-outer">
                    <h5>
                      <b>Name :</b>
                      <input
                        type="text"
                        disabled={diableName}
                        value={name}
                        onChange={onChangeName}
                      />
                      {diableName ? (
                        <i
                          className="mdi mdi-square-edit-outline edit-btn"
                          onClick={editName}
                        ></i>
                      ) : (
                        ""
                      )}
                      {diableName ? (
                        ""
                      ) : (
                        <button type="button" onClick={updatename}>
                          Update
                        </button>
                      )}
                    </h5>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="product-list-outer">
                    <h5>
                      <b>Email : {userDetails.email}</b>
                    </h5>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="product-list-outer">
                    <h5>
                      <b>Mobile Number : {userDetails.mobileNumber}</b>
                    </h5>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="product-list-outer">
                    <h5>
                      <b>
                        DOB :{" "}
                        {userDetails?.dob
                          ? moment(userDetails?.dob).format("DD-MM-YYYY")
                          : ""}
                      </b>
                    </h5>
                  </div>
                </div>
                <b>
                  <hr />
                </b>
              </div>
              {userDetails.email ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="product-list-outer">
                        <span>
                          {" "}
                          <h5>
                            <b>Email</b> :{userDetails.email}
                          </h5>
                        </span>

                        <h5>
                          <b>Status : {userDetails.emailStatus}</b>
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-list-outer"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      {userDetails.emailStatus == "Pending" ? (
                        <>
                          <button
                            onClick={() => verifyHandler("email")}
                            type="button"
                            className="btn btn-success me-3"
                          >
                            Verify
                          </button>
                          <button
                            onClick={() => handleShow("email")}
                            type="button"
                            className="btn btn-danger"
                          >
                            Reject
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {userDetails.aadharNo ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="product-list-outer">
                        <span>
                          {" "}
                          <h5>
                            <b>Aadhar Number</b> :{userDetails.aadharNo}
                          </h5>
                        </span>

                        <h5>
                          <b>Status : {userDetails.aadharStatus}</b>
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-list-outer"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="product-list-outer">
                        <img
                          style={{ height: "250px" }}
                          src={
                            baseUrls +
                            "/static/kycdoc/" +
                            userDetails.aadharFront
                          }
                          alt=""
                          onClick={() => {
                            handleImageShow(
                              "/static/kycdoc/" + userDetails.aadharFront
                            );
                          }}
                          className="img-pop"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-list-outer">
                        <img
                          style={{ height: "250px" }}
                          src={
                            baseUrls +
                            "/static/kycdoc/" +
                            userDetails.aadharBack
                          }
                          alt=""
                          onClick={() => {
                            handleImageShow(
                              "/static/kycdoc/" + userDetails.aadharBack
                            );
                          }}
                          className="img-pop"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {userDetails.aadharStatus === "Pending" ? (
                        <>
                          <button
                            onClick={() => verifyHandler("aadhar")}
                            type="button"
                            className="btn btn-success me-3"
                          >
                            Verify
                          </button>
                          <button
                            onClick={() => handleShow("aadhar")}
                            type="button"
                            className="btn btn-danger"
                          >
                            Reject
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                      {userDetails.aadharStatus === "Verified" ? (
                        <>
                          <button
                            onClick={() => handleShow("aadhar")}
                            type="button"
                            className="btn btn-danger"
                          >
                            Reject
                          </button>

                          <button
                            onClick={() => handleAddharShow()}
                            type="button"
                            className="btn btn-primary"
                          >
                            Edit
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <b>
                <hr />
              </b>
              {userDetails.panNo ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="product-list-outer">
                        <span>
                          {" "}
                          <h5>
                            <b>Pan Number</b> :{userDetails.panNo}
                          </h5>
                        </span>

                        <h5>
                          <b>Status : {userDetails.panStatus}</b>
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-list-outer"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="product-list-outer">
                        <img
                          style={{ height: "250px" }}
                          src={
                            baseUrls + "/static/kycdoc/" + userDetails.panFront
                          }
                          alt=""
                          onClick={() => {
                            handleImageShow(
                              "/static/kycdoc/" + userDetails.panFront
                            );
                          }}
                          className="img-pop"
                        />
                      </div>
                      {userDetails.panStatus === "Pending" ? (
                        <>
                          <button
                            onClick={() => verifyHandler("pan")}
                            type="button"
                            className="btn btn-success me-3"
                          >
                            Verify
                          </button>
                          <button
                            onClick={() => handleShow("pancard")}
                            type="button"
                            className="btn btn-danger"
                          >
                            Reject
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                      {userDetails.panStatus === "Verified" ? (
                        <>
                          <button
                            onClick={() => handleShow("pancard")}
                            type="button"
                            className="btn btn-danger"
                          >
                            Reject
                          </button>
                          <button
                            onClick={() => handlePanShow()}
                            type="button"
                            className="btn btn-primary"
                          >
                            Edit
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <b>
                <hr />
              </b>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Title style={{ color: "black" }}>
                  Write Reason
                </Modal.Title>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label> Reason </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={reject}
                        placeholder="Enter Name"
                        onChange={handlechange}
                      />
                      <span style={{ color: "red" }}>{rejecterr}</span>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="secondary" onClick={rejectedHandler}>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <RotateImage
            image={image}
            setImageShow={setImageShow}
            imageShow={imageShow}
          />
          <AadharUpdateModal
            refresh={refresh}
            setrefresh={setrefresh}
            aadhaarEditShow={aadhaarEditShow}
            setAadharEditShow={setAadharEditShow}
            id={id}
            aadharNo={userDetails.aadharNo}
            backImage={baseUrls + "/static/kycdoc/" + userDetails.aadharBack}
            frontImage={baseUrls + "/static/kycdoc/" + userDetails.aadharFront}
          />
          <PanUpdateModal
            refresh={refresh}
            setrefresh={setrefresh}
            panEditShow={panEditShow}
            setPanEditShow={setPanEditShow}
            id={id}
            panNo={userDetails.panNo}
            panFront={baseUrls + "/static/kycdoc/" + userDetails.panFront}
          
          />
        </div>

        <Footer />
      </div>
    </div>
  );
};
