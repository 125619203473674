import React,{useEffect} from "react";
import io from "socket.io-client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import { ToastContainer } from "react-toastify";
import Dashboard from "./Widgets/Dashboard";
import Totaluser from "./Pages/Totaluser";
import Withdrawalrequest from "./Pages/Withdrawalrequest";
import { Helpsupport } from "./Pages/Helpsupport";
import Usermanagement from "./Pages/Usermanagement";
import { Paymentmanagement } from "./Pages/Paymentmanagement";
import { Managewithdrawrequest } from "./Pages/Managewithdrawrequest";
import { Managematches } from "./Pages/Managematches";
import { Revenuemanagement } from "./Pages/Revenuemanagement";
import { Managerewardpoints } from "./Pages/Managerewardpoints";
import { Managereports } from "./Pages/Managereports";
import { Aboutus } from "./Pages/Aboutus";
import { Contactus } from "./Pages/Contactus";
import { Legality } from "./Pages/Legality";
import { Support } from "./Pages/Support";
import { Sendemail } from "./Pages/Sendemail";

import { Pushnotification } from "./Pages/Pushnotification";
import { Verification } from "./Pages/Verification";
import Contestcategories from "./Pages/Contestcategories";
import { Rankcategories } from "./Pages/Rankcategories";
import { Fixtures } from "./Pages/Fixtures";
import { Contests } from "./Pages/Contests";
import { Fantasypoints } from "./Pages/Fantasypoints";
import { Settings } from "./Pages/Settings";
import { Banner } from "./Pages/Banner";
import Contesttemplate from "./Pages/Contesttemplate";
import { Notifications } from "./Pages/Notifications";
import { Team } from "./Pages/Team";

import UserDetails from "./Pages/UserDetails";
import { Player } from "./Pages/Player";

import "firebase/compat/messaging";
import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { Verifykyc } from "./Pages/Verifykyc";
import Competitions from "./Pages/Competitions";
import Fixturescontest from "./Pages/Fixturescontest";
import Fixturescontestlist from "./Pages/Fixturescontestlist";
import FixturePlayer from "./Pages/FixturePlayer";
import { TermAndCondition } from "./Pages/TermAndCondition";
import { PromotionBanner } from "./Pages/PromotionBanner";
import PlayerImage from "./Pages/PlayerImage";
import ReferralBoard from "./Pages/ReferralBoard";
import ReferralUser from "./Pages/ReferralUser";
import FixtureContestView from "./Pages/FixtureContestView";

// const socket = io.connect("https://fantasyapi.technoloaderitservices.com",{ query:"JWTtoken="+ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjA3LCJpYXQiOjE2ODM2MjY1ODB9.ZwB6pCOqyDIkoy36tqaurvddmUHpK10-l1JZ9ZAnxGs" });
function App() { 


  // const firebaseConfig = {
  //   apiKey: "AIzaSyAr9VrcK7yJAfGAYRL93LjAHOl1H5ekRdk",
  //   authDomain: "technofantasy-messaging.firebaseapp.com",
  //   projectId: "fantasy-75ad8",
  //   storageBucket: "technofantasy-messaging.appspot.com",
  //   messagingSenderId: "61411370054",
  //   appId: "1:118475969254:web:04128671043edfc1c2a127",
  // };

  const firebaseConfig = {
    apiKey: "AIzaSyAr9VrcK7yJAfGAYRL93LjAHOl1H5ekRdk",
    authDomain: "fantasy-75ad8.firebaseapp.com",
    projectId: "fantasy-75ad8",
    storageBucket: "fantasy-75ad8.appspot.com",
    messagingSenderId: "61411370054",
    appId: "1:61411370054:web:6c398900a4960aa555d647",
    measurementId: "G-0679FD3LZC"
  };

   function requestPermission(){
    console.log("Request permission....")
    Notification.requestPermission().then((permission)=>{
      if(permission==='granted'){
        console.log("Notification permission granted")
        const apps=initializeApp(firebaseConfig)
        console.log("appps",apps)
        const messaging = getMessaging(apps);
      
        getToken(messaging, {
          vapidKey:
            "BFGIfhwaIw2KTxHpkzYkBFIC8xJj4dIeXQb6xYaSporrUjNcCLEIzn6qpDnHRTrzFQWCvlgMruJkZxZobzntmYU",
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log(currentToken, "your token is available");
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });
      }
      else{
        console.log("Do not have permission")
      }
    })
   }


  useEffect(() => {
    requestPermission();
  }, []);

  // const myFunction = () => {
  //   // var element = document.getElementById("as-react-datatable-table-foot");
  //   if (document.getElementById("as-react-datatable-table-foot") != null) {
  //     document.getElementById("as-react-datatable-table-foot").classList.add("align-items-center");
  //   }
  // };

//  }
  return (
    <>

      <Router>
      {/* <button onClick={()=>{handlesocket()}}>Socket</button> */}
        <Routes>
      
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/total-user" element={<Totaluser />} />
          <Route path="/total-user/:name" element={<Totaluser />} />
          <Route path="/withdrawal-request" element={<Withdrawalrequest />} />
          <Route path="/user-management" element={<Usermanagement />} />
          <Route path="/user-details/:id" element={<UserDetails />} />
          
          <Route path="/manage-payment" element={<Paymentmanagement />} />
          <Route
            path="/manage-withdraw-request"
            element={<Managewithdrawrequest />}
          />
          <Route path="/manage-matches" element={<Managematches />} />
          <Route path="/revenue-management" element={<Revenuemanagement />} />
          <Route
            path="/manage-reward-points"
            element={<Managerewardpoints />}
          />
          <Route path="/manage-reports" element={<Managereports />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/legality" element={<Legality />} />
          <Route path="/term-and-condition" element={<TermAndCondition />} />
          <Route path="/support" element={<Support />} />
          <Route path="/send-mail" element={<Sendemail />} />
          <Route path="/push-notification" element={<Pushnotification />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/verifykyc/:id" element={<Verifykyc />} />
          <Route path="/contest-categories" element={<Contestcategories />} />
          <Route path="/rank-categories" element={<Rankcategories />} />
          <Route path="/fixture" element={<Fixtures />} />
          <Route path="/contest" element={<Contests />} />
          <Route path="/contest/:name" element={<Contests />} />
          <Route path="/fantasy-points" element={<Fantasypoints />} />
          <Route path="/notification" element={<Notifications />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/banner" element={<Banner />} />
          <Route path="/promotion-banner" element={<PromotionBanner/>} />
          <Route path="/contest-template" element={<Contesttemplate />} />
          <Route path="/team" element={<Team />} />
          <Route path="/player" element={<Player />} />
          <Route path="/player-image" element={<PlayerImage />} />
          <Route path="/help-support" element={<Helpsupport />} />
          <Route path="/competitions" element={<Competitions />} />
          <Route path="/fixturescontest/:id" element={<Fixturescontest />} />
          <Route path="/fixturescontestlist/:id" element={<Fixturescontestlist/>} />
          <Route path="/fixture-contest-view/:id" element={<FixtureContestView/>} />
          <Route path="/fixtureplayer/:id" element={<FixturePlayer/>} />
          <Route path="/referral-board" element={<ReferralBoard />} />
          <Route path="/referral-user/:id" element={<ReferralUser />} />
        </Routes>
      </Router>
      <ToastContainer limit={1} autoClose={2000}/>
    </>
  );
}
export default App;
