import React,{useState,useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import configs from "../Constant/Config";
import { toast } from "react-toastify";
import { updatePan } from "../services/user";
function PanUpdateModal(props) {
  const [disable, setDisable] = useState(false)
  const [panImageErr, setpanImageErr] = useState("")

  const [panImage, setPanImage] = useState(props.panFront)
  const [panNumber, setPanNumber] = useState(props.panNo)
  const [panNumberErr, setPanNumberErr] = useState("")
  const [panFile, setPanFile] = useState("")

  const [id, setId] = useState(props.id)
  useEffect(() => {
    setPanNumber(props.panNo)
    setPanImage(props.panFront)
    setId(props.id)
  }, [props])
  const handleClose = () => {
    props.setPanEditShow(false);
  };
  const onsubmit =async (e) => {
    e.preventDefault()
    const formdata = new FormData();
    
    formdata.append("id", id.id);
    formdata.append("panNo", panNumber);
    formdata.append("panFront", panFile);
    const result=await updatePan(formdata,configs)
   
    if(result.status){
     toast.dismiss();
       toast.success(result.message);
     props.setPanEditShow(false);
     props.setrefresh(!props.refresh)
    }
   };
   const handlechangeNumber = (e) => {
    setPanNumber(e.target.value)
      if(e.target.value===""){
         setPanNumberErr("This field is required")
         return false
      }
      if((e.target.value).length<10){
        setPanNumberErr("Invalid pan number")
         return false
      }
      setPanNumberErr("")
   };
   const handlechangeimage = (e) => {
    const {name,value}=e.target
    if(name==="panImage"){
      setPanFile(e.target.files[0])
       setPanImage(URL.createObjectURL(e.target.files[0]))
       if(value===""){
        setpanImageErr("This field is required")
        return false
       }
       setpanImageErr("")
    }
   
  };
  return (
    <div>
      <Modal show={props.panEditShow} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Update Pan Card</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Pan Card Number </Form.Label>
              <Form.Control
                type="tel"
                name="aadharNumber"
                value={panNumber}
                onChange={handlechangeNumber}
                maxLength="12"
              />
              <span  style={{ color: "red" }}>{panNumberErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div>
              <img
                style={{ width: "100px" }}
                src={panImage}
                className="img-fluid"
                alt=""
             
              />
              </div>
              
               <Form.Label>Pan card Image</Form.Label>
              <Form.Control
                type="file"
                name="panImage"
                accept="image/png, image/jpeg, image/jpg"
                onChange={handlechangeimage}
              />
              <span style={{ color: "red" }}>{panImageErr}</span>
            </Form.Group>
           
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit} disabled={disable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default PanUpdateModal
