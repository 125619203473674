import * as opsService from "./Ops";
import { baseUrl } from "../Pages/BaseUrl";
import { addCategory,getCategory,deleteCategory,updateCategory,getCategoryById,addContestTemplate,getContestTemplate,deleteContestTemplate,updateContestTemplate,getContestTemplateById,getTournaments,getTemplatesName,getFixtures,addFixtureContests,getAllFixtureContests,getFixtureContestsByfixtureId,deleteFixtureContests,getTotalContests, getFixtureContestsByIdApi } from "../Constant/Api";

const getcategory = async (token) => {
  let result = await opsService.getData(getCategory, token);
  return result;
};
const addcategory = async (data, token) => {
  let result = await opsService.postdata(
    addCategory,
    data,
    token
  );
  return result;
};
const getcategorydatabyid = async (data, token) => {
  let result = await opsService.postdata(
    getCategoryById,
    data,
    token
  );
  return result;
};
const categorydelete = async (data, token) => {
  let result = await opsService.postdata(
    deleteCategory,
    data,
    token
  );
  return result;
};
const categoryupdates = async (data, token) => {
  let result = await opsService.postdata(
    updateCategory,
    data,
    token
  );
  return result;
};

//   ============ contest template==================//
const getContestCombine = async (token) => {
  let result = await opsService.getData(
    getContestTemplate,
    token
  );
  return result;
};
// const getcontest = async (data, token) => {
//   let result = await opsService.getData(
//     baseUrl +"/contest-getdata",
//     data,
//     token
//   );
//   return result;
// };
// const conteststatus = async (data, token) => {
//   let result = await opsService.postdata(
//     baseUrl +"/contest-status",
//     data,
//     token
//   );
//   return result;
// };
const addcontest = async (data, token) => {
  let result = await opsService.postdata(
    addContestTemplate,
    data,
    token
  );
  return result;
};
const getcontestdatabyid = async (data, token) => {
  let result = await opsService.postdata(getContestTemplateById, data, token);
  return result;
};
const deleteContest = async (data, token) => {
  let result = await opsService.postdata(
    deleteContestTemplate,
    data,
    token
  );
  return result;
};
const contestpointupdates = async (data, token) => {
  let result = await opsService.postdata(
    updateContestTemplate,
    data,
    token
  );
  return result;
};
const getTemplateName = async (token) => {
  let result = await opsService.getData(
    getTemplatesName,
    token
  );
  return result;
};

// ============= notification=======================//
const addnotification = async (data, token) => {
  let result = await opsService.postdata(
    baseUrl +"/notification-insert",
    data,
    token
  );
  return result;
};
const getnotification = async (data, token) => {
  let result = await opsService.getData(
    baseUrl +"/notification-get",
    data,
    token
  );
  return result;
};


// const getTotalContestFilter = async (data, token) => {
//   let result = await opsService.getData(
//     baseUrl + "/total_ac_in_contest",
//     data,
//     token
//   );
//   return result;
// }




const getTournament=async(token)=>{
  let result = await opsService.getData(
    getTournaments,
    token
  );
  return result;
}
const getFixturesData = async (token) => {
  let result = await opsService.getData(
    getFixtures,
    token
  );
  return result;
};

const allFixtureContest = async (data, token) => {
  let result = await opsService.getData(
    getAllFixtureContests,
    token
  );
  return result;
};
// const insertMatch=async(data,token)=>{
//   let result = await opsService.postdata(
//     baseUrl +"/insert-match",
//     data,
//     token
//   );
//   return result;
// }
const addFixtureContest = async (data, token) => {
  let result = await opsService.postdata(
    addFixtureContests,
    data,
    token
  );
  return result;
};
const getFixtureContext = async (data, token) => {
  let result = await opsService.postdata(
    getFixtureContestsByfixtureId,
    data,
    token
  );
  return result;
};
const deleteFixtureContest = async (data, token) => {
  let result = await opsService.postdata(
    deleteFixtureContests,
    data,
    token
  );
  return result;
};
const getTotalContest = async (data, token) => {
  let result = await opsService.getData(
    getTotalContests,
    data,
    token
  );
  return result;
};
const getFixtureContestById = async (data, token) => {
  let result = await opsService.postdata(
    getFixtureContestsByIdApi,
    data,
    token
  );
  return result;
};
export {
  getTournament,
  getFixturesData,
  getTemplateName,
  addFixtureContest,
  getFixtureContext,
  deleteFixtureContest,
  allFixtureContest,
  getFixtureContestById,
  // insertMatch,
  getcategory,
  addcategory,
  getcategorydatabyid,
  categorydelete,
  categoryupdates,

  // getcontest,
  getContestCombine,
  // conteststatus,
  addcontest,
  getcontestdatabyid,
  deleteContest,
  contestpointupdates,
  getTotalContest,
  // getTotalContestFilter,
  addnotification,
  getnotification,
  
 
  
};
