import * as opsService from "./Ops";
import { baseUrl } from "../Pages/BaseUrl";
const getRank = async(token) => {
    let result = await opsService.getData(baseUrl + "/get-rankcategory",token);
    return result;
  };
  const getRankById = async(data,token) => {
    let result = await opsService.postdata(baseUrl + "/get-rankcategorybyid", data,token);
    return result;
  };
  const addRank = async(data,token) => {
    let result = await opsService.postdata(baseUrl + "/add-rankcategory", data,token);
    return result;
  };
  const updateRank = async(data,token) => {
    let result = await opsService.postdata(baseUrl + "/update-rankcategory", data,token);
    return result;
  };
  const deleteRank = async (data, token) => {
    let result = await opsService.postdata(
      baseUrl +"/delete-rankcategory",
      data,
      token
    );
    return result;
  };
  export {
    addRank,
    getRank,
    getRankById, 
    deleteRank,
    updateRank

   
  
  };