import React, { useState, useEffect } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ReactDatatable from "@mkikets/react-datatable";
import configs from "../Constant/Config";
import Button from "react-bootstrap/Button";
// import { baseUrls } from "./BaseUrl";
import { baseUrls } from "../Constant/BaseUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFixturePlayer } from "../services/fixturePlayer";
import { getplayerByID, UpdateplayerImage } from "../services/player";
function FixturePlayer() {
  const { id } = useParams();
  const [playerId, setPlayerId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [playerRecord, setPlayerRecord] = useState([]);
  const [percentage, setPercentage] = useState("")
  // console.log("playerid",playerId)
  const [record, setRecord] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  useEffect(() => {
    getdata();
  }, [refresh]);
  const getdata = async () => {
    const data = {
      fixtureId: id,
    };
    let res = await getFixturePlayer(data, configs);
    if (res.status) {
      console.log("res----", res);
      setRecord(res.data);
    }
  };
  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "player_name",
      text: "Player Name",
      className: "name",
      align: "left",
      sortable: true,
      // cell:((record)=>{
      //    return(
      //     <Link to={`/fixturescontestlist/${record.id}`}>{record.match_name}</Link>
      //    )
      // })
    },
    {
      key: "team_name",
      text: "Team Name",
      className: "Category",
      align: "left",
      sortable: true,
    },
    {
      key: "player_role",
      text: "Player Role",
      className: "Role",
      align: "left",
      sortable: true,
    },
    {
      key: "team_key",
      text: "Team Key",
      className: "type",
      align: "left",
      sortable: true,
    },
    {
      key: "credit",
      text: "Credit",
      className: "type",
      align: "left",
      sortable: true,
    },
    {
      key: "points",
      text: "Points",
      className: "type",
      align: "left",
      sortable: true,
    },
    {
      key: "selected_player",
      text: "Selected",
      className: "type",
      align: "left",
      sortable: true,
    },
    {
      key: "player_photo",
      text: "Image",
      className: "player_img",
      align: "left",
      sortable: true,
      cell: (response, index) => {
        return (
          <span className="change-player-image position-relative">
            <img
              src={
                response.player_photo == null
                  ? "/assets/images/person-icon.png"
                  : baseUrls + `/static/playerPic/${response.player_photo}`
              }
              alt="hfghfgh"
              className="img-fluid"
            />
          </span>
        );
      },
    },
    {
      key: "view",
      text: "View",
      className: "view1",
      align: "left",
      sortable: true,
      cell: (res) => {
        return (
          <button
            type="button"
            onClick={() => {
              recordView(res.id);
            }}
          >
            View
          </button>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "type view",
      align: "left",
      sortable: true,
      cell: (response, index) => {
        return (
          <div className="edit-profile ">
            <input
              type="file"
              // ref={myref}
              id="img1"
              accept="image/png, image/jpeg, image/jpg"
              style={{ display: "none" }}
              onChange={(e) => {
                saveFile(e);
              }}
              name="playerphoto"
            />
            <label
              htmlFor="img1"
              className="editlabel"
              id={response.playerId}
              onClick={() => setPlayerId(response.playerId)}
            >
              <i className="fa fa-edit"></i>
            </label>
          </div>
        );
      },
    },
  ];
  const config = {
    page_size: 20,
    length_menu: [20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Filter in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const saveFile = async (e) => {
    e.preventDefault();

    if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
      toast.error("Select valid image format");
      return false;
    }

    const formData = new FormData();
    formData.append("playerphoto", e.target.files[0]);
    formData.append("playerid", playerId);
    try {
      let result = await UpdateplayerImage(formData, configs);

      if (result.status) {
        toast.success("Image updated Successfully");
        setRefresh(!refresh);
        // getdata()
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  const recordView = async (playerId) => {
    const data = {
      id: playerId,
      fixtureId:id
    };

    let result = await getplayerByID(data, configs);
    handleShow();
    if (result.status) {
      setPlayerRecord(result.data);
      setPercentage(result.percentage)
    }
  };
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content ">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>Fixture Player</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {/* <button
                    onClick={() => {
                     setTab("not_started")
                      filterData("not_started");
                    }}
                    style={{background:Tab==="not_started"?"green":""}}
                  >
                    Upcoming
                  </button>
                  <button
                    onClick={() => {
                     setTab("started")
                      filterData("started");
                    }}
                    style={{background:Tab==="started"?"green":""}}
                  >
                    Live
                  </button>
                  <button
                    onClick={() => {
                      setTab("completed")
                      filterData("completed");
                    }}
                    style={{background:Tab==="completed"?"green":""}}
                  >
                    completed
                  </button> */}

                  <div className="buttonDirection"></div>
                  <div className="product-list-outer">
                    <ReactDatatable
                      config={config}
                      records={record}
                      columns={columns}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-12">
                  {/* <Tabs
                    defaultActiveKey="Live"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                  >
                    <Tab eventKey="Upcoming" title="Upcoming">
                      <div className="row">
                        <div className="col-xxl-5 text-center">
                          <div className="product-list-outer">
                            <ReactDatatable
                              config={configForUpcoming}
                              records={upcomingData}
                              columns={columnForUpcoming}
                              onPageChange={pageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="Live" title="Live">
                      <div className="row">
                        <div className="col-xxl-5 text-center">
                          <div className="product-list-outer">
                            <ReactDatatable
                              config={configForLive}
                              records={liveData}
                              columns={columnForLive}
                              onPageChange={pageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="Complete" title="Complete">
                      {" "}
                      <div className="row">
                        <div className="col-xxl-5 text-center">
                          <div className="product-list-outer">
                            <ReactDatatable
                              config={configForCompleted}
                              records={record}
                              columns={columnForCompleted}
                              onPageChange={pageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs> 
                  <ReactDatatable
                              config={configForCompleted}
                              records={record}
                              columns={columnForCompleted}
                              onPageChange={pageChange}
                            />
                </div>
              </div> */}
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className="playermodal">
                  {" "}
                  <Modal.Title style={{ color: "black" }}>
                    <div>
                      <div className="row">
                        <div className="col-md-3 text-center">
                          <span className="change-player-image position-relative">
                          <img
                            src={
                              playerRecord[0]?.player_image == null
                                ? "/assets/images/person-icon.png"
                                : baseUrls +
                                  `/static/playerPic/${playerRecord[0]?.player_image}`
                            }
                            alt="hfghfgh"
                            class="img-fluid player"
                          />
                          </span>
                         
                          <h5>{playerRecord[0]?.player_name}</h5>
                        </div>
                        <div className="col-md-3">
                          <p>Selected By</p>
                          <p>{parseInt(percentage)}%</p>
                        </div>
                        <div className="col-md-3">
                          <p>Credits</p>
                          <p> {playerRecord[0]?.credit}</p>
                        </div>
                        <div className="col-md-3">
                          <p>Points</p>
                          <p> {playerRecord[0]?.points}</p>
                        </div>
                      </div>
                    </div>
                    {/* {playerRecord[0]?.player_name} */}
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body className="playerdatatable">
                  
                  {playerRecord.map((list) => {
                    let batting = JSON.parse(list.batting);
                    let bowling = JSON.parse(list.bowling);
                    let fielding = JSON.parse(list.fielding);
                    return (
                      <>
                        {batting ? (
                          <>
                            <h4>Batting</h4>
                            <table>
                              <thead>
                                <tr>
                                  <th>Run</th>
                                  <th>Ball</th>
                                  <th>Fours</th>
                                  <th>Sixs</th>
                                  <th>Strike Rate</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{batting.runs}</td>
                                  <td>{batting.balls}</td>
                                  <td>{batting.fours}</td>
                                  <td>{batting.sixes}</td>
                                  <td>{batting.strike_rate}</td>
                                </tr>
                              </tbody>
                            </table>
                            {/* <p>{list.batting}</p> */}
                          </>
                        ) : (
                          ""
                        )}
                        {bowling ? (
                          <>
                            <h4>Bowling</h4>
                            <table>
                              <thead>
                                <tr>
                                  <th>Over</th>
                                  <th>Maiden Over</th>
                                  <th>Runs</th>
                                  <th>Wicket</th>
                                  <th>Economy</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {bowling.overs[0]}.{bowling.overs[1]}
                                  </td>
                                  <td>{bowling.maiden_overs}</td>
                                  <td>{bowling.runs}</td>
                                  <td>{bowling.wickets}</td>
                                  <td>{bowling.economy}</td>
                                </tr>
                              </tbody>
                            </table>
                            {/* <p>{list.bowling}</p> */}
                          </>
                        ) : (
                          ""
                        )}
                        {fielding ? (
                          <>
                            <h4>Fielding</h4>
                            <table>
                              <thead>
                                <tr>
                                  <th>Catches</th>
                                  <th>Stumps</th>
                                  <th>RunOuts</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{fielding.catches}</td>
                                  <td>{fielding.stumpings}</td>
                                  <td>{fielding.runouts}</td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        ) : (
                          ""
                        )}
                        <h3>Points</h3>
                          
                          <table>
                            <thead>
                              <tr>
                                <th>
                                  <span>Events</span>
                                </th>
                                <th>
                                <span>Points</span>
                                  </th>
                                  <th>
                                  <span>Actual</span>
                                  </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><span>Four</span></td>
                                <td><span>{list.is_four}</span></td>
                                <td><span>{batting ? batting.fours:0}</span></td>
                              </tr>
                              <tr>
                                <td><span>Six</span></td>
                                <td><span>{list.is_six}</span></td>
                                <td><span>{batting ? batting.sixes:0}</span></td>
                              </tr>
                              <tr>
                                <td><span>Run</span></td>
                                <td><span>{list.run}</span></td>
                                <td><span>{batting ? (parseInt(batting.runs)-((parseInt(batting.sixes)*6)+(parseInt(batting.fours)*4))):0}</span></td>
                              </tr>
                              <tr>
                                <td><span>Wicket</span></td>
                                <td><span>{list.is_wicket}</span></td>
                                <td><span>{bowling ? bowling.wickets:0}</span></td>
                              </tr>
                              <tr>
                                <td><span>Stump</span></td>
                                <td><span>{list.is_stump}</span></td>
                                <td><span>{fielding ? fielding.stumpings:0}</span></td>
                              </tr>
                              <tr>
                                <td><span>RunOut</span></td>
                                <td><span>{list.is_run_out}</span></td>
                                <td><span>{fielding ? fielding.runouts:0}</span></td>
                              </tr>
                              <tr>
                                <td><span>Catch</span></td>
                                <td><span>{list.is_catch}</span></td>
                                <td><span>{fielding ? fielding.catches:0}</span></td>
                              </tr>
                              <tr>
                                <td><span>Total Points</span></td>
                                <td><span>{playerRecord[0]?.points}</span></td>
                                <td></td>
                                
                              </tr>
                            </tbody>
                          </table>


        
                      </>
                    );
                  })}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FixturePlayer;
