import React, { useState, useEffect, Fragment } from "react";
// import ReactDatatable from "@mkikets/react-datatable";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
// import Modal from "react-bootstrap/Modal";
import { useParams, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import configs from "../Constant/Config";
// import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import {
  // getContestCombine,
  getcategory,
  addFixtureContest,
  getcontestdatabyid,
  getTemplateName,
  // deleteContest,
  // contestpointupdates,
} from "../services/contest";

function Fixturescontest() {
  const { id } = useParams();
  const navigate=useNavigate()
  const [templateName, setTemplateName] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const initialValue = {
    rankfrom: "",
    torank: "",
    totalpercentage: "",
    prize: "",
    totalprizes: "",
  };
  const [formValueserr, setFormValueserr] = useState([{  }]);
  const [updateformValues, setupdateformValues] = useState([{ initialValue }]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [contesttype, setcontesttype] = useState("Paid");
  const [totalprize, settotalprize] = useState("");
  const [adminPercentage, setAdminpercentage] = useState("0");
  const [entryfees, setentryfees] = useState("");
  const [maxteamperuser, setmaxteamperuser] = useState(2);
  const [autocreate, setautocreate] = useState("yes");
  const [addSpots, setAddSpots] = useState("10")
  const [contestcategory, setcontestcategory] = useState("");
  const [bonus, setbonus] = useState("");
  const [dynamiccontest, setdynamiccontest] = useState("Normal");
  // const [s_time, sets_time] = useState("");
  // const [e_time, sete_time] = useState("");
  // const [s_date, sets_date] = useState("");
  // const [e_date, sete_date] = useState("");
  const [totalSpots, setTotalSpots] = useState("");
  const [firstPrize, setFirstPrize] = useState("")
  
  const [categorydata, setCategorydata] = useState([]);

  // const [show, setShow] = useState(false);

  // const [contestid, setcontestid] = useState([]);
  // const [updateshow, setupdateshow] = useState(false);

  const [nameerr, setNameerr] = useState(false);
  const [descriptionerr, setDescriptionerr] = useState(false);
  const [contesttypeerr, setcontesttypeerr] = useState(false);
  const [totalprizeerr, settotalprizeerr] = useState(false);
  const [entryfeeserr, setentryfeeserr] = useState(false);
  const [adminPercentageErr, setAdminPercentageErr] = useState(false);
  const [maxteamsperusererr, setmaxteamsperusererr] = useState(false);

  const [autocreateerr, setautocreateerr] = useState(false);
  const [contestcategoryerr, setcontestcategoryerr] = useState(false);
  const [bonuserr, setbonuserr] = useState(false);
  const [dynamiccontesterr, setdynamiccontesterr] = useState(false);
  // const [s_timeErr, sets_timeErr] = useState(false);
  // const [e_timeErr, sete_timeErr] = useState(false);
  // const [s_dateErr, sets_dateErr] = useState(false);
  // const [e_dateErr, sete_dateErr] = useState(false);
  const [totalSpotsErr, settotalSpotsErr] = useState(false);
  const [firstPrizeErr, setFirstPrizeErr] = useState(false)
  // const [formValues, setFormValues] = useState([{ initialValue }]);
  const [selectTemplate, setSelectTemplate] = useState("");
  const handlechange = (e) => {
    const { name, value } = e.target;

    const validateInput = (name, value) => {
      if (value === "" || value === undefined || value.match(/^\s/)) {
        return ["This field is required", false];
      } else {
        return ["", true];
      }
    };
    const validatePercentage = (name, value) => {
      if (value === "" || value === undefined) {
        return ["This field is required", false];
      }
      if (value >= 100) {
        return ["Please enter the valid value", false];
      } else {
        return ["", true];
      }
    };
    const validateSpots = (name, value) => {
      if (value === "" || value === undefined) {
        return ["This field is required", false];
      }
      if (value == 0) {
        return ["Please enter the valid value", false];
      } else {
        return ["", true];
      }
    };
    switch (name) {
      case "name":
        const [nameErr] = validateInput(name, value);
        setName(value);
        setNameerr(nameErr);
        break;

      case "description":
        const [descErr] = validateInput(name, value);
        setDescription(value);
        setDescriptionerr(descErr);
        break;

      case "contesttype":
        const [contestTypeErr] = validateInput(name, value);
        setcontesttype(value);
        setcontesttypeerr(contestTypeErr);

        if (value === "Free") {
          setIsDisabled(true);
          setentryfeeserr("");
          setAdminPercentageErr("");
          settotalprize("0");
          setAdminpercentage("0");
          setautocreate("no")
        } else {
          setIsDisabled(false);
        }
        break;

      case "totalprize":
        const [totalPrizeErr] = validateInput(name, value);
        settotalprize(value);
        settotalprizeerr(totalPrizeErr);
        break;

      case "entryfees":
        const [entryFeesErr] = validateInput(name, value);
        setentryfees(value);
        setentryfeeserr(entryFeesErr);
        if (adminPercentage && totalSpots) {
          let totalvalue = parseInt(value) * parseInt(totalSpots);
          let per = totalvalue * (parseFloat(adminPercentage) / 100);
          let totalprizes = parseFloat(totalvalue) - parseFloat(per);
          settotalprize(totalprizes.toFixed(2));
        }
        break;
        case "adminPercentage":
          const [adminPercentageErr] = validatePercentage(name, value);
          setAdminpercentage(value);
          setAdminPercentageErr(adminPercentageErr);
          if (entryfees && totalSpots) {
            let totalvalue = parseInt(entryfees) * parseInt(totalSpots);
            let per = totalvalue * (parseFloat(value) / 100);
            let totalprizes = parseFloat(totalvalue) - parseFloat(per);
            settotalprize(totalprizes.toFixed(2));
          }
          break;

      case "maxteamsperuser":
        const [maxTeamsErr, isMaxTeamsValid] = validateInput(name, value);
        setmaxteamperuser(value);

        if (isMaxTeamsValid) {
          const regex = /^[-+]?[0-9]+\.[0-9]+$/;
          const isValidated = regex.test(value);

          if (isValidated) {
            setmaxteamsperusererr("Enter valid format");
          } else {
            setmaxteamsperusererr("");
          }
        } else {
          setmaxteamsperusererr(maxTeamsErr);
        }
        break;

      case "autocreate":
        const [autoCreateErr] = validateInput(name, value);
        setautocreate(value);
        setautocreateerr(autoCreateErr);
        break;
        case "addspots":
      
        setAddSpots(value);
       
        break;

      case "contestcategory":
        const [contestCatErr] = validateInput(name, value);
        setcontestcategory(value);
        setcontestcategoryerr(contestCatErr);
        break;

      case "bonus":
        setbonus(value);

        if (value !== "" && value <= 0) {
          setbonuserr("Enter valid input value");
        } else {
          setbonuserr("");
        }
        break;

      case "dynamiccontest":
        const [dynamicContestErr] = validateInput(name, value);
        setdynamiccontest(value);
        setdynamiccontesterr(dynamicContestErr);
        break;
      // case "totalSopts":
      //   const [totalSpotsErr] = validateInput(name, value);
      //   setTotalSpots(value);
      //   settotalSpotsErr(totalSpotsErr);
      //   break;
        case "totalSopts":
        const [totalSpotsErr] = validateSpots(name, value);
        setTotalSpots(value);
        settotalSpotsErr(totalSpotsErr);
        if (entryfees && adminPercentage) {
          let totalvalue = parseInt(entryfees) * parseInt(value);
          let per = totalvalue * (parseFloat(adminPercentage) / 100);
          let totalprizes = parseFloat(totalvalue) - parseFloat(per);
          settotalprize(totalprizes.toFixed(1));
        }
        break;
        case "firstPrize":
          const [firstPrizeErr] = validateInput(name, value);
          setFirstPrize(value);
          setFirstPrizeErr(firstPrizeErr);
          break;
      // case "s_time":
      //   const [s_timeErr] = validateInput(name, value);
      //   sets_time(value);
      //   sets_timeErr(s_timeErr);
      //   break;
      // case "e_time":
      //   const [e_timeErr] = validateInput(name, value);
      //   sete_time(value);
      //   sete_timeErr(e_timeErr);
      //   break;
      // case "s_date":
      //   const [s_dateErr] = validateInput(name, value);
      //   sets_date(value);
      //   sets_dateErr(s_dateErr);
      //   break;
      // case "e_date":
      //   const [e_dateErr] = validateInput(name, value);
      //   sete_date(value);
      //   sete_dateErr(e_dateErr);
      //   break;

      default:
        break;
    }
  };
  let addFormupdateFields = () => {
    setupdateformValues([...updateformValues, {}]);
    setFormValueserr([...formValueserr, { initialValue }]);
  };

  let updateremoveFormFields = (i) => {
    let newFormValues = [...updateformValues];
    newFormValues.splice(i, 1);
    setupdateformValues(newFormValues);
  };
  let handleChangemoreupdatefield = (e, i) => {
 
    let newFormValuess = [...updateformValues];
    let newFormValuesErr = [...formValueserr];

    newFormValuess[i][e.target.name] = e.target.value;
    setupdateformValues(newFormValuess);
    
    if (e.target.name === "rankfrom") {
      
      if (
        !newFormValuess[i].rankfrom ||
        newFormValuess[i].rankfrom == undefined
      ) {
        newFormValuesErr[i][e.target.name] = "This field is required";
        setupdateformValues(newFormValuess);
        setFormValueserr(newFormValuesErr);
        
        return false;
      } else {
      
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setupdateformValues(newFormValuess);
        //  if(newFormValuess[i].prize){
        //   console.log("prize4",newFormValuess[i].prize)
        //   let rankfrom=newFormValuess[i].rankfrom
        //   let rankto=newFormValuess[i].torank
          
        //   newFormValuess[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValuess[i].prize
        //   setupdateformValues(newFormValuess);
        //  }
        if(newFormValuess[i].rankfrom && newFormValuess[0].rankfrom!="1"){
      
          newFormValuesErr[0][e.target.name] = "Rank will be started 1";
          setFormValueserr(newFormValuesErr);
         }
       
        
        if(newFormValuess[i].torank && newFormValuess[i].rankfrom>newFormValuess[i].torank){
       
          newFormValuesErr[i]["torank"] = "Please enter the valid rank";
          setFormValueserr(newFormValuesErr);
         }
    
         if(newFormValuess[i-1].torank && newFormValuess[i].rankfrom==newFormValuess[i-1].torank ){
     

            newFormValuesErr[i][e.target.name] = "Please enter a valid rank";
            setFormValueserr(newFormValuesErr);
          
         }
         if(newFormValuess[i-1].torank &&  parseInt(newFormValuess[i].rankfrom)!==parseInt((newFormValuess[i-1].torank))+1){
          newFormValuesErr[i][e.target.name] = "Please enter a valid rank";
          setFormValueserr(newFormValuesErr);
         }
        if (
          newFormValuess[i].torank &&
          newFormValuess[i].totalpercentage &&
          totalprize
        ) {
          if (newFormValuess[i].totalpercentage == 0) {
          
            newFormValuess[i]["prize"]="0"
            newFormValuess[i]["totalprizes"]="0"
          } else {
            if (
              newFormValuess[i].torank &&
              newFormValuess[i].totalpercentage &&
              totalprize
            ) {
              let rankfrom = newFormValuess[i].rankfrom;
              let rankto = newFormValuess[i].torank;
              let per = newFormValuess[i].totalpercentage;
              let totalprizes =
                parseFloat(totalprize) * (parseFloat(per) / 100);
              let totalrank = parseInt(rankto) - parseInt(rankfrom) + 1;
              let perPrize = parseFloat(totalprizes) / parseInt(totalrank);
              // newFormValues[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValues[i].prize
              newFormValuess[i]["prize"] = perPrize;
              newFormValuess[i]["totalprizes"] = totalprizes;
              setupdateformValues(newFormValuess);
              // setFormValues(newFormValues);
            }
          }
        }
    
      }
    }

    if (e.target.name === "torank") {
      if (!newFormValuess[i].torank || newFormValuess[i].torank === undefined) {
        newFormValuesErr[i][e.target.name] = "This field is required";

        setupdateformValues(newFormValuess);
        setFormValueserr(newFormValuesErr);
        return false;
      } else {
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setupdateformValues(newFormValuess);
        // if (newFormValuess[i].prize){
        //   let rankfrom=newFormValuess[i].rankfrom
        //   let rankto=newFormValuess[i].torank
          
        //   newFormValuess[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValuess[i].prize
        //   setupdateformValues(newFormValuess);
        // }
        
        if(newFormValuess[i].rankfrom &&  parseInt(newFormValuess[i].rankfrom)>newFormValuess[i].torank){
        
          newFormValuesErr[i][e.target.name] = "Please enter the valid rank";
          setFormValueserr(newFormValuesErr);
         }
        if (
          newFormValuess[i].rankfrom &&
          newFormValuess[i].totalpercentage &&
          totalprize
        ) {
          if (newFormValuess[i].totalpercentage == 0) {
        
            newFormValuess[i]["prize"] = "0";
            newFormValuess[i]["totalprizes"] = "0";
          } else {
            if (
              newFormValuess[i].rankfrom &&
              newFormValuess[i].totalpercentage &&
              totalprize
            ) {
              let rankfrom = newFormValuess[i].rankfrom;
              let rankto = newFormValuess[i].torank;
              let per = newFormValuess[i].totalpercentage;
              let totalprizes = parseFloat(totalprize) * (parseFloat(per) / 100);
              let totalrank = parseInt(rankto) - parseInt(rankfrom) + 1;
              let perPrize = parseFloat(totalprizes) / parseInt(totalrank);
              // newFormValues[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValues[i].prize
              newFormValuess[i]["prize"] = perPrize;
              newFormValuess[i]["totalprizes"] = totalprizes;
              setupdateformValues(newFormValuess);
              // setFormValues(newFormValues);
            }
          }
        }
      }
    }

    if (e.target.name === "totalpercentage") {
      if (
        !newFormValuess[i].totalpercentage ||
        newFormValuess[i].totalpercentage === undefined
      ) {
        newFormValuesErr[i][e.target.name] = "This field is required";

        setupdateformValues(newFormValuess);
        setFormValueserr(newFormValuesErr);
        return false;
      } else {
        newFormValuesErr[i][e.target.name] = "";
        setFormValueserr(newFormValuesErr);
        setupdateformValues(newFormValuess);
        if (newFormValuess[i].totalpercentage == 0) {
              newFormValuess[i]["prize"] = "0";
          newFormValuess[i]["totalprizes"] = "0";
        } else {
          if (
            newFormValuess[i].rankfrom &&
            newFormValuess[i].torank &&
            totalprize
          ) {
            let rankfrom = newFormValuess[i].rankfrom;
            let rankto = newFormValuess[i].torank;
            let per = newFormValuess[i].totalpercentage;
            let totalprizes = parseFloat(totalprize) * (parseFloat(per) / 100);
            let totalrank = parseInt(rankto) - parseInt(rankfrom) + 1;
            let perPrize = parseFloat(totalprizes) / parseInt(totalrank);
       

            // newFormValues[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValues[i].prize
            newFormValuess[i]["prize"] = perPrize;
            newFormValuess[i]["totalprizes"] = totalprizes;
            setupdateformValues(newFormValuess);
            // setFormValues(newFormValues);
          }
        }
      }
    }

    // if (e.target.name === "prize") {
    //   console.log("prize5",newFormValuess[i].prize)
    //   if (!newFormValuess[i].prize || newFormValuess[i].prize === undefined) {
    //     newFormValuesErr[i][e.target.name] = "This field is required";

    //     setupdateformValues(newFormValuess);
    //     setFormValueserr(newFormValuesErr);
    //     return false;
    //   } else {
    //     newFormValuesErr[i][e.target.name] = "";
    //     setFormValueserr(newFormValuesErr);
    //     setupdateformValues(newFormValuess);
    //     console.log("i",newFormValuess,i)
    //   if(newFormValuess[i].rankfrom && newFormValuess[i].torank){
    //     let rankfrom=newFormValuess[i].rankfrom
    //     let rankto=newFormValuess[i].torank
        
    //     newFormValuess[i]["totalprizes"] = ((rankto-rankfrom)+1)*newFormValuess[i].prize
    //     setupdateformValues(newFormValuess);
    //    }
    //   }
      
    // }

    // if (e.target.name === "totalprizes") {
    //   if (
    //     !newFormValuess[i].totalprizes ||
    //     newFormValuess[i].totalprizes === undefined
    //   ) {
    //     newFormValuesErr[i][e.target.name] = "This field is required";

    //     setupdateformValues(newFormValuess);
    //     setFormValueserr(newFormValuesErr);
    //     return false;
    //   } else {
    //     newFormValuesErr[i][e.target.name] = "";
    //     setFormValueserr(newFormValuesErr);
    //     setupdateformValues(newFormValuess);
    //   }
    // }
  };
  const getcategorydata = async () => {
    let result = await getcategory(configs);
    if (result.status === true) {
      setCategorydata(result.data.categorydata);
    }
  };
  const iddata = async (id) => {
    let datas = {
      id,
    };
    let data = await getcontestdatabyid(datas, configs);
    const {
      contestName,
      description,
      contesttype,
      totalprize,
      entryfees,
      maxteamperuser,
      autocreate,
      categoryId,
      bonus,
      dynamic,
      // start_time,
      // start_date,
      // end_time,
      // end_date,
      total_spots,
      first_prize,
      admin_percentage,
      add_spots
    } = data.data.databyid[0];

    setName(contestName);
    setNameerr(false)
    setDescription(description);
    setDescriptionerr(false)
    setcontesttype(contesttype);
    setcontesttypeerr(false)
    settotalprize(parseFloat(totalprize).toFixed(2));
    settotalprizeerr(false)
    if (contesttype === "Free") {
      setIsDisabled(true);
    }
    setentryfees(entryfees);
    setentryfeeserr(false)
    setmaxteamperuser(maxteamperuser);
    setmaxteamsperusererr(false)
    setautocreate(autocreate);
    setautocreateerr(false)
    setcontestcategory(categoryId);
    setcontestcategoryerr(false)
    setbonus(bonus);
    setbonuserr(false)
    setdynamiccontest(dynamic);
    setdynamiccontesterr(false)
    // sets_time(start_time);
    // sets_date(moment(start_date).format("YYYY-MM-DD"));
    // sete_time(end_time);
    // sete_date(moment(end_date).format("YYYY-MM-DD"));
    setupdateformValues(data.data.databyid2);
   
    setTotalSpots(total_spots)
    settotalSpotsErr(false)
    setFirstPrize(first_prize)
    setFirstPrizeErr(false)
    setAdminpercentage(admin_percentage)
    setAdminPercentageErr(false)
    setAddSpots(add_spots)
    var arr=[]
    for(var i=0;i<(data.data.databyid2).length;i++){
      arr.push({})
    }
    setFormValueserr(arr)

  };
  useEffect(() => {
    getcategorydata();
    gettemplatename();
  }, []);
  const gettemplatename = async () => {
    let result = await getTemplateName(configs);
    if (result.status === true) {
      setTemplateName(result.data);
    }
  };
  const handleChangeTemplate = (e) => {
    setSelectTemplate(e.target.value);
    iddata(e.target.value);
  };
  const contestInsert = async () => {
    if (!name) {
      setNameerr("This field is required");
      return false;
    }
    if (name.match(/^\s/)) {
      setNameerr("This field is required");
      return false;
    }
    if (!description) {
      setDescriptionerr("This field is required");
      return false;
    }
    if (description.match(/^\s/)) {
      setDescriptionerr("This field is required");
      return false;
    }
    // if (!contesttype) {
    //   setcontesttypeerr("This field is required");
    //   return false;
    // }
  
    if(entryfees!=0){
      if(!entryfees){
        setentryfeeserr("This field is required");
          return false;
      }
      if (entryfees !== "") {
        if (entryfees <= 0) {
          setentryfeeserr("Enter correct input value");
          return false;
        }
      }
      if (!adminPercentage) {
        setAdminPercentageErr("This field is required");
        return false;
      }
    }
   
    if (!totalSpots) {
      settotalSpotsErr("This field is required");
      return false;
    }
    if (totalSpots<2) {
      settotalSpotsErr("Please enter minimum 2 spots");
      return false;
    }
    if (!totalprize) {
      settotalprizeerr("This field is required");
      return false;
    }
    if (totalprize < 0) {
      settotalprizeerr("Enter correct input value");
      return false;
    }
    if (!maxteamperuser) {
      setmaxteamsperusererr("This field is required");
      return false;
    }
    var regex = /^[-+]?[0-9]+\.[0-9]+$/;
    var isValidated = regex.test(maxteamperuser);

    if (isValidated) {
      setmaxteamsperusererr("Enter valid format");
      return false;
    }
    if (maxteamperuser < 2) {
      setmaxteamsperusererr("Minimum 2 player are required");
      return false;
    }
    if (maxteamperuser < 2) {
      setmaxteamsperusererr("Enter correct input value");
      return false;
    }
    if (!autocreate) {
      setautocreateerr("This field is required");
      return false;
    }
    if (!contestcategory) {
      setcontestcategoryerr("This field is required");
      return false;
    }

    if (!dynamiccontest) {
      setdynamiccontesterr("This field is required");
      return false;
    }
   
    if (!firstPrize) {
      setFirstPrizeErr("This field is required");
      return false;
    }
    // if (!s_time) {
    //   sets_timeErr("This field is required");
    //   return false;
    // }
    // if (!s_date) {
    //   sets_dateErr("This field is required");
    //   return false;
    // }
    // if (!e_time) {
    //   sete_timeErr("This field is required");
    //   return false;
    // }
    // if (!e_date) {
    //   sete_dateErr("This field is required");
    //   return false;
    // }
    for (let i = 0; i < updateformValues.length; i++) {
      let newFormValuesErr = [...formValueserr];

      if (
        !updateformValues[i].rankfrom ||
        updateformValues[i].rankfrom === undefined
      ) {
        newFormValuesErr[i].rankfrom = "This field is required";

        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (updateformValues[i].rankfrom <= 0) {
        newFormValuesErr[i].rankfrom = "Enter valid input format";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if(updateformValues[i].rankfrom && updateformValues[0].rankfrom!="1"){
        newFormValuesErr[0].rankfrom = "Rank will be started 1";
        setFormValueserr(newFormValuesErr);
        return false;
       }
       if(updateformValues[i].torank && parseFloat(updateformValues[i].rankfrom)>parseFloat(updateformValues[i].torank)){
        newFormValuesErr[i].torank = "Please enter the valid rank";
        setFormValueserr(newFormValuesErr);
        return false;
       }
       if(updateformValues[i-1]?.torank && updateformValues[i].rankfrom==updateformValues[i-1].torank ){
        newFormValuesErr[i].rankfrom = "Please enter a valid rank";
        setFormValueserr(newFormValuesErr);
        return false;
     }
      if(updateformValues[i-1]?.torank &&  parseInt(updateformValues[i].rankfrom)!==parseInt((updateformValues[i-1].torank))+1){
      newFormValuesErr[i].rankfrom = "Please enter a valid rank";
      setFormValueserr(newFormValuesErr);
        return false;
     }

      if (!updateformValues[i].torank) {
        newFormValuesErr[i].torank = "This field is required";
        setFormValueserr(newFormValuesErr);
        return false;
      }
   
      if (parseFloat(updateformValues[i].torank) < parseFloat(updateformValues[i].rankfrom)) {
        newFormValuesErr[i].rankfrom = "Enter valid rank format";
        setFormValueserr(newFormValuesErr);
        return false;
      }
    
      if (!updateformValues[i].totalpercentage) {
        newFormValuesErr[i].totalpercentage = "This field is required";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (parseFloat(updateformValues[i].totalpercentage) < 0) {
        newFormValuesErr[i].totalpercentage = "Enter valid input format";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (updateformValues[i].prize==="") {
        newFormValuesErr[i].prize = "This field is required111";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      if (updateformValues[i].prize < 0) {
        newFormValuesErr[i].prize = "Enter valid input format";
        setFormValueserr(newFormValuesErr);
        return false;
      }
      // console.log("fghdggdffdf",updateformValues[i].totalprizes)
      // if (!updateformValues[i].totalprizes) {
      //   newFormValuesErr[i].totalprizes = "This field is required111";
      //   setFormValueserr(newFormValuesErr);
      //   return false;
      // }
      if (updateformValues[i].totalprizes < 0) {
        newFormValuesErr[i].totalprizes = "Enter valid input format";
        setFormValueserr(newFormValuesErr);

        return false;
      }
    }
    let totalvalue=0
    for (let i = 0; i < updateformValues.length; i++){
      totalvalue=parseFloat(totalvalue)+parseFloat(updateformValues[i].totalprizes)
    }
    console.log("parixwe",parseFloat(totalvalue)!=parseFloat(totalprize).toFixed(1),totalvalue.toFixed(1),totalprize)
    if(parseFloat(totalvalue).toFixed(2)!=parseFloat(totalprize).toFixed(2)){
      toast.error("Distributed amount is not equal to total amount")
      return false
    }
    let data = {
      id: id,
      contestName: name,
      description,
      contesttype,
      totalprize,
      adminPercentage,
      entryfees,
      maxteamperuser,
      autocreate,
      categoryId: contestcategory,
      bonus,
      dynamic: dynamiccontest,
      total_spots: totalSpots,
      first_prize:firstPrize,
      // start_time: s_time,
      // start_date: s_date,
      // end_time: e_time,
      // end_date: e_date,
      addSpots,
      atribute: updateformValues,

    };
    let resp = await addFixtureContest(data, configs);
    if (resp.status === true) {
      toast.dismiss();
      toast.success(resp.message);
      setTimeout(() => {
        navigate(`/fixturescontestlist/${id}`)
      }, 2000);
    }
    else{
      toast.dismiss();
      toast.error(resp.message);
    }
    // combinedata();
  };
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>Contest Templates</b>
                </h2>
              </div>
              <div>
                <select value={selectTemplate} onChange={handleChangeTemplate}>
                  <option value="">Select Contest Template</option>
                  {templateName?.map((list) => {
                    return <option value={list.id} key={list.id}>{list.contestName}</option>;
                  })}
                </select>
              </div>
              <div className="row">
                <div className="col-xxl-5">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center">
                      <Row>
                        <Col xs={12} md={5}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label> Name </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={name}
                              placeholder="Enter Name `"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{nameerr}</span>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label> Description </Form.Label>
                            <Form.Control
                              type="text"
                              name="description"
                              value={description}
                              placeholder="Enter Description"
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>
                              {descriptionerr}
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={2}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label> Contest Type </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="contesttype"
                              onChange={handlechange}
                              value={contesttype}
                            >
                              <option value="Paid">Paid</option>
                              <option value="Free">Free</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        {isDisabled === false || contesttype === "Paid" ? (
                          <>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Entry Fee</Form.Label>
                              <Form.Control
                                type="number"
                                name="entryfees"
                                placeholder="Enter Entry Fee"
                                value={entryfees}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>
                                {entryfeeserr}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label> Admin Percentage </Form.Label>
                                  <Form.Control
                                    type="tel"
                                    name="adminPercentage"
                                    value={adminPercentage}
                                    placeholder="Enter Percentage"
                                    onChange={handlechange}
                                    onKeyPress={(event) => {
                                      if (!/[0-9.]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    maxLength="4"
                                  />
                                  <span style={{ color: "red" }}>
                                    {adminPercentageErr}
                                  </span>
                                </Form.Group>
                              </Col>
                          </>
                        ) : null}
                            <Col xs={12} md={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Total Spots</Form.Label>
                            <Form.Control
                              type="text"
                              name="totalSopts"
                              placeholder="Total Spots"
                              value={totalSpots}
                              onChange={handlechange}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {totalSpotsErr}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label> Total Prize </Form.Label>
                            <Form.Control
                              type="number"
                              name="totalprize"
                              value={totalprize}
                              placeholder="Enter Total Prize"
                              onChange={handlechange}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={contesttype=='Paid'?true:""}
                            />
                            <span style={{ color: "red" }}>
                              {totalprizeerr}
                            </span>
                          </Form.Group>
                        </Col>
                      

                        
                      </Row>

                      <Row>
                      <Col xs={12} md={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Max Teams Per User</Form.Label>
                            <Form.Control
                              type="number"
                              name="maxteamsperuser"
                              placeholder="Max Teams Per User"
                              value={maxteamperuser}
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>
                              {maxteamsperusererr}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Auto Create When Contest Full
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="autocreate"
                              onChange={handlechange}
                              value={autocreate}
                            >
                               {contesttype=="Free"? 
                                <option value="no">No</option>: <><option value="yes">Yes</option>
                                <option value="no">No</option></> }
                              {/* <option value="yes">Yes</option>
                              <option value="no">No</option> */}
                            </Form.Select>
                            <span style={{ color: "red" }}>
                              {autocreateerr}
                            </span>
                          </Form.Group>
                        </Col>
                        {
                            autocreate=='yes'?<Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Add Spots
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="addspots"
                                onChange={handlechange}
                                value={addSpots}
                              >
                               
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>
                                {autocreateerr}
                              </span>
                            </Form.Group>
                          </Col>:""
                          }
                        <Col xs={12} md={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label> Contest Category</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="contestcategory"
                              onChange={handlechange}
                              value={contestcategory}
                            >
                              <option value="">
                                  Please select
                                </option>
                              {categorydata.map((category, index) => {
                                return (
                                  <>
                                    <option value={category.id}>
                                      {category.name}
                                    </option>
                                  </>
                                );
                              })}
                            </Form.Select>
                            <span style={{ color: "red" }}>
                              {contestcategoryerr}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Bonus</Form.Label>
                            <Form.Control
                              type="number"
                              name="bonus"
                              placeholder="bonus"
                              value={bonus}
                              onChange={handlechange}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Dynamic Contest</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="dynamiccontest"
                              onChange={handlechange}
                              value={dynamiccontest}
                            >
                              <option value="Normal">Normal</option>
                              <option value="Dynamic">Dynamic</option>
                            </Form.Select>
                            <span style={{ color: "red" }}>
                              {dynamiccontesterr}
                            </span>
                          </Form.Group>
                        </Col>
                    
                        <Col xs={12} md={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>First Prize</Form.Label>
                            <Form.Control
                              type="text"
                              name="firstPrize"
                              placeholder="First Prize"
                              value={firstPrize}
                              onChange={handlechange}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {firstPrizeErr}
                            </span>
                          </Form.Group>
                        </Col>

                        {/* <Col xs={12} md={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Start Time </Form.Label>
                            <Form.Control
                              type="time"
                              name="s_time"
                              placeholder="Start Time"
                              value={s_time}
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{s_timeErr}</span>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Start Date </Form.Label>
                            <Form.Control
                              type="date"
                              name="s_date"
                              placeholder="Start Time"
                              value={s_date}
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{s_dateErr}</span>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>End Time </Form.Label>
                            <Form.Control
                              type="time"
                              name="e_time"
                              placeholder="End Time"
                              value={e_time}
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{e_timeErr}</span>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>End Date </Form.Label>
                            <Form.Control
                              type="date"
                              name="e_date"
                              placeholder="End Time"
                              value={e_date}
                              onChange={handlechange}
                            />
                            <span style={{ color: "red" }}>{e_dateErr}</span>
                          </Form.Group>
                        </Col> */}
                      </Row>
                      {/* ============add more update================== */}
                      {updateformValues.map((item, index) => {
                        return (
                          <>
                            <Row>
                              <Col xs={12} md={2}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label> Rank From</Form.Label>
                                  <Form.Control
                                    style={{ color: "#1a1d21" }}
                                    type="number"
                                    name="rankfrom"
                                    placeholder=" Enter rankfrom"
                                    value={item.rankfrom || ""}
                                    onChange={(e) =>
                                      handleChangemoreupdatefield(e, index)
                                    }
                                  />
                                  <span style={{ color: "red" }}>
                                    {formValueserr[index]?.rankfrom}
                                  </span>
                                </Form.Group>
                              </Col>
                              <Col xs={12} md={2}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label> To Rank </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="torank"
                                    placeholder="To rank"
                                    value={item.torank}
                                    onChange={(e) =>
                                      handleChangemoreupdatefield(e, index)
                                    }
                                  />
                                  <span style={{ color: "red" }}>
                                    {formValueserr[index]?.torank}
                                  </span>
                                </Form.Group>
                              </Col>

                              <Col xs={12} md={2}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label> Total Percentage</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="totalpercentage"
                                    placeholder=" Total Percentage"
                                    value={item.totalpercentage}
                                    onChange={(e) =>
                                      handleChangemoreupdatefield(e, index)
                                    }
                                  />
                                  <span style={{ color: "red" }}>
                                    {formValueserr[index]?.totalpercentage}
                                  </span>
                                </Form.Group>
                              </Col>
                              <Col xs={12} md={2}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Prize Per User</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="prize"
                                    placeholder="Prize"
                                    value={item.prize}
                                    onChange={(e) =>
                                      handleChangemoreupdatefield(e, index)
                                    }
                                    readOnly
                                  />
                                  <span style={{ color: "red" }}>
                                    {formValueserr[index]?.prize}
                                  </span>
                                </Form.Group>
                              </Col>
                              <Col xs={12} md={2}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label> Total Prize</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="totalprizes"
                                    placeholder=" Total Prize"
                                    value={item.totalprizes}
                                    onChange={(e) =>
                                      handleChangemoreupdatefield(e, index)
                                    }
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    readOnly
                                  />
                                  <span style={{ color: "red" }}>
                                    {formValueserr[index]?.totalprizes}
                                  </span>
                                </Form.Group>
                                {index ? (
                                  <button
                                    type="button"
                                    className="button remove"
                                    onClick={() =>
                                      updateremoveFormFields(index)
                                    }
                                  >
                                    - Remove
                                  </button>
                                ) : null}
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                      <div className="add-row">
                        <button onClick={() => addFormupdateFields()}>
                          + Add More
                        </button>
                      </div>
                    </div>
                    <Button variant="secondary" onClick={contestInsert}>
                      Save
                    </Button>
                    {/* <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Fixturescontest;
