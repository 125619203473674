import React, { useState, useEffect } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import configs from "../Constant/Config";

import { getTermCondition, updateTermCondition } from "../services/termCondition";
export const TermAndCondition = () => {
  const [description, setDescription] = useState("");
  // const [nameerr, setNameerr] = useState("");
  const [record, setRecord] = useState([]);
//   const [disable, setDisable] = useState(true);
  const getData = async () => {
    let data = await getTermCondition(configs);
    setRecord(data.data);
  };
  useEffect(() => {
    getData();
  }, []);

  const onSubmitHandler = async (id) => {
    // if (!name) {
    //   setNameerr("This field is required");
    //   return false;
    // }
    var data
    if(record.length>0){
      data = {
        id: record[0].id,
        description,
      };
    }
    else{
      data = {
        description,
      };
    }
   
    let result = await updateTermCondition(data, configs);
    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
    }
    // setDisable(false);
    getData();

    if (result.status === false) {
      toast.dismiss();
      toast.success(result.message);
    }
    // setDisable(true);
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>Terms And Conditions</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-5 text-center">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center"></div>
                  </div>
                  {record.length>0? <CKEditor
                            editor={ClassicEditor}
                            data={record[0].description}
                            config={{
                              toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
                              heading: {
                                  options: [
                                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                                  ]
                              }
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                            setDescription(data);
                            }}
                          />:<CKEditor
                          editor={ClassicEditor}
                          data={description}
                          config={{
                            toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
                            heading: {
                                options: [
                                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                                ]
                            }
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setDescription(data);
                          }}
                        />}
                        <button
                          onClick={onSubmitHandler}
                          type="button"
                          class="btn btn-primary ml-5 mt-4 "
                        >
                          Save
                        </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
