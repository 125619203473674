import React, { useState, useEffect } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import moment from "moment";
import { getUser } from "../services/user";
import configs from "../Constant/Config";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";

export const Verification = () => {
  const [mainData, setMainData] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("all");

  const columns = [
    {
      key: "id",
      text: "User ID",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },

    {
      key: "userName",
      text: "User Name",
      className: "User Name",
      align: "left",
      sortable: true,
    },
    {
      key: "mobileNumber",
      text: "Mobile Number",
      className: "Mobile Number",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
    },
    
    {
      key: "aadharStatus",
      text: "Aadhaar Status",
      className: "Documents",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record.aadharStatus=="Reject"?"Rejected":record.aadharStatus}</>;
      },
    },
    {
      key: "panStatus",
      text: "Pan Status",
      className: "Documents",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record.panStatus=="Reject"?"Rejected":record.panStatus}</>;
      },
    },
    {
      key: "kycStatus",
      text: "KYC Status",
      className: "Documents",
      align: "left",
      sortable: true,
      cell:(record)=>{
        return (
        <span>{record.kycStatus=="Reject"?"Rejected":record.kycStatus}</span>
        )
      }
    },
    {
      key: "RegistrationDate",
      text: "Request Date",
      className: "Request Date",
      align: "left",
      sortable: true,
      cell: (record) => {
        let date = record.createdAt;
        date = moment(date).format("DD-MM-YYYY, hh:mm:ss A");
        return <>{date}</>;
      },
    },

    {
      key: "view",
      text: "Action",
      className: "view",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <>
            <Link to={`/verifykyc/${record.id}`} title="View Kyc">
              <button type="button" className="btn btn-success">
                View
              </button>
            </Link>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const getUserDetails = async () => {
    let result = await getUser(configs);
    setData(result.data);
    setMainData(result.data);
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  const handleFilter = (e) => {
    setFilter(e.target.value);
    userList(e, e.target.value);
  };

  const userList = (e, type) => {
    e.preventDefault();
    setData([]);
    
    const filteredData = mainData?.filter((item) =>
      item?.kycStatus==type
    );
    type === "all" ? setData(mainData) : setData(filteredData);
  };

  const pageChange = (pageData) => {};

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="page-heading">
              <div className="row align-items-center">
                <div className="col-md-10 col-sm-10 col-12">
                  <div class="section-heading">
                    <h2 class="text-black">
                      <b> Verification</b>
                    </h2>
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 col-12">
                  <div className="form-group verify-select">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      value={filter}
                      onChange={handleFilter}
                    >
                      <option value="all">All User</option>
                      <option value="Verified"> Verified User</option>
                      <option value="Pending"> Pending User</option>
                      <option value="Reject">Rejected User</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-5">
                <div className="product-list-outer">
                  <div className="add-product-btn text-center"></div>
                  <ReactDatatable
                    config={config}
                    records={data}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
