import React, { useState, useEffect } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import {
  getContactUsdata,
  updateContactUs,
  getContactUsbyid,
} from "../services/support";
import { toast } from "react-toastify";
import configs from "../Constant/Config";
export const Contactus = () => {
  const [record, setRecord] = useState([]);
  const [id, setId] = useState("")
  const [email, setEmail] = useState("");
  const [disable, setDisable] = useState(true);
  const [phone, setPhone] = useState("");
  const [tollfree, setTollfree] = useState("");
  const [emailerr, setEmailerr] = useState("");
  const [phoneerr, setPhoneerr] = useState("");
  const [tollfreeerr, setTollfreeerr] = useState("");

  const handleUpdateEmailHandler = (id) => {
    // databyid(id, type);
    setDisable(false);
    document.getElementById(id).disabled = false;
  };

  const getdata = async () => {
    let data = await getContactUsdata(configs);
    const {id, email, phone, tollfree } = data.data[0];
    setRecord(data.data);
    setId(id)
    setEmail(email);
    setPhone(phone);
    setTollfree(tollfree);
  };
  useEffect(() => {
    getdata();
  }, []);
  // const databyid = async (id) => {
  //   let data = {
  //     id,
  //   };
  //   let resp = await getContactUsbyid(data, configs);
  //   const { email, phone, tollfree } = resp.data[0];
  //   setEmail(email);
  //   setPhone(phone);
  //   setTollfree(tollfree);
  // };
  const onUpdate = async (e, type) => {
    e.preventDefault();
    if (!email) {
      setEmailerr("This field is required");
      return false;
    }
    if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
      setEmailerr("Please enter a valid email address ");
      return false;
    }
    if (!phone) {
      setPhoneerr("This field is required");
      return false;
    }
    if(!/^[1-9]\d{9}$/.test(phone)){
      setPhoneerr("Please enter a valid mobile number");
      return false;
    }
    if (!tollfree) {
      setTollfreeerr("This field is required");
      return false;
    }
    if (!/^[1-9]/.test(tollfree)) {
      setTollfreeerr("Please enter a valid toll free number");
      return false;
    }
    if (tollfree.length<7 || tollfree.length>12) {
      setTollfreeerr("Please enter a valid toll free number");
      return false;
    }

    let data = {
      id: record[0].id,
      email,
      phone,
      tollfree,
      type,
    };
    let resp = await updateContactUs(data, configs);
    if (resp.status === true) {
      toast.dismiss();
      toast.success(resp.message);
      setDisable(true);
      document.getElementById("Email").disabled = true;
      document.getElementById("Phone").disabled = true;
      document.getElementById("Tollfree").disabled = true;
    }
    getdata();
  
    if (resp.status === false) {
      toast.dismiss();
      toast.success(resp.message);
      setDisable(false);
    }
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
      if (value === "" || value === undefined) {
        setEmailerr("This field is required");
        setEmail(value);
        return false;
      } 
      if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)){
        setEmailerr("Please enter a valid email address ");
        return false;
      }
        setEmailerr("");
        
      
    }
    if (name === "phone") {
      setPhone(value);
      if (value === "" || value == undefined) {
        setPhoneerr("This field is required");
        setPhone(value);
        return false;
      } 
      if(!/^[1-9]\d{9}$/.test(value)){
        setPhoneerr("Please enter a valid mobile number");
        return false;
      }
        setPhoneerr("");
   
      
    }
    if (name === "tollfree") {
      setTollfree(value);
      if (value === "" || value == undefined) {
        setTollfreeerr("This field is required");
        setTollfree(value);
        return false;
      } 
      if (value.length<7 || value.length>12) {
        setTollfreeerr("Please enter a valid toll free number");
        return false;
      }
      if (!/^[1-9]/.test(value)) {
        setTollfreeerr("Please enter a valid toll free number");
        return false;
      }
      else {
        setTollfreeerr("");
        
      }
    }
  };
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>Contact Us</b>
                </h2>
              </div>
              <div class="white_box cococ">
              <form>
             
                    <div class="form-group row">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">
                      Email Address
                      </label>

                      <div class="col-sm-6 mb-3">
                        <a className="edit_pancial" 
                          href
                          onClick={() => {
                            handleUpdateEmailHandler("Email");
                          }}
                        
                        >
                         <span class="mdi mdi-square-edit-outline"></span>
                        </a>
                        <input
                          type="text"
                          name="email"
                          class="form-control disable"
                          id="Email"
                          disabled={id?true:false}
                          value={email}
                          onChange={handlechange}
                          placeholder="Enter Email Address"
                        />
                        <span style={{ color: "red" }}>{emailerr}</span>
                        <div class="col-sm-1 "></div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        for="inputPassword3"
                        class="col-sm-2 col-form-label"
                      >
                        Phone Number
                      </label>
                      <div class="col-sm-6 mb-3">
                        <a className="edit_pancial" 
                          href
                          onClick={() => {
                            handleUpdateEmailHandler("Phone");
                          }}
                           
                        >
                         <span class="mdi mdi-square-edit-outline"></span>
                        </a>
                        <input
                          type="tel"
                          class="form-control disable"
                          name="phone"
                         id="Phone"
                          value={phone}
                          onChange={handlechange}
                          disabled={id?true:false}
                          placeholder="Enter Phone Number"
                          maxLength="10"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <span style={{ color: "red" }}>{phoneerr}</span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        for="inputPassword3"
                        class="col-sm-2 col-form-label"
                      >
                        Toll Free Number
                      </label>
                      <div class="col-sm-6">
                        <a className="edit_pancial" 
                          href
                          onClick={() => {
                            handleUpdateEmailHandler("Tollfree");
                          }}
                           
                        >
                         <span class="mdi mdi-square-edit-outline"></span>
                        </a>
                        <input
                          type="tel"
                          name="tollfree"
                     
                          value={tollfree}
                          onChange={handlechange}
                          class="form-control disable"
                          id="Tollfree"
                          disabled={id?true:false}
                          placeholder="Enter Toll Free Number"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength="12"
                          minLength="7"
                        />
                        <span style={{ color: "red" }}>{tollfreeerr}</span>
                      </div>
                    </div>
                  
                

                {disable === true ? null : (
                  <button
                    class="btn btn-primary mt-5 "
                    style={{ marginLeft: "40%" }}
                    onClick={onUpdate}
                  >
                    Update
                  </button>
                )}
              </form>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
