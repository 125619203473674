import * as opsService from "./Ops";
// import { baseUrl } from "../Pages/BaseUrl";
import { getTerms,updateTerms } from "../Constant/Api";
// ===========================ABOUT US ==============================//
const getTermCondition = async(token) => {
    let result = await opsService.getData(getTerms,token);
    return result;
  };
  const updateTermCondition = async(data,token) => {
    let result = await opsService.postdata(updateTerms, data,token);
    return result;
  };
 

export {
  
    getTermCondition,
    updateTermCondition,

   
  
  };