import React,{useState,useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import configs from "../Constant/Config";
import { toast } from "react-toastify";
import { updateAadhar } from "../services/user";
function AadharUpdateModal(props) {
 
    const [disable, setDisable] = useState(false)
    const [frontImageErr, setFrontImageErr] = useState("")
    const [backImageErr, setBackImageErr] = useState("")
    const [frontImage, setFrontImage] = useState(props.frontImage)
    const [backImage, setBackImage] = useState(props.backImage)
    const [aadharNumber, setAadharNumber] = useState(props.aadharNo)
    const [aadharNumberErr, setAadharNumberErr] = useState("")
    const [frontFile, setFrontFile] = useState("")
    const [backFile, setBackFile] = useState("")
    const [id, setId] = useState(props.id)
   
    useEffect(() => {
      setAadharNumber(props.aadharNo)
      setFrontImage(props.frontImage)
      setBackImage(props.backImage)
      setId(props.id)
      console.log("props.id",props.id)
    }, [props])
    

  const handleClose = () => {
    props.setAadharEditShow(false);
  };
  const onsubmit =async (e) => {
   e.preventDefault()
   const formdata = new FormData();
   
   formdata.append("id", id.id);
   formdata.append("aadharNo", aadharNumber);
   formdata.append("aadharFront", frontFile);
   formdata.append("aadharBack", backFile);
   const result=await updateAadhar(formdata,configs)
   console.log("results----",result)
   if(result.status){
    toast.dismiss();
      toast.success(result.message);
    props.setAadharEditShow(false);
    props.setrefresh(!props.refresh)
   }
  };
  const handlechangeNumber = (e) => {
     setAadharNumber(e.target.value)
     if(e.target.value===""){
        setAadharNumberErr("This field is required")
        return false
     }
     if((e.target.value).length<12){
        setAadharNumberErr("Invalid aadhar number")
        return false
     }
     setAadharNumberErr("")
  };
  const handlechangeimage = (e) => {
    const {name,value}=e.target
    if(name==="frontImage"){
       setFrontFile(e.target.files[0])
       setFrontImage(URL.createObjectURL(e.target.files[0]))
       if(value===""){
        setFrontImageErr("This field is required")
        return false
       }
       setFrontImageErr("")
    }
    if(name==="backImage"){
      setBackFile(e.target.files[0])
      setBackImage(URL.createObjectURL(e.target.files[0]))
      if(value===""){
        setBackImageErr("This field is required")
        return false
       }
       setBackImageErr("")
    }
  };
  return (
    <div>
      <Modal show={props.aadhaarEditShow} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Update Aadhaar</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Aadhaar Number </Form.Label>
              <Form.Control
                type="tel"
                name="aadharNumber"
                value={aadharNumber}
                onChange={handlechangeNumber}
                maxLength="12"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div>
              <img
                style={{ width: "100px" }}
                src={frontImage}
                className="img-fluid"
                alt=""
             
              />
              </div>
              
               <Form.Label>Front Image</Form.Label>
              <Form.Control
                type="file"
                name="frontImage"
                accept="image/png, image/jpeg, image/jpg"
                onChange={handlechangeimage}
              />
              <span style={{ color: "red" }}>{frontImageErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
             <div>
             <img
                style={{ width: "100px" }}
                src={backImage}
                className="img-fluid"
                alt=""
              />
             </div>
             
               <Form.Label>Back Image</Form.Label>
              <Form.Control
                type="file"
                name="backImage"
                accept="image/png, image/jpeg, image/jpg"
                onChange={handlechangeimage}
              />
              <span style={{ color: "red" }}>{backImageErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit} disabled={disable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AadharUpdateModal;
