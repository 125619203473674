import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { adminChangePassword } from "../services/user";
import configs from "../Constant/Config";
import { toast } from "react-toastify";
function Header() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
      window.location = "/";
    }
  }, []);
  if (!sessionStorage.getItem("jwtToken")) {
    localStorage.clear();
  }

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const handleClose = () => {
    setPasswordfield({
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
    });
    setCurrentPasswordErr("");
    setNewpasswordErr("");
    setconfirmPasswordErr("");
    setShow(false);
  };
  const [passwordfield, setPasswordfield] = useState({
    currentpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const [eye1, seteye1] = useState("fa-eye-slash");
  const [eye2, seteye2] = useState("fa-eye-slash");
  const [eye, seteye] = useState("fa-eye-slash");
  const [currentPasswordErr, setCurrentPasswordErr] = useState("");
  const [newPasswordErr, setNewpasswordErr] = useState("");
  const [confirmPasswordErr, setconfirmPasswordErr] = useState("");
  const [disable, setdisable] = useState("");
  const [show, setShow] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState("password");
  const [showConfirmPassword, setShowConfirmPassword] = useState("password");
  const [showCurrentPassword, setShowCurrentPassword] = useState("password");
  const handleShow = (id) => {
    setShow(true);
  };
  const showcurrentPassword = () => {
   
    if (showCurrentPassword === "password") {
   
      setShowCurrentPassword("text");
      seteye("fa-eye");
    } else {
      setShowCurrentPassword("password");
      seteye("fa-eye-slash");
    }
  };

  const shownewPassword = () => {
    if (showNewPassword === "password") {
      setShowNewPassword("text");
      seteye1("fa-eye");
    } else {
      setShowNewPassword("password");
      seteye1("fa-eye-slash");
    }
  };

  const showconfirmPassword = () => {
    if (showConfirmPassword === "password") {
      setShowConfirmPassword("text");
      seteye2("fa-eye");
    } else {
      setShowConfirmPassword("password");
      seteye2("fa-eye-slash");
    }
  };

  const handlepassword = (e) => {
    setPasswordfield({ ...passwordfield, [e.target.name]: e.target.value });
    const value = e.target.value;
    if (e.target.name === "currentpassword") {
      if (value === "") {
        setCurrentPasswordErr("This field is required");
        return false;
      }
      setCurrentPasswordErr("");
    }
    if (e.target.name === "newpassword") {
      if (value === "") {
        setNewpasswordErr("This field is required");
        return false;
      }
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#%?^-_/$&*]).{8,}$/.test(
          value
        )
      ) {
        setNewpasswordErr(
          "Password must be 8 characters long, contain at least one upper case letter, one lower case letter, one number, and one special character"
        );
        return false;
      }

      setNewpasswordErr("");
      if (passwordfield.confirmpassword !== "") {
        if (value !== passwordfield.confirmpassword) {
          setconfirmPasswordErr("Confirm password does't matched");
          return false;
        }
        setconfirmPasswordErr("");
      }
    }
    if (e.target.name === "confirmpassword") {
      if (value === "") {
        setconfirmPasswordErr("This field is required");
        return false;
      }
      if (passwordfield.newpassword !== "") {
        if (passwordfield.newpassword !== value) {
          setconfirmPasswordErr("Confirm password does't matched");
          return false;
        }
      }
      setconfirmPasswordErr("");
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    setdisable(true);
    setTimeout(() => {
      setdisable(false);
    }, 3000);
    if (passwordfield.currentpassword === "") {
      setCurrentPasswordErr("This field is required");
      return false;
    }
    if (passwordfield.newpassword === "") {
      setNewpasswordErr("This field is required");
      return false;
    }
    if (
      !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#%?^-_/$&*]).{8,}$/.test(
        passwordfield.newpassword
      )
    ) {
      setNewpasswordErr(
        "Password must be 8 characters long, contain at least one upper case letter, one lower case letter, one number, and one special character"
      );
      return false;
    }
    if (passwordfield.confirmpassword === "") {
      setconfirmPasswordErr("This field is required");
      return false;
    }
    if (passwordfield.newpassword !== passwordfield.confirmpassword) {
      setconfirmPasswordErr("Confirm password does't matched");
      return false;
    }
    if (passwordfield.newpassword === passwordfield.confirmpassword) {
      const data = {
        oldPassword: passwordfield.currentpassword,
        newPassword: passwordfield.newpassword,
        confirmPassword: passwordfield.confirmpassword,
      };
      const response = await adminChangePassword(data, configs);
      if (response.status) {
        setPasswordfield({
          currentpassword: "",
          newpassword: "",
          confirmpassword: "",
        });
        toast(response.message);
        handleClose();
        setTimeout(() => {
          navigate("/", { replace: true });
          localStorage.clear();
        }, 2000);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Confirm password does't matched");
    }
  };

  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex"></div>
          <div className="d-flex align-items-center">
            <div className="dropdown ms-sm-3 header-item topbar-user">
              <button
                type="button"
                className="btn shadow-none"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  <img
                    className="rounded-circle header-profile-user"
                    src="../../assets/images/users/avatar-1.png"
                    alt="Header Avatar"
                  />
                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                      Admin
                    </span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">Welcome Admin!</h6>
                <Link
                  className="dropdown-item"
                  to=""
                  onClick={() => handleShow()}
                >
                  <span className="align-middle">Change Password</span>
                </Link>
                <Link
                  className="dropdown-item"
                  to=""
                  onClick={(e) => logout(e)}
                >
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />
                  <span className="align-middle" data-key="t-logout">
                    Logout
                  </span>
                </Link>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title style={{ color: "black" }}>
                      Change Password{" "}
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Current Password </Form.Label>
                        <div className="password-group">
                          <Form.Control
                            type={showCurrentPassword}
                            name="currentpassword"
                            value={passwordfield.currentpassword}
                            placeholder="Enter Current Password"
                            onChange={handlepassword}
                            className="password"
                          />
                          <span
                            role="button"
                            onClick={showcurrentPassword}
                            className="eye-icon"
                          >
                            <i className={`fa ${eye}`}></i>
                          </span>
                        </div>

                        <span style={{ color: "red" }}>
                          {currentPasswordErr}
                        </span>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> New Password</Form.Label>
                        <div className="password-group">
                          <Form.Control
                            type={showNewPassword}
                            name="newpassword"
                            placeholder="Enter New Password"
                            value={passwordfield.newpassword}
                            onChange={handlepassword}
                            className="password"
                          />
                          <span
                            role="button"
                            onClick={shownewPassword}
                            className="eye-icon"
                          >
                            <i className={`fa ${eye1}`}></i>
                          </span>
                        </div>

                        <span style={{ color: "red" }}>{newPasswordErr}</span>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label> Confirm Password</Form.Label>
                        <div className="password-group">
                          <Form.Control
                            type={showConfirmPassword}
                            name="confirmpassword"
                            placeholder=" Enter Confirm Password"
                            value={passwordfield.confirmpassword}
                            rows={3}
                            onChange={handlepassword}
                            className="password"
                          />
                          <span
                            role="button"
                            onClick={showconfirmPassword}
                            className="eye-icon"
                          >
                            <i className={`fa ${eye2}`}></i>
                          </span>
                        </div>

                        <span style={{ color: "red" }}>
                          {confirmPasswordErr}
                        </span>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        changePassword(e);
                      }}
                      disabled={disable}
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
