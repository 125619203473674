import React,{useState} from 'react'
import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import moment from "moment";
// import { DateRangePicker } from 'react-date-range';
// import 'react-date-range/dist/styles.css'; // Import the styles
// import 'react-date-range/dist/theme/default.css'; // Import the theme
Chart.register(CategoryScale);

function Graph({ dataPoints }) {
    // const [selectedRange, setSelectedRange] = useState([
    //     {
    //       startDate: new Date(),
    //       endDate: new Date(),
    //       key: 'selection',
    //     },
    //   ]);
    
    //   const handleDateSelect = ranges => {
    //     setSelectedRange([ranges.selection]);
    //   };
    // ChartJS.register(ArcElement, Tooltip, Legend);
    const counts = dataPoints.map(data => data.count);
    const dates = dataPoints.map(data => moment(data.date).format('YYYY-MM-DD'));
    
    // Helper function to generate all dates within the range
    const generateAllDates = (startDate, endDate) => {
      const dates = [];
      const currDate = new Date(startDate);
      while (currDate <= endDate) {
        dates.push(currDate.toISOString().slice(0, 10));
        currDate.setDate(currDate.getDate() + 1);
      }
      return dates;
    };
  
    // Get the minimum and maximum dates from the data
    // const minDate = new Date(Math.min(2023-06-06));
    // const maxDate = new Date(Math.max(2023-06-09));
    console.log("datapoints",dataPoints)
    const minDate = new Date("2022-01-06");
    const maxDate = new Date("2028-12-09");
    const allDates = generateAllDates(minDate, maxDate);
    console.log("counts",allDates)
   
    // Create a mapping of date to count
    const dataMap = {};
    dataPoints.forEach(data => {
      dataMap[data.date] = data.count;
    });
    console.log("datamap",dataMap)
  
    // Fill in missing dates with zero counts
    const filledData = allDates.map(date => ({
      date,
      count: dataMap[date] || 0,
    }));
    const filledCounts = filledData.map(data => data.count);
  const filledDates = filledData.map(data => data.date);
  console.log("filledcount",filledData)
  // Chart.js data object
//   const chartData = {
//     labels: filledDates,
//     datasets: [
//       {
//         label: 'Count',
//         data: filledCounts,
//         backgroundColor: 'rgba(75, 192, 192, 0.6)', // Custom color for bars
//       },
//     ],
//   };
const chartData = {
    labels: dates,
    datasets: [
      {
        label: 'Count',
        data: counts,
        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Custom color for bars
      },
    ],
  };
  const chartOptions = {
    maintainAspectRatio: false ,
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
      },
    },
  };

  return (
    <div>
    {/* <DateRangePicker ranges={selectedRange} onChange={handleDateSelect} /> */}
    <Line data={chartData} options={chartOptions} height={330}/>
    </div>

  )
}

export default Graph
