import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Footer from "../Widgets/Footer";
import { referraluser } from "../services/user";
import ReactDatatable from "@mkikets/react-datatable";
import configs from "../Constant/Config";
import { useParams } from "react-router-dom";

function ReferralUser() {
    const {id}=useParams()
    const [records, setRecords] = useState([])
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        getUserDetails();
      }, [refresh]);
    const columns = [
        {
          key: "S.No.",
          text: "S.No.",
          className: "S.No.",
          align: "left",
          sortable: true,
          cell: (row, index) => index + 1,
        },
        {
          key: "id",
          text: " User ID",
          className: " User Id",
          align: "left",
          sortable: true,
        },
    
        {
            key: "userName",
            text: "User Name",
            className: "User Name",
            align: "left",
            sortable: true,
            // cell:(res)=>{
            //     return (
            //         <Link to={`/referral-user/${res.id}`}>{res.userName}</Link>
            //     )
            // }
          },
        {
          key: "mobileNumber",
          text: "Phone",
          className: "Phone",
          align: "left",
          sortable: true,
        },
       
        {
          key: "email",
          text: "User Email",
          className: "User Name",
          align: "left",
          sortable: true,
        },
        {
          key: "wallet",
          text: "Wallet Balance",
          className: "Wallet Balance",
          align: "left",
          sortable: true,
          cell: (record) => {
            if (record.wallet) {
              let walletStr = JSON.parse(record.wallet);
    
              return <p>{walletStr.totalBalance}</p>;
            }
          },
        },
        {
          key: "total_referral",
          text: "total Referrals",
          className: "total_referral",
          align: "left",
          sortable: true,
         
        },
        
      ];
      const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Fund Request List",
        no_data_text: "No record found!",
        button: {
          // print: true,
          // csv: true,
        },
        language: {
          // length_menu: "Show MENU result per page",
          filter: "Search in records...",
          // info: "Showing START to END of TOTAL records",
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
      };
      const getUserDetails = async () => {
        const data={id:id}
        let result = await referraluser(data,configs);
        console.log("result",result)
        if (result.status) {
            setRecords(result.data);
        }
      };
  return (
    <div id="layout-wrapper">
    <Header />
    <Navbar />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <div className="page-heading">
            <div className="row align-items-center">
              <div className="col-md-10 col-sm-10 col-12">
                <div class="section-heading">
                  <h2 class="text-black">
                    <b> Referral Users</b>
                  </h2>
                </div>
              </div>
              
            </div>
          </div>
         
        </div>
        <div className="row">
          <div className="col-xxl-5">
            <div className="product-list-outer">
              <div className="add-product-btn text-center"></div>
              <ReactDatatable
                config={config}
                records={records}
                columns={columns}
              
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
  )
}

export default ReferralUser
