import { baseUrl } from "./BaseUrl";

// ========= About us ==========

export const getAbout=baseUrl+"/about-us-get"
export const updateAbout=baseUrl+"/about-us-update"


// =========   Banner ========
export const addBanner=baseUrl+"/banner-insert"
export const getBanner=baseUrl+"/banner-get"
export const deleteBanner=baseUrl+"/banner-delete"
export const updateBanner=baseUrl+"/banner-update"
export const getBannerBYId=baseUrl+"/banner-getbyid"

export const addPromotionBanner=baseUrl+"/promotion-banner-insert"
export const getPromotionBanner=baseUrl+"/get-promotion-banner"
export const deletePromotionBanner=baseUrl+"/delete-promotion-banner"
export const updatePromotionBanner=baseUrl+"/update-promotion-banner-status"


// =========== Player ============

export const  getPlayerImage=baseUrl+"/get-player-images"
export const  updatePlayerImage=baseUrl+"/update-player-image"
export const getFixturePlayerById=baseUrl+"/get-player-by-contestid"
export const getPlayerById=baseUrl+"/get-player-by-id"

// =========== Team ===========

export const getTeam=baseUrl+"/team-get"
export const updateteamLogo=baseUrl+"/update-team-logo"


// ========== Users ===========

export const getAllUser=baseUrl+"/allUserDetails"
export const getUserDetailBYId=baseUrl+"/userDetails-byId"
export const getTransactionById=baseUrl+"/get-transaction-by-user-id-admin"
export const getBankDetailBYUserId=baseUrl+"/get-bank-detail-by-id"
export const getContestBYUserId=baseUrl+"/get-my-contest-user-id"
export const changePassword=baseUrl+"/admin-change-password"
export const totalUser=baseUrl+"/total-user"
export const totalVerifiedUser=baseUrl+"/total-verified-user"
export const totalPendingUser=baseUrl+"/total-pending-user"
export const userByReferred=baseUrl+"/user-by-referred"
export const referralUser=baseUrl+"/referral-user"
export const dateCountUser=baseUrl+"/date-count-user"
export const kycUserApi=baseUrl+"/kyc-user"
export const updateNameApi=baseUrl+"/update-user-name-by-admin"
export const updateAadharApi=baseUrl+"/update-aadhar-by-admin"
export const updatePanApi=baseUrl+"/update-pan-by-admin"

// ========== kyc =============== 

export const rejectKyc=baseUrl+"/rejectkyc"
export const verifiedKyc=baseUrl+"/verifyKyc"


// ========= Transaction ==========

export const getTransaction=baseUrl+"/get-transaction"
export const getWithdrawRequest=baseUrl+"/get-withdrawrequest"
export const updateWithdrawRequest=baseUrl+"/update-request"
export const todayDeposite=baseUrl+"/today-deposit"
export const todayWithdraw=baseUrl+"/today-withdrawal"
export const totalWithDrawRequest=baseUrl+"/total-withdrawal-request"


// ========== Help And Support ========= 

export const addHelpSupport=baseUrl+"/help-support-insert"
export const getHelpSupport=baseUrl+"/help-support-get"
export const deleteHelpSupport=baseUrl+"/help-support-delete"
export const updateHelpSupport=baseUrl+"/help-support-update"
export const getHelpSupportById=baseUrl+"/help-support-byid"


// ========== Contact Us ==============

export const getContactUs=baseUrl+"/contact-us-get"
export const updateContactUS=baseUrl+"/contact-us-update"
export const updateContactUsById=baseUrl+"/contact-us-byid"


// ============ Legality =============

export const getLegality=baseUrl+"/legality-get"
export const updateLegalities=baseUrl+"/legality-update"

// ===========  Setting ========= 

export const getSetting=baseUrl+"/settings-get"
export const updateSetting=baseUrl+"/settings-update"

// ===============  Term and Conditions ================ 

export const getTerms=baseUrl+"/get-term-condition"
export const updateTerms=baseUrl+"/update-term-condition"


// =============== Fantasy Points ============= 

export const addFantasyPoint=baseUrl+"/fantasypoint-pointinsert"
export const getFantasyPoint=baseUrl+"/fantasy-pointget"
export const deleteFantasyPoint=baseUrl+"/fantasy-pointdelete"
export const updateFantasyPoint=baseUrl+"/fantasy-pointupdate"
export const getFantasyPointById=baseUrl+"/fantasy-pointbyid"


// ============== Category =================

export const addCategory=baseUrl+"/category-insert"
export const getCategory=baseUrl+"/category-get"
export const deleteCategory=baseUrl+"/category-delete"
export const updateCategory=baseUrl+"/category-update"
export const getCategoryById=baseUrl+"/getbyid-category"


// =========== Contest Template ============= 

export const addContestTemplate=baseUrl+"/contest-insert"
export const getContestTemplate=baseUrl+"/contest-getdata"
export const deleteContestTemplate=baseUrl+"/contest-delete"
export const updateContestTemplate=baseUrl+"/contest-update"
export const getContestTemplateById=baseUrl+"/contestbyid"
export const getTemplatesName=baseUrl+"/get-template-name"

// ==========  tournament ====================

export const getTournaments=baseUrl+"/admin-get-tournament"

// ========== fixture =================

export const getFixtures=baseUrl+"/get-fixtures"

// =========== fixture Contest =============

export const addFixtureContests=baseUrl+"/fixturecontest-insert"
export const getAllFixtureContests=baseUrl+"/all-get-fixture-contest-admin"
export const getFixtureContestsByfixtureId=baseUrl+"/get-fixture-contest-admin"
export const deleteFixtureContests=baseUrl+"/fixture-contest-delete"
export const getTotalContests=baseUrl+"/total-contest"
export const getFixtureContestsByIdApi=baseUrl+"/get-fixture-contest-by-id"