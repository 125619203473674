import React, { useState, useEffect } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
import moment from "moment";
import {Link} from 'react-router-dom'
import ReactDatatable from "@mkikets/react-datatable";
import { getFixturesData } from "../services/contest";
import configs from "../Constant/Config";
export const Fixtures = () => {
  const [record,setRecord ] = useState([]);
  const [filterRecord, setFilterRecord] = useState([])
  // const [upcomingData, ] = useState([]);
  // const [liveData, ] = useState([]);
  const [Tab, setTab] = useState("not_started");
  // ============= Completed match =====================//
  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "ID",
      className: "sr_no.",
      align: "left",
      sortable: true,

    },
    {
      key: "match_name",
      text: "Name",
      className: "fixture-name",
      align: "left",
      sortable: true,
      cell:((record)=>{
         return(
          <Link to={`/fixturescontestlist/${record.id}`}>{record.match_name}</Link>
         )
      })
    },
    {
      key: "match_format",
      text: "Match Type",
      className: "Category",
      align: "left",
      sortable: true,
    },
    {
      key: "match_start_date",
      text: "Start Date",
      className: "Fixture",
      align: "left",
      sortable: true,
      cell:((response)=>{
        console.log("data=====",response.match_start_date)
        var date=(new Date((response.match_start_date) * 1000)).toString()
        console.log("date=",date)
        return (
          //  <p>{date}</p>
          <span>{moment(date).format("DD-MM-YYYY , hh:mm:ss A")}</span>
           )
      })
     },
    

    {
      key: "venue_city",
      text: "Location",
      className: "type",
      align: "left",
      sortable: true,
    },
    {
      key: "venue_name",
      text: "Venue Name",
      className: "type",
      align: "left",
      sortable: true,
    },
    {
      key: "match_status",
      text: "Status",
      className: "type",
      align: "left",
      sortable: true,
    },
    {
      key: "no_of_contest",
      text: "Total Contest",
      className: "type",
      align: "left",
      sortable: true,
    },
    {
      key: "user_total_team",
      text: "User Total Team",
      className: "type",
      align: "left",
      sortable: true,
    },
    {
      key: "player",
      text: "Player",
      className: "type",
      align: "left",
      sortable: true,
      cell:((response)=>{
        return <Link to={`/fixtureplayer/${response.id}`} className="btn btn-primary">View</Link>
      })
    },

    {
      key: "view",
      text: "Action",
      className:`view ${Tab}`,
      align: `left ${Tab}`,
      sortable: true,
      cell:((response)=>{
        return (
          
          <Link to={`/fixturescontest/${response.id}`} className="btn btn-primary" style={{pointerEvents:response.match_status=="started" || response.match_status=="completed"?"none":"auto", opacity:response.match_status=="started" || response.match_status=="completed"?"0.5":"1"}}>Add Contest</Link>
        )
      })
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Filter in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const filterData = (value) => {
   
    setFilterRecord([]);
   console.log("vggsdafvcaLL=====",value)
    const filteredData = record?.filter((item) =>
    item.match_status===value  
    );
    if(value=="completed"){
      console.log("tab",Tab)
      setFilterRecord(filteredData.reverse());
    }
    else{
      setFilterRecord(filteredData);
    }
    //  setFilterRecord(filteredData);
  };
  const getdata = async () => {
    let res = await getFixturesData(configs);
    if(res.status){
      setRecord(res.data)
      const filteredData = res.data?.filter((item) =>
      item.match_status===Tab  
      );
      if(Tab=="completed"){
        console.log("tab",Tab)
        setFilterRecord(filteredData.reverse());
      }
      else{
        setFilterRecord(filteredData);
      }
    
    }
  };
  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content ">
          <div className="page-content">
            <div className="container-fluid">
             
              <div className="row">
                <div className="col-md-6 ">
				 <div className="section-heading">
                <h2>
                  <b>Fixtures</b>
                </h2>
              </div>
				</div>
                <div className="col-md-6 btn_4 tr mb-3">
                
                  <button
                    onClick={() => {
                     setTab("not_started")
                      filterData("not_started");
                    }}
                    style={{background:Tab==="not_started"?"green":"", color:Tab==="not_started"?"white":"black"}}
                  >
                    Upcoming
                  </button>
                  <button
                    onClick={() => {
                     setTab("started")
                      filterData("started");
                    }}
                    style={{background:Tab==="started"?"green":""}}
                  >
                    Live
                  </button>
                  <button
                    onClick={() => {
                      setTab("completed")
                      filterData("completed");
                    }}
                    style={{background:Tab==="completed"?"green":""}}
                  >
                    Completed
                  </button>
				   </div>
                 </div>
                
                  <div className="buttonDirection">
                    
                  </div>
                  <ReactDatatable
                    config={config}
                    records={filterRecord}
                    columns={columns}
                  />
               
              {/* <div className="row">
                <div className="col-md-12">
                  {/* <Tabs
                    defaultActiveKey="Live"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                  >
                    <Tab eventKey="Upcoming" title="Upcoming">
                      <div className="row">
                        <div className="col-xxl-5 text-center">
                          <div className="product-list-outer">
                            <ReactDatatable
                              config={configForUpcoming}
                              records={upcomingData}
                              columns={columnForUpcoming}
                              onPageChange={pageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="Live" title="Live">
                      <div className="row">
                        <div className="col-xxl-5 text-center">
                          <div className="product-list-outer">
                            <ReactDatatable
                              config={configForLive}
                              records={liveData}
                              columns={columnForLive}
                              onPageChange={pageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="Complete" title="Complete">
                      {" "}
                      <div className="row">
                        <div className="col-xxl-5 text-center">
                          <div className="product-list-outer">
                            <ReactDatatable
                              config={configForCompleted}
                              records={record}
                              columns={columnForCompleted}
                              onPageChange={pageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs> 
                  <ReactDatatable
                              config={configForCompleted}
                              records={record}
                              columns={columnForCompleted}
                              onPageChange={pageChange}
                            />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
