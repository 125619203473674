import React, { useState, useEffect, Fragment } from "react";

import ReactDatatable from "@mkikets/react-datatable";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Form from "react-bootstrap/Form";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
// import { baseUrls } from "./BaseUrl";
import { baseUrls } from "../Constant/BaseUrl";
import { addnotification, getnotification } from "../services/contest";
import configs from "../Constant/Config";
import { inputFieldValidation } from "../Validations/validation";
// import axios from "axios";
export const Notifications = () => {
  const [record, setRecord] = useState([]);
  const handleClose = () => {
    setType("");
    setMessage("");
    setSubject("");
    setEnterday("");
    setTypeerr("");
    // setEnterdayerr("");
    setSubjecterr("");
    setMessageerr("");
    setImageerr(undefined);
    setImage(undefined);
    setShow(false);
  };
  const [type, setType] = useState("");
  const [enterday, setEnterday] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState(false);

  const [typeerr, setTypeerr] = useState("");
  // const [enterdayerr, setEnterdayerr] = useState("");
  const [subjecterr, setSubjecterr] = useState("");
  const [messageerr, setMessageerr] = useState("");
  const [imageerr, setImageerr] = useState(false);
  const [show, setShow] = useState(false);

  const handleShow = (id) => {
    setShow(true);
  };

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "type",
      text: "Type",
      className: "Type",
      align: "left",
      sortable: true,
    },
    {
      key: "image",
      text: "Image",
      className: "Image",
      align: "left",
      sortable: true,
      cell: (record) => {
        
        return (
          <Fragment>
            <img
              style={{ height: "50px" }}
              src={baseUrls + "/static/notifications/" + record.image}
              className="img-fluid"
              alt=""
            />
          </Fragment>
        );
      },
    },

    {
      key: "subject",
      text: "Subject",
      className: "Subject",
      align: "left",
      sortable: true,
    },
    {
      key: "message",
      text: "Message",
      className: "Message",
      align: "left",
      sortable: true,
    },
    {
      key: "createdAt",
      text: "Created",
      className: "Created",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <Fragment>{moment(date).format("lll")}</Fragment>;
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  const onSubmit = async () => {
    let err = "This field is required";
    if (!type) {
      setTypeerr(err);
      return false;
    }
    // if (!enterday) {
    //   setEnterdayerr(err);
    //   return false;
    // }
    if (!subject) {
      setSubjecterr(err);
      return false;
    }
    if (!message) {
      setMessageerr(err);
      return false;
    }
    if (!image || image === undefined) {
      setImageerr(err);
      return false;
    }
    if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setImageerr("Select valid image format");
      return false;
    }
    const formdata = new FormData();
    formdata.append("type", type);
    formdata.append("enterday", enterday);
    formdata.append("subject", subject);
    formdata.append("message", message);
    formdata.append("notification", image);
    let result = await addnotification(formdata, configs);
    if (result.status === true) {
      toast.dismiss();
      toast.success(result.message);
    }

    getnotificationdata();
    handleClose();
  };

  // ============= firebase ===========//
  // const onSubmitHandler = async () => {
  //   const token =
  //     "key = AAAAG5W30uY:APA91bGphwWaMhdIMAzUP0NjgnAGlLh9HeDPEIOaWhKxwL6N7o3sMSqC4YEoMLsUHHoJgS5TjJBeD4tAI9tgkH9ZtDaQOtEasde7KkfZCxlxnC82C6pWLBnbMrgF8pZY1NxmBkoLYcYT";

  //   var data = JSON.stringify({
  //     to: "cOmBzLebYON-NHFQK33sQE:APA91bEsJyaeIoZcK-Ycl6L4kSj7_dKWvFMNaCDSR8imknFQsq8eUCvdJLIzAhOHjDxAzsz7tgn57f8lNmxWN007We-wpNSW8CPm_XJwwSrqPorF5NX6MKJhWp17wvEJ817VjbgYXzi7",
  //     notification: {
  //       title: type,
  //       body: message,
  //     },

  //   });

  //   var config = {
  //     method: "post",
  //     // url: "https://fcm.googleapis.com/fcm/send",
      
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization:token
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
       
  //       handleClose()
  //     })
  //     .catch(function (error) {
       
  //     });
  // };

  // ======firebase===============//

  const getnotificationdata = async () => {
    let resultss = await getnotification(configs);

    setRecord(resultss.data);
  };
  useEffect(() => {
    getnotificationdata();
  }, []);
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "contesttype") {

      setType(value);
      const err = inputFieldValidation(name, value);
      setTypeerr(err);
    }

    // if (name === "enterday") {
    //   setEnterday(value);
    //   const err = inputFieldValidation(name, value);
    //   setEnterdayerr(err);
    // }
    if (name === "subject") {
      setSubject(value);
      const err = inputFieldValidation(name, value);
      setSubjecterr(err);
    }

    if (name === "message") {
      setMessage(value);
      const err = inputFieldValidation(name, value);
      setMessageerr(err);
    }
  };
  const handlechangeimage = (e) => {
    let { value, name } = e.target;
    const image = e.target.files[0];
    if (name === "image") {
      if (value.trim() !== " " || value !== undefined) {
        setImage(e.target.files[0]);
        setImageerr("");
      } else {
        setImage(e.target.files[0]);
        setImageerr("This field is required");
      }
      if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        setImageerr("Select valid image format");
        return false;
      }
    }
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>Notification</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-5 text-center">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center">
                      <button
                        onClick={() => handleShow()}
                        className="btn btn-primary"
                      >
                        Send Notification
                      </button>

                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Title style={{ color: "black" }}>
                          Create Notification Details
                        </Modal.Title>
                        <Modal.Body>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Type </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="contesttype"
                                onChange={handlechange}
                              >
                                <option selected disabled>
                                  Please select
                                </option>
                                <option value="TRANSACTIONAL">
                                  TRANSACTIONAL
                                </option>
                                <option value="PROMOTIONAL">PROMOTIONAL</option>
                                <option value="GAMEPLAY">GAMEPLAY</option>
                                <option value="PROFILE">PROFILE</option>
                                <option value="SOCIAL">SOCIAL</option>
                              </Form.Select>
                              <span style={{ color: "red" }}>{typeerr}</span>
                            </Form.Group>
                            {/* <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Enter Day</Form.Label>
                              <Form.Control
                                type="text"
                                name="enterday"
                                placeholder="Enter Enter Day"
                                value={enterday}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>
                                {enterdayerr}
                              </span>
                            </Form.Group> */}
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Subject</Form.Label>
                              <Form.Control
                                type="text"
                                name="subject"
                                placeholder="Enter Subject"
                                value={subject}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{subjecterr}</span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>Message</Form.Label>
                              <Form.Control
                                type="text"
                                name="message"
                                placeholder=" Enter Message"
                                value={message}
                                rows={3}
                                onChange={handlechange}
                              />
                              <span style={{ color: "red" }}>{messageerr}</span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>Image</Form.Label>
                              <img
                                style={{ width: "100px" }}
                                src={image}
                                className="img-fluid"
                                alt=""
                              />

                              <Form.Control
                                type="file"
                                name="image"
                                //   value={message}
                                rows={3}
                                onChange={handlechangeimage}
                              />
                              <span style={{ color: "red" }}>{imageerr}</span>
                            </Form.Group>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={onSubmit}>
                            Save
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <ReactDatatable
                      config={config}
                      records={record}
                      columns={columns}
                      onPageChange={pageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
